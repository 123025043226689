import React, {useContext, useEffect, useRef, useState} from "react";
import s from './Questions.module.css';
import arrow from '../../assets/img/arrow_send.png';
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
import {useHttp} from "../../hooks/HttpHook";
import {AuthContext} from "../../context/AuthContext";
import jwtDecode from "jwt-decode";
export default function Chat({onStageChange, chatId, lotId}){
    const [chatInfo, setChatInfo] = useState({})
    const [userInfo, setUserInfo] = useState({})
    const {request} = useHttp();
    const auth = useContext(AuthContext);
    const userId = jwtDecode(auth.token).user_id;
    const inputRef = useRef();
    // function getUserInfo(){
    //     try{
    //         request(`${config.baseUrl}/api/v1/user/info/`,'GET').then((data)=>{
    //             if(data) {
    //                 setUserInfo(data);
    //             }
    //         });
    //     }catch(e){
    //         ErrorHandler(e)
    //     }
    // }
    function getMessages(){
        try{
            request(`${config.baseUrl}/api/v1/chat/auction-clarification-chat/${lotId}/${chatId}/`,'GET').then((data)=>{
                if(data) {
                   setChatInfo(data);
                }
            });
        }catch(e){
            ErrorHandler(e)
        }
    }

    function sendMessage(message){
        try{
            request(`${config.baseUrl}/api/v1/chat/auction-clarification-chat/${lotId}/${chatId}/`,'PUT', {message}).then((data)=>{
                getMessages();
            });
        }catch(e){
            ErrorHandler(e)
        }
    }

    function sendMessageHandler(e){
        sendMessage(inputRef.current.value);
        inputRef.current.value = '';
    }

    useEffect(()=>{
       getMessages();
       // getUserInfo();
    },[])

    return(
        <div className={s.Chat}>
            <div className={s.chatHeader}>
                <div>
                    <p>23.08.2021</p>
                    <p>Тема: <span className={s.chatTitle}>Участь у аукціоні</span></p>
                </div>
                <div onClick={() => onStageChange("list")} className={s.backArrow}>
                    <i className="fas fa-long-arrow-alt-left"/>
                    <p>Назад</p>
                </div>
            </div>
            <div className={s.chatMessagesBox}>
                {/*<div className={s.dateBox}>*/}
                {/*    <div/>*/}
                {/*    <p>Сьогодні</p>*/}
                {/*    <div/>*/}
                {/*</div>*/}
                {chatInfo.messages && chatInfo.messages.map((message)=>{
                    if(message.user_id == userId) return(
                        <div className={[s.rightMessage,s.message].join(' ')}>
                            {/*<h5>{userInfo.username}</h5>*/}
                            <div className={s.messageContent}>
                                <p>{message.message}</p>
                            </div>
                            <div className={s.triangle}/>
                        </div>
                    )
                    else return (
                        <div className={[s.leftMessage,s.message].join(' ')}>
                            {/*<h5>Менеджер Аліна</h5>*/}
                            <div className={s.messageContent}>
                                <p>{message.message}</p>
                            </div>
                            <div className={s.triangle}/>
                        </div>
                    )
                })}
                {/*<div className={[s.leftMessage,s.message].join(' ')}>*/}
                {/*    <h5>Менеджер Аліна</h5>*/}
                {/*    <div className={s.messageContent}>*/}
                {/*        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, aliquam asperiores at dignissimos dolor dolore doloribus, eius est fugit iusto laboriosam magnam minima molestiae, natus nisi nostrum odit perspiciatis quo.</p>*/}
                {/*    </div>*/}
                {/*    <div className={s.triangle}/>*/}
                {/*</div>*/}

                {/*<div className={[s.rightMessage,s.message].join(' ')}>*/}
                {/*    <h5>Юрій</h5>*/}
                {/*    <div className={s.messageContent}>*/}
                {/*        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, aliquam asperiores at dignissimos dolor dolore doloribus, eius est fugit iusto laboriosam magnam minima molestiae, natus nisi nostrum odit perspiciatis quo.</p>*/}
                {/*    </div>*/}
                {/*    <div className={s.triangle}/>*/}
                {/*</div>*/}

                {/*<div className={[s.leftMessage,s.message].join(' ')}>*/}
                {/*    <h5>Менеджер Аліна</h5>*/}
                {/*    <div className={s.messageContent}>*/}
                {/*        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, aliquam asperiores at dignissimos dolor dolore doloribus, eius est fugit iusto laboriosam magnam minima molestiae, natus nisi nostrum odit perspiciatis quo.</p>*/}
                {/*    </div>*/}
                {/*    <div className={s.triangle}/>*/}
                {/*</div>*/}

                {/*<div className={[s.rightMessage,s.message].join(' ')}>*/}
                {/*    <h5>Юрій</h5>*/}
                {/*    <div className={s.messageContent}>*/}
                {/*        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, aliquam asperiores at dignissimos dolor dolore doloribus, eius est fugit iusto laboriosam magnam minima molestiae, natus nisi nostrum odit perspiciatis quo.</p>*/}
                {/*    </div>*/}
                {/*    <div className={s.triangle}/>*/}
                {/*    <div className={s.messageInfo}>*/}
                {/*        <p></p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className={s.chatControls}>
                <input ref={inputRef}/>
                <button onClick={sendMessageHandler}><img src={arrow}/></button>
            </div>
        </div>
    )
}