import config from "../../../config/config";
import React, {Fragment, useCallback, useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import Loading from "../../Loading/Loading";
import s from "../Participation.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import FileSign from "../../FileSign/FileSign";
import {Field, useFormikContext} from "formik";
import {
    CheckboxField,
    DateField,
    FileField,
    NumericField, PhoneNumberField,
    SelectField,
    TextAreaField,
    TextField
} from "../../FormikFields/FormikFields";
import {InputItem, InputItemShort, ItemRow, Line, OutlinedButton} from "../../UI/UI";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";
import SignComponent from "../../SignComponent/SignComponent";
import ErrorHandler from "../../../utils/ErrorLoger";
import {AuthContext} from "../../../context/AuthContext";
import SignFileModal from "../../SignFileModal/SignFileModal";
import InputField from "../../FormikFields/InputField";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import dictionary from "../../../config/dictionary";

export default function AuthorizedPerson(){
    const {values, setFieldValue} = useFormikContext();
    const {nextStage} = useContext(ParticipationFormContext)

    function RepresentativePerson(){
        const [authorizationSignStatus, setAuthorizationSignStatus] = useState('unsigned')
        const [taxSignStatus, setTaxSignStatus] = useState('unsigned')
        const [identitySignStatus, setIdentitySignStatus] = useState('unsigned')
        return(
            <>
                <fieldset disabled={true}>
                    <InputItemShort label={"Прізвище"}>
                        <Field name={"legal_entity_authorized_person_last_name"} component={TextField}/>
                    </InputItemShort>

                    <InputItemShort label={"Ім'я"}>
                        <Field name={"legal_entity_authorized_person_first_name"} component={TextField}/>
                    </InputItemShort>

                    <InputItemShort label={"По батькові"}>
                        <Field name={"legal_entity_authorized_person_second_name"} component={TextField}/>
                    </InputItemShort>

                    <InputItemShort label={"Назва документа що підтверджує повноваження"}>
                        <Field name={"legal_entity_authorized_person_authorization_document_name"} component={TextAreaField}/>
                    </InputItemShort>

                    <InputItemShort label={"№"}>
                        <Field name={"legal_entity_authorized_person_authorization_document_number"} component={TextField}/>
                    </InputItemShort>

                    <InputItemShort label={"Дата видачі"}>
                        <Field name={"legal_entity_authorized_person_authorization_document_issue_date"} component={TextField}/>
                    </InputItemShort>

                    <InputItemShort label={"Ким видано"}>
                        <Field name={"legal_entity_authorized_person_authorization_document_issuer"} component={TextField}/>
                    </InputItemShort>

                </fieldset>

                <SignFileModal
                    isVisible={authorizationSignStatus==='signing'}
                    onSign={(fileId)=>{
                        setAuthorizationSignStatus('signed');
                        setFieldValue('legal_entity_authorized_person_authorization_document', fileId)
                    }}
                    onClose={setAuthorizationSignStatus.bind(this, 'unsigned')}
                    defaultFileUrl={values.legal_entity_authorized_person_authorization_document?.file}
                    transparentBackground={false}
                    documentType={"LEGAL_ENTITY_AUTHORIZED_PERSON_AUTHORIZATION_DOCUMENT"}
                />

                <InputItemShort>
                    <OutlinedButton
                        style={{marginTop:'30px'}}
                        onClick={setAuthorizationSignStatus.bind(this, 'signing')}
                        disabled={authorizationSignStatus==='signed'}
                        color="#057E36"
                    >
                        {authorizationSignStatus==='signed'?'Підписано':'Підписати'}
                    </OutlinedButton>
                </InputItemShort>


                <Line/>

                <ItemRow>
                    <InputItem label={"РНОКПП"}>
                        <Field disabled={true} name={"legal_entity_authorized_person_tax_number"} component={TextField}/>
                    </InputItem>

                    <InputField
                        name="legal_entity_authorized_person_has_tax_number"
                        component={CheckboxField}
                        disabled={true}
                        modifier={(e)=>!e}
                    >
                       Я відмовився від РНОКПП через свої релігійні переконання та маю про це відмітку у паспорті
                    </InputField>
                </ItemRow>

                <SignFileModal
                    isVisible={taxSignStatus==='signing'}
                    onSign={(fileId)=>{
                        setTaxSignStatus('signed');
                        setFieldValue('legal_entity_authorized_person_tax_number_document', fileId)
                    }}
                    onClose={setTaxSignStatus.bind(this, 'unsigned')}
                    defaultFileUrl={values.legal_entity_authorized_person_tax_number_document?.file}
                    transparentBackground={false}
                    documentType={"LEGAL_ENTITY_AUTHORIZED_PERSON_TAX_NUMBER"}
                />

                <InputItemShort>
                    <OutlinedButton
                        style={{marginTop:'30px'}}
                        onClick={setTaxSignStatus.bind(this, 'signing')}
                        disabled={taxSignStatus==='signed'}
                        color="#057E36"
                    >
                        {taxSignStatus==='signed'?'Підписано':'Підписати'}
                    </OutlinedButton>
                </InputItemShort>

                <Line/>
                <fieldset disabled={true}>
                    <ItemRow>
                        <InputItem label="Документ що посвідчує особу">
                            <InputField
                                name="legal_entity_authorized_person_identity_document_type"
                                data={dictionary.DOCUMENT_TYPES}
                                component={SelectField}
                                disabled={true}
                                checkDefaultById
                            />
                        </InputItem>
                        <InputItem label="Дата видачі">
                            <InputField name="legal_entity_authorized_person_identity_document_issue_date" component={TextField}/>
                        </InputItem>
                    </ItemRow>


                    <ItemRow>
                        <InputItem label="Серія">
                            <InputField name="legal_entity_authorized_person_identity_document_serial_number" component={TextField}/>
                        </InputItem>
                        <InputItem label="Номер">
                            <InputField name="legal_entity_authorized_person_identity_document_number" component={NumericField}/>
                        </InputItem>
                    </ItemRow>
                </fieldset>

                <SignFileModal
                    isVisible={identitySignStatus==='signing'}
                    onSign={(fileId)=>{
                        setIdentitySignStatus('signed');
                        setFieldValue('legal_entity_authorized_person_identity_document', fileId)
                        alert('set')
                    }}
                    onClose={setIdentitySignStatus.bind(this, 'unsigned')}
                    defaultFileUrl={values.legal_entity_authorized_person_identity_document?.file}
                    transparentBackground={false}
                    documentType={"LEGAL_ENTITY_AUTHORIZED_PERSON_IDENTITY_DOCUMENT"}
                />
                {console.log(authorizationSignStatus,taxSignStatus,identitySignStatus)}
                <InputItemShort>
                    <OutlinedButton
                        style={{marginTop:'30px'}}
                        onClick={setIdentitySignStatus.bind(this, 'signing')}
                        disabled={identitySignStatus==='signed'}
                        color="#057E36"
                    >
                        {identitySignStatus==='signed'?'Підписано':'Підписати'}
                    </OutlinedButton>
                </InputItemShort>

                <Line/>
                <fieldset disabled={true}>
                    <InputItemShort label="Країна">
                        <Field
                            name="legal_entity_authorized_person_country"
                            url="/api/v1/location/countries/"
                            component={SelectField}
                            customId="code"
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItemShort>

                    <InputItemShort label="Поштовий індекс">
                        <Field
                            name="legal_entity_authorized_person_postal_code"
                            component={TextField}
                        />
                    </InputItemShort>

                    <ItemRow>
                        <InputItem label="Область">
                            <Field
                                name="legal_entity_authorized_person_region"
                                url="/api/v1/location/regions/"
                                component={SelectField}
                                disabled={true}
                                checkDefaultById
                            />
                        </InputItem>

                        <InputItem label="Район">
                            <Field
                                name="legal_entity_authorized_person_district"
                                url="/api/v1/location/districts/"
                                component={SelectField}
                                disabled={true}
                                checkDefaultById
                            />
                        </InputItem>
                    </ItemRow>

                    <InputItemShort label="Населений пункт">
                        <Field
                            name="legal_entity_authorized_person_settlement"
                            url="/api/v1/location/settlements/"
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItemShort>

                    <InputItemShort label="Вулиця">
                        <Field
                            name="legal_entity_authorized_person_street"
                            component={TextField}
                        />
                    </InputItemShort>

                    <ItemRow>
                        <InputItem label="Номер будинку">
                            <Field
                                name="legal_entity_authorized_person_building_number"
                                component={TextField}
                            />
                        </InputItem>

                        <InputItem label="Корпус">
                            <Field
                                name="legal_entity_authorized_person_building_block_number"
                                component={TextField}
                            />
                        </InputItem>
                    </ItemRow>

                    <InputItem label="Номер квартири/офісу">
                        <Field
                            name="legal_entity_authorized_person_apartment_or_office_number"
                            component={TextField}
                        />
                    </InputItem>
                </fieldset>
                    <InputItem label="Email">
                        <Field
                            name="legal_entity_authorized_person_email"
                            component={TextField}
                        />
                    </InputItem>

                    <InputItem label="Номер телефону">
                        <Field
                            name="legal_entity_authorized_person_phone_number"
                            component={PhoneNumberField}
                        />
                    </InputItem>
                {console.log(!!values.legal_entity_authorized_person_email, !!values.legal_entity_authorized_person_phone_number)}
                {console.log(identitySignStatus, taxSignStatus, authorizationSignStatus)}
                <button
                    className={s.nextStageButton}
                    onClick={nextStage}
                    disabled={!(
                        identitySignStatus === 'signed' &&
                        taxSignStatus === 'signed' &&
                        authorizationSignStatus === 'signed'
                    )}
                >Далі</button>
            </>
        )
    }

    const MemoizedRepresentativePerson = useCallback(RepresentativePerson,[])

    return(
        <>
            <InputItemShort>
                <Field
                    name="legal_entity_authorized_person_type"
                    data={[
                        {name:'Представник', id:'REPRESENTATIVE'},
                        {name:'Керівник', id:'CHIEF'}
                    ]}
                    component={SelectField}
                />
            </InputItemShort>


            {values.legal_entity_authorized_person_type === 'REPRESENTATIVE' &&
                <MemoizedRepresentativePerson/>
            }

            {values.legal_entity_authorized_person_type === 'CHIEF' &&
                <button className={s.nextStageButton} onClick={nextStage}>Далі</button>
            }
        </>
    )
}