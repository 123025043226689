import s from "../NewLot.module.css";
import React from "react";

export default function LotDetails({auctionInfo, lotNumber, id}) {

    const AuctionStatus = ({status}) => {
        switch (status) {
            case 'PENDING' : return 'Очікується'
            case 'AWAITING_START' : return 'Очікування початку'
            case 'STARTED' : return 'Розпочалися'
            case 'ENDED' : return 'Завершились'
            case 'AWAITING_PROTOCOL_SIGN' : return 'Очікування підписання протоколу'
            case 'AWAITING_CONTRACT_SIGN' : return 'Очікування підписання договору'
            case 'AWAITING_PAYMENT' : return 'Очікування оплати'
            case 'NOT_OCCURRED' : return 'Не відбулись'
            default : return '- -'
        }
    }



    return (
        <div id={id} className={s.lotDetails}>
            <div className={s.gridLine}/>
            <div className={s.gridItem}>
                <p className={s.label}>Статус:</p>
                <h5 className={s.value}><b><AuctionStatus status={auctionInfo.auction_status}/></b></h5>
            </div>
            <div className={s.gridItem}>
                <p className={s.label}>Дата і час початку аукціону:</p>
                <h5 className={s.value}><b>{auctionInfo.date_start}</b></h5>
            </div>
            <div className={s.gridItem}>
                <p className={s.label}>Номер аукціону:</p>
                <h5 className={s.value}>{lotNumber}</h5>
            </div>
            <div className={s.gridLine}/>
            <div className={s.gridItem}>
                <p className={s.label}>Стартова ціна:</p>
                <h5 className={s.value}>{auctionInfo.starting_price} грн.</h5>
            </div>
            <div className={s.gridItem}>
                <p className={s.label}>Крок аукціону:</p>
                <h5 className={s.value}>{auctionInfo.min_bid_step} грн.</h5>
            </div>
            <div className={s.gridItem}>
                <p className={s.label}>Кількість лотів:</p>
                <h5 className={s.value}>{auctionInfo.lots_count}</h5>
            </div>
            <div className={s.gridLine}/>
            <div className={s.gridItem}>
                <p className={s.label}>Період редагування:</p>
                <h5 className={s.value}>{auctionInfo.editing_period_start_at} - {auctionInfo.editing_period_end_at}</h5>
            </div>
            <div className={s.gridItem}>
                <p className={s.label}>Період уточнень:</p>
                <h5 className={s.value}>{auctionInfo.editing_period_end_at} - {auctionInfo.entrant_period_start_at}</h5>
            </div>
            <div className={s.gridItem}>
                <p className={s.label}>Період подання пропозицій:</p>
                <h5 className={s.value}>{auctionInfo.entrant_period_start_at} - {auctionInfo.entrant_period_end_at}</h5>
            </div>
        </div>
    )
}