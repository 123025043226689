import React, {useContext, useEffect, useRef, useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from "formik";
import s from './RegisterRedesign.module.css'
import checkmarkGreen from "../../assets/img/checkmark-green.svg";
import InputMask from "react-input-mask";
import {Link} from "react-router-dom";
import smsPicture from '../../assets/img/sms.png'
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import * as Yup from 'yup';
import {useHttp} from "../../hooks/HttpHook";
import {AuthContext} from "../../context/AuthContext";
import config from "../../config/config";
import Loading from "../../components/Loading/Loading";
import CodeComponent from "./components/CodeComponent";
import InputsComponent from "./components/InputsComponent";
import SecondStage from "./components/SecondStage";
import ThirdStage from "./components/ThirdStage";

function Register() {
    const [currentStage, setCurrentStage] = useState(1);
    const [mainForm, setMainForm] = useState({})
    const maxStages = 3;
    const auth = useContext(AuthContext)
    const {request, requestErrors, loading} = useHttp()


    const StagesProgress = ({stage})=>{
        const stagesNumbers = [];
        for(let i=0; i<maxStages; i++){

            if(i+1<stage){
                stagesNumbers.push(<img key={i} className={s.completedStage} src={checkmarkGreen}/>)
                if(i!==maxStages-1)stagesNumbers.push(<div key={'key'+i} className={s.completedLine}/>)
            }else if(i+1===stage){
                stagesNumbers.push(<p key={i} className={s.activeStage}>{i+1}</p>)
                if(i!==maxStages-1)stagesNumbers.push(<div key={'key'+i} className={s.line}/>)
            }else {
                stagesNumbers.push(<p key={i}>{i+1}</p>)
                if(i!==maxStages-1)stagesNumbers.push(<div key={'key'+i} className={s.line}/>)
            }

        }

        return(
            <div className={s.stages}>
                {stagesNumbers}
            </div>
        )
    }



    const CompleteRegistration = () => {
        useEffect(()=>{
            if(currentStage===1) setCurrentStage(2);
        },[])
        return(
            <>
                {
                    currentStage===2?
                        <SecondStage
                            setMainForm={setMainForm}
                            mainForm={mainForm}
                            setCurrentStage={setCurrentStage}
                            currentStage={currentStage}
                        />
                    :currentStage===3?
                        <ThirdStage
                            mainForm={mainForm}
                        />
                    :null
                }
            </>)
    }

    console.log(mainForm)


    const RenderStage = () => {
        switch (auth.scope) {
            case 'none': return <InputsComponent/>
            case 'confirm_phone_number': return <CodeComponent/>
            case 'complete_registration': return <CompleteRegistration/>
        }
    }

    return(
        <div className={s.Registration}>
            <div className={s.content}>
                {loading && <Loading/>}
                <h1>Реєстрація</h1>
                <StagesProgress stage={currentStage}/>
                <RenderStage/>
            </div>
        </div>
    )
}

export default Register;