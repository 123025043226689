import React, {useState, useEffect} from "react";
import s from './MyLots.module.css';
import {NavLink, useHistory} from "react-router-dom";
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import MyLotsCard from "../../components/MyLotsCard/MyLotsCard";
import jwt from "jwt-decode";
import DatePicker from "react-datepicker";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Auction from "../Auction/Auction";
import CabinetCard from "../../components/CabinetCard/CabinetCard";
import ApplicationItem from "../../components/ApplicationItem/ApplicationItem";
import LotItem from "../../components/LotItem/LotItem";
import ErrorHandler from "../../utils/ErrorLoger";
import CabinetSideBar from "../../components/CabinetSideBar/CabinetSideBar";
//TODO rename to OrganizerLots
function MyLots() {
    const [lots, setLots] = useState([]);
    const [selectedLotId, setSelectedLotId] = useState(null);
    const {request} = useHttp();
    const history = useHistory();

    const getData = async () => {
        let data;
        try{
            data = await request(config.baseUrl+`/api/v1/auctions/organizer/created-auctions/`, 'GET');
            if(data) setLots(data.results);
        }catch (e) {
            ErrorHandler(e)
        }
    }

    useEffect(()=>{
        getData();
    },[])

    return <CabinetSideBar>
                <div className={s.MyLots}>
                    <div className={s.topBar}>
                        {/*<h1>Оголошення</h1>*/}
                        {/*<p>Тут відображаються усі ваші оголошення та ті у яких ви приймали участь</p>*/}
                        <ul className={s.list}>
                            <li>
                                <NavLink activeClassName={s.active} exact to="/mylots/auction/">
                                    <div className={s.listItem}>
                                        <h3>Мої оголошення</h3>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName={s.active} to="/mylots/saved/">
                                    <div className={s.listItem}>
                                        <h3>Збережені</h3>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                <div className={s.content}>
                    {
                        lots.map((item, index)=><CabinetCard key={index} item={item} onSelect={()=>history.push(`/mylots/auction/${item.number}/`)}/>)
                    }
                </div>
            </div>
        </CabinetSideBar>
}

export default MyLots;