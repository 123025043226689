import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import s from './NewOrganizerCabinet.module.css';
import {
    Button,
    CheckboxItem,
    InputItem,
    InputItemShort,
    ItemRow,
    Line, OutlinedButton,
    PlusButton,
    RemoveButton,
    Subtitle,
    Title
} from "../../components/UI/UI";
import userProfileLogo from '../../assets/img/userIcon.png';
import warningPic from '../../assets/img/warning.png';
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
import {useHttp} from "../../hooks/HttpHook";
import {Field, FieldArray, Form, Formik, getIn, useFormikContext} from "formik";
import {
    CheckboxField, DateField, FileField, IbanField,
    NumberMaskField, NumericField,
    PhoneNumberField,
    SelectField, TextAreaField,
    TextField,
} from "../../components/FormikFields/FormikFields";
import Loading from "../../components/Loading/Loading";
import InputField from "../../components/FormikFields/InputField";
import InputMask from "react-input-mask";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {FormikPropsContext} from "../../context/FormikPropsContext";
import Modal from "../../components/Modal/Modal";
import {FileDrop} from "react-file-drop";
import {UserInfoContext} from "../../context/UserInfoContext";
import dictionary from "../../config/dictionary";

export default function LegalCabinet() {
    const [userProfile, setUserProfile] = useState(null);
    const [profileId, setProfileId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalShown, setModalShown] = useState(false);
    const [isValidationError, setValidationError] = useState(false);
    const [isPhotoModalShown, setPhotoModalShown] = useState(false);
    const [loading, setLoading] = useState(false)
    const [previewPhoto, setPreviewPhoto] = useState(userProfileLogo);
    const {getUserInfo} = useContext(UserInfoContext);


    const {request, errors} = useHttp({useFormikLocalization:true});


    const ErrorsHandler = ({errors}) => {
        const {setErrors} = useFormikContext();
        useEffect(()=>{
            if(errors && isEditing) setErrors(errors);
        },[errors])
        return null;
    }

    useEffect(async ()=>{
        try{
            const info = await request(config.baseUrl + `/api/v1/user/info/`,'GET');
            const profId = info.individual_enterpreneur_profile_id ||
                info.individual_profile_id ||
                info.legal_entity_profile_id
            setProfileId(profId)
            if(info.photo_url) setPreviewPhoto(config.baseUrl + info.photo_url)
            const profile = await request(config.baseUrl + `/api/v1/user/${profId}/user-profile/`,'GET');
            setUserProfile(profile);
        }catch(e){
            ErrorHandler(e)
        }
    },[])
    

    const scrollToError = () => {
        const element = document.querySelector("#error")
        const topPos = element.getBoundingClientRect().top + window.pageYOffset-300;

        window.scrollTo({
            top: topPos, // scroll so that the element is at the top of the view
            behavior: 'smooth' // smooth scroll
        })
    }


    const submitHandler = async (values, {setErrors}) => {
        setLoading(true)
        if(!values.legal_entity_chief_address) values.legal_entity_chief_address = {}
        setUserProfile(values)
        try{
            let data = await request(config.baseUrl + `/api/v1/user/${profileId}/user-profile/`,'PUT', {
                ...values,
            });
            if(data && Object.keys(data).length === 0) {
                setLoading(false)
                setModalShown(true);
                setIsEditing(false)
                setErrors(null)

            }else {
                setLoading(false)
                setValidationError(true)
                scrollToError()
            }
        }catch(e){
            ErrorHandler(e)
        }
    }

    const Block = ({children, collapsedComponent}) => {
        const [isCollapsed, setCollapsed] = useState(false)
        return(
            <div className={s.block}>
                <p type="button" className={s.collapseButton} onClick={setCollapsed.bind(this, !isCollapsed)}>
                    {isCollapsed?'+':'-'}
                </p>
                {
                    isCollapsed?
                        collapsedComponent:
                        children
                }
            </div>
        )
    }

    function AlertBlock(){
        return(
            <div className={s.alertBlock}>
                    <p><img src={warningPic}/>Для продовження роботи на майданчику доповніть інформацією та документами свій профіль</p>
            </div>
        )
    }

    function MainBlock(){
        return (
            <div className={s.block}>
                <div className={s.mainBlock}>
                    <div className={s.photoChangeIcon}>
                        <img src={previewPhoto}/>
                        <p onClick={setPhotoModalShown.bind(this, true)}>Змінити фото</p>
                    </div>
                    <div className={s.photoButtons}>
                        <OutlinedButton onClick={setIsEditing.bind(this, !isEditing)} color="#103989">
                            Редагувати
                        </OutlinedButton>
                        <OutlinedButton color="#057E36">
                            Змінити пароль
                        </OutlinedButton>
                    </div>
                </div>

                <InputItemShort label="Організаційно-правова форма:">
                    {/*<InputField/>*/}
                    <input value="Юридична особа" disabled={true}/>
                </InputItemShort>
                <InputItem label="Повна назва юридичної особи:">
                    <InputField name="legal_entity_name" component={TextAreaField}/>
                </InputItem>
                <ItemRow>
                    <InputItem label="Скорочена назва юридичної особи:">
                        <InputField name="legal_entity_short_name" component={TextField}/>
                    </InputItem>
                    <InputItem label="Код ЄДРПОУ:">
                        <InputField mask={"99999999"} name="legal_entity_tax_number" component={NumberMaskField}/>
                    </InputItem>
                </ItemRow>
                <InputItem label="Назва документа, що посвідчує державну реєстрацію юридичної особи:">
                    <InputField name="legal_entity_document_name" component={TextAreaField}/>
                </InputItem>
                <ItemRow>
                    <InputItem label="Дата документа:">
                        <InputField name="legal_entity_document_date" component={DateField}/>
                    </InputItem>
                    <InputItem label="Номер документа:">
                        <InputField name="legal_entity_document_number" component={NumericField}/>
                    </InputItem>
                </ItemRow>
                <InputItemShort>
                    <InputField
                        component={FileField}
                        disabled={!isEditing}
                        name="legal_entity_document_id"
                        description="Максимальний розмір файлу 50 Мб "
                        title="Завантажити документ"
                        url={`/api/v1/user/${profileId}/upload-legal-document/`}
                        uploadMethod="formData"
                        checkDefault={true}
                    />
                </InputItemShort>
            </div>
        )
    }

    function LocationBlock() {
        const props = useFormikContext();
        return(
            <Block collapsedComponent={
                <>
                    <Title style={{marginBottom:0}}>Місцезнаходження юридичної особи</Title>
                </>
            }>
                <Title>Місцезнаходження юридичної особи</Title>
                <Subtitle>Юридична адреса</Subtitle>
                <ItemRow>
                    <InputItem label="Країна:">
                        <InputField
                            name="legal_entity_address.country"
                            url={`/api/v1/location/countries/`}
                            component={SelectField}
                            disabled={!isEditing}
                            customId="code"
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Поштовий індекс:">
                        <InputField
                            name="legal_entity_address.postal_code"
                            maskLength={5}
                            component={NumberMaskField}
                        />

                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Область:">
                        <InputField
                            name="legal_entity_address.region_id"
                            url={`/api/v1/location/regions/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Район:">
                        <InputField
                            name="legal_entity_address.district_id"
                            queryName={'region_id'}
                            queryValue={props.values.legal_entity_address?.region_id}
                            url={`/api/v1/location/districts/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Населений пункт:">
                        <InputField
                            name="legal_entity_address.settlement_id"
                            queryName={'district_id'}
                            queryValue={props.values.legal_entity_address?.district_id}
                            url={`/api/v1/location/settlements/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Вулиця:">
                        <InputField name="legal_entity_address.street" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        <InputItem label="№ будинку:">
                            <InputField name="legal_entity_address.building_number" component={TextField}/>
                        </InputItem>
                        <InputItem label="Корпус:">
                            <InputField name="legal_entity_address.building_block_number" component={TextField}/>
                        </InputItem>
                    </ItemRow>
                    <InputItem label="№ квартири/офісу:">
                        <InputField name="legal_entity_address.apartment_or_office_number" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Номер телефону:">
                        <InputField
                            name="legal_entity_address.phone_number"
                            component={PhoneNumberField}
                        />
                    </InputItem>
                    <InputItem label="E-mail:">
                        <InputField name="legal_entity_address.email" component={TextField}/>
                    </InputItem>
                </ItemRow>
                <FieldArray name="phone_numbers">
                    {({ insert, remove, push }) => (
                        <div>
                            {props.values.phone_numbers.length > 0 &&
                                props.values.phone_numbers.map((friend, index) => (
                                    <div key={index}>
                                        <InputItemShort label="Номер телефону:">
                                            <InputField
                                                defaultNumber={props.values.phone_numbers[index].number}
                                                name={`phone_numbers.${index}.number`}
                                                component={PhoneNumberField}
                                            />
                                        </InputItemShort>
                                        <RemoveButton
                                            disabled={!isEditing}
                                            style={{width:'25%'}}
                                            onClick={() => remove(index)}
                                        >
                                            Видалити телефон
                                        </RemoveButton>
                                        <Line/>
                                    </div>

                                ))}

                            <PlusButton
                                style={{width:'25%'}}
                                onClick={() => push({
                                    number: ''
                                })}
                                disabled={!isEditing}
                            >
                                Додати телефон
                            </PlusButton>
                        </div>
                    )}
                </FieldArray>

                <InputField
                    style={{marginTop:'50px'}}
                    name="legal_entity_address_and_postal_address_is_different"
                    component={CheckboxField}
                    disabled={!isEditing}
                    modifier={(e)=>!e}
                >
                    Юридична адреса збігається з адресою для листування
                </InputField>
                {props.values.legal_entity_address_and_postal_address_is_different && <>
                    <Subtitle style={{marginTop:'50px'}}>Адреса для листування</Subtitle>

                    <ItemRow>
                        <InputItem label="Країна:">
                            <InputField
                                name="legal_entity_postal_address.postal_address_country"
                                url={`/api/v1/location/countries/`}
                                component={SelectField}
                                disabled={!isEditing}
                                customId="code"
                                checkDefaultById
                            />
                        </InputItem>
                        <InputItem label="Поштовий індекс:">
                            <InputField
                                name="legal_entity_postal_address.postal_address_postal_code"
                                maskLength={5}
                                component={NumberMaskField}
                            />
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <InputItem label="Область:">
                            <InputField
                                name="legal_entity_postal_address.postal_address_region_id"
                                url={`/api/v1/location/regions/`}
                                component={SelectField}
                                disabled={!isEditing}
                                checkDefaultById
                            />
                        </InputItem>
                        <InputItem label="Район:">
                            <InputField
                                name="legal_entity_postal_address.postal_address_district_id"
                                queryName={'region_id'}
                                queryValue={props.values.legal_entity_postal_address?.postal_address_region_id}
                                url={`/api/v1/location/districts/`}
                                component={SelectField}
                                disabled={!isEditing}
                                checkDefaultById
                            />
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <InputItem label="Населений пункт:">
                            <InputField
                                name="legal_entity_postal_address.postal_address_settlement_id"
                                queryName={'district_id'}
                                queryValue={props.values.legal_entity_postal_address?.postal_address_district_id}
                                url={`/api/v1/location/settlements/`}
                                component={SelectField}
                                disabled={!isEditing}
                                checkDefaultById
                            />
                        </InputItem>
                        <InputItem label="Вулиця:">
                            <InputField name="legal_entity_postal_address.postal_address_street" component={TextField}/>
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <ItemRow>
                            <InputItem label="№ будинку:">
                                <InputField name="legal_entity_postal_address.postal_address_building_number" component={TextField}/>
                            </InputItem>
                            <InputItem label="Корпус:">
                                <InputField name="legal_entity_postal_address.postal_address_building_block_number" component={TextField}/>
                            </InputItem>
                        </ItemRow>
                        <InputItem label="№ квартири/офісу:">
                            <InputField name="legal_entity_postal_address.postal_address_apartment_or_office_number" component={TextField}/>
                        </InputItem>
                    </ItemRow>
                </>}
            </Block>
        )
    }

    function ChiefBlock() {
        const props = useFormikContext();
        return(
            <Block collapsedComponent={
                <>
                    <Title style={{marginBottom:0}}>Керівник юридичної особи</Title>
                </>
            }>
                <Title>Керівник юридичної особи</Title>

                <ItemRow>
                    <InputItem label="Прізвище:">
                        <InputField name="legal_entity_chief_second_name" component={TextField}/>
                    </InputItem>
                    <InputItem label="Ім’я:">
                        <InputField name="legal_entity_chief_first_name" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="По батькові:">
                        <InputField name="legal_entity_chief_last_name" component={TextField}/>
                    </InputItem>
                    <InputItem label="Назва посади:">
                        <InputField name="legal_entity_chief_job_title" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Громадянство:">
                        <InputField
                            name="legal_entity_chief_citizenship"
                            data={dictionary.CITIZENSHIP}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    {props.values.legal_entity_chief_has_tax_number &&
                        <InputItem label="Реєстраційний номер облікової картки платника податків (РНОКПП):">
                            <InputField mask={"9999999999"} name="legal_entity_chief_tax_number" component={NumberMaskField}/>
                        </InputItem>
                    }
                </ItemRow>

                <ItemRow>
                    <InputField
                        style={{marginTop:'50px'}}
                        name="legal_entity_chief_has_tax_number"
                        component={CheckboxField}
                        disabled={!isEditing}
                        modifier={(e)=>!e}
                    >
                        Керівник відмовився від РНОКПП через свої релігійні переконання та має про це відмітку у паспорті
                    </InputField>
                    {props.values.legal_entity_chief_has_tax_number &&
                        <div>
                            <InputField
                                component={FileField}
                                disabled={!isEditing}
                                name="legal_entity_chief_tax_number_document_id"
                                description="Максимальний розмір файлу 50 Мб "
                                title="Завантажити документ"
                                url={`/api/v1/user/${profileId}/upload-chief-tax-document/`}
                                uploadMethod="formData"
                                checkDefault={true}
                            />
                        </div>
                    }
                </ItemRow>

                <Line/>

                <ItemRow>
                    <InputItem label="Документ що посвідчує особу:">
                        <InputField
                            name="legal_entity_chief_identity_document_type"
                            data={dictionary.DOCUMENT_TYPES}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Дата видачі:">
                        <InputField name="legal_entity_chief_identity_document_issue_date" component={DateField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        {props.values.legal_entity_chief_identity_document_type === "PASSPORT" &&
                            <InputItem label="Серія:">
                                <InputField maxLength={2} name="legal_entity_chief_identity_document_serial_number" component={TextField}/>
                            </InputItem>
                        }
                        <InputItem label="Номер:">
                            <InputField
                                mask={
                                    props.values.legal_entity_chief_identity_document_type === "PASSPORT"?
                                        "999999":
                                        props.values.legal_entity_chief_identity_document_type !== "ANOTHER_DOCUMENT"?
                                            "999999999":
                                            null
                                }
                                name="legal_entity_chief_identity_document_number"
                                component={NumberMaskField}
                            />
                        </InputItem>
                    </ItemRow>
                    <div>
                    <InputField
                        component={FileField}
                        disabled={!isEditing}
                        name="legal_entity_chief_identity_document_id"
                        description="Максимальний розмір файлу 50 Мб "
                        title="Завантажити документ"
                        url={`/api/v1/user/${profileId}/upload-chief-identity-document/`}
                        uploadMethod="formData"
                        checkDefault={true}
                    />
                    </div>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Унікальний № запису в ЄДДР (за наявності):">
                        <InputField mask={"99999999-99999"} name="legal_entity_unified_state_demographic_register" component={NumberMaskField}/>
                    </InputItem>
                </ItemRow>

                <Line/>

                <Subtitle>Місце проживання</Subtitle>

                <ItemRow>
                    <InputItem label="Країна:">
                        <InputField
                            name="legal_entity_chief_address.country"
                            url={`/api/v1/location/countries/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                            customId="code"
                        />
                    </InputItem>
                    <InputItem label="Поштовий індекс:">
                        <InputField
                            name="legal_entity_chief_address.postal_code"
                            maskLength={5}
                            component={NumberMaskField}
                        />

                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Область:">
                        <InputField
                            name="legal_entity_chief_address.region_id"
                            url={`/api/v1/location/regions/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Район:">
                        <InputField
                            name="legal_entity_chief_address.district_id"
                            queryName={'region_id'}
                            queryValue={props.values.legal_entity_chief_address?.region_id}
                            url={`/api/v1/location/districts/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Населений пункт:">
                        <InputField
                            name="legal_entity_chief_address.settlement_id"
                            queryName={'district_id'}
                            queryValue={props.values.legal_entity_chief_address?.district_id}
                            url={`/api/v1/location/settlements/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Вулиця:">
                        <InputField name="legal_entity_chief_address.street" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        <InputItem label="№ будинку:">
                            <InputField name="legal_entity_chief_address.building_number" component={TextField}/>
                        </InputItem>
                        <InputItem label="Корпус:">
                            <InputField name="legal_entity_chief_address.building_block_number" component={TextField}/>
                        </InputItem>
                    </ItemRow>
                    <InputItem label="№ квартири/офісу:">
                        <InputField name="legal_entity_chief_address.apartment_or_office_number" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <InputItem label="Назва документа на підставі якого діє керівник:">
                    <InputField name="legal_entity_chief_document_name" component={TextField}/>
                </InputItem>

                <ItemRow>
                    <InputItem label="Дата документа:">
                        <InputField name="legal_entity_chief_document_date" component={DateField}/>
                    </InputItem>
                    <InputItem label="Номер документа:">
                        <InputField name="legal_entity_chief_document_number" component={NumericField}/>
                    </InputItem>
                </ItemRow>
                <InputItemShort>
                    <InputField
                        component={FileField}
                        disabled={!isEditing}
                        name="legal_entity_chief_document_id"
                        description="Максимальний розмір файлу 50 Мб "
                        title="Завантажити документ"
                        url={`/api/v1/user/${profileId}/upload-chief-document/`}
                        uploadMethod="formData"
                        checkDefault={true}
                    />
                </InputItemShort>
            </Block>
        )
    }

    function AgentBlock() {
        const props = useFormikContext();
        return(
            <>
                <div className={s.block}>
                    <InputField
                        name="legal_entity_has_authorized_person"
                        component={CheckboxField}
                        disabled={!isEditing}
                    >
                        Додати інформацію про особу, уповноважену вчиняти дії від імені юридичної особи
                    </InputField>
                </div>
                {props.values.legal_entity_has_authorized_person &&
                    <Block collapsedComponent={
                        <>
                            <Title style={{marginBottom:0}}>особа уповноважена діяти від імені юридичної особи</Title>
                        </>
                    }>
                        <Title>особа уповноважена діяти від імені юридичної особи</Title>

                        <ItemRow>
                            <InputItem label="Прізвище:">
                                <InputField name="legal_entity_authorized_person_second_name" component={TextField}/>
                            </InputItem>
                            <InputItem label="Ім’я:">
                                <InputField name="legal_entity_authorized_person_first_name" component={TextField}/>
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <InputItem label="По батькові:">
                                <InputField name="legal_entity_authorized_person_last_name" component={TextField}/>
                            </InputItem>
                            <InputItem label="Громадянство:">
                                <InputField
                                    name="legal_entity_authorized_person_citizenship"
                                    data={dictionary.CITIZENSHIP}
                                    component={SelectField}
                                    disabled={!isEditing}
                                    checkDefaultById
                                />
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <InputItem label="Реєстраційний номер облікової картки платника податків (РНОКПП):">
                                <InputField mask={"9999999999"} name="legal_entity_authorized_person_tax_number" component={NumberMaskField}/>
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <InputField
                                style={{marginTop:'50px'}}
                                name="legal_entity_authorized_person_has_tax_number"
                                component={CheckboxField}
                                disabled={!isEditing}
                                modifier={(e)=>!e}
                            >
                                Особа відмовилася від РНОКПП через свої релігійні переконання та має про це відмітку у паспорті
                            </InputField>
                            {props.values.legal_entity_authorized_person_has_tax_number &&
                            <InputField
                                component={FileField}
                                disabled={!isEditing}
                                name="legal_entity_authorized_person_tax_number_document_id"
                                description="Максимальний розмір файлу 50 Мб "
                                title="Завантажити документ"
                                url={`/api/v1/user/${profileId}/upload-legal-authorized-person-tax_number-document/`}
                                uploadMethod="formData"
                                checkDefault={true}
                            />}
                        </ItemRow>

                        <Line/>

                        <ItemRow>
                            <InputItem label="Документ що посвідчує особу:">
                                <InputField
                                    name="legal_entity_authorized_person_identity_document_type"
                                    data={dictionary.DOCUMENT_TYPES}
                                    component={SelectField}
                                    disabled={!isEditing}
                                    checkDefaultById
                                />
                            </InputItem>
                            <InputItem label="Дата видачі:">
                                <InputField name="legal_entity_authorized_person_identity_document_issue_date" component={DateField}/>
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <ItemRow>
                                {props.values.legal_entity_authorized_person_identity_document_type === "PASSPORT" &&
                                    <InputItem label="Серія:">
                                        <InputField maxLength={2} name="legal_entity_authorized_person_identity_document_serial_number" component={TextField}/>
                                    </InputItem>
                                }

                                <InputItem label="Номер:">
                                    <InputField
                                        mask={
                                            props.values.legal_entity_authorized_person_identity_document_type === "PASSPORT"?
                                                "999999":
                                                props.values.legal_entity_authorized_person_identity_document_type !== "ANOTHER_DOCUMENT"?
                                                    "999999999":
                                                    null
                                        }
                                        name="legal_entity_authorized_person_identity_document_number"
                                        component={NumberMaskField}
                                    />
                                </InputItem>
                            </ItemRow>
                            <InputField
                                component={FileField}
                                disabled={!isEditing}
                                name="legal_entity_authorized_person_identity_document_id"
                                description="Максимальний розмір файлу 50 Мб "
                                title="Завантажити документ"
                                url={`/api/v1/user/${profileId}/upload-legal-authorized-person-indentity-document/`}
                                uploadMethod="formData"
                                checkDefault={true}
                            />
                        </ItemRow>

                        <ItemRow>
                            <InputItem label="Унікальний № запису в ЄДДР (за наявності):">
                                <InputField mask={"99999999-99999"} name="legal_entity_authorized_person_unified_state_demographic_reg" component={NumberMaskField}/>
                            </InputItem>
                        </ItemRow>

                        <Line/>

                        <Subtitle>Місце проживання</Subtitle>

                        <ItemRow>
                            <InputItem label="Країна:">
                                <InputField
                                    name="legal_entity_authorized_person_address.country"
                                    url={`/api/v1/location/countries/`}
                                    component={SelectField}
                                    disabled={!isEditing}
                                    checkDefaultById
                                    customId="code"
                                />
                            </InputItem>
                            <InputItem label="Поштовий індекс:">
                                <InputField
                                    name="legal_entity_authorized_person_address.postal_code"
                                    maskLength={5}
                                    component={NumberMaskField}
                                />

                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <InputItem label="Область:">
                                <InputField
                                    name="legal_entity_authorized_person_address.region_id"
                                    url={`/api/v1/location/regions/`}
                                    component={SelectField}
                                    disabled={!isEditing}
                                    checkDefaultById
                                />
                            </InputItem>
                            <InputItem label="Район:">
                                <InputField
                                    name="legal_entity_authorized_person_address.district_id"
                                    queryName={'region_id'}
                                    queryValue={props.values.legal_entity_authorized_person_address?.region_id}
                                    url={`/api/v1/location/districts/`}
                                    component={SelectField}
                                    disabled={!isEditing}
                                    checkDefaultById
                                />
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <InputItem label="Населений пункт:">
                                <InputField
                                    name="legal_entity_authorized_person_address.settlement_id"
                                    queryName={'district_id'}
                                    queryValue={props.values.legal_entity_authorized_person_address?.district_id}
                                    url={`/api/v1/location/settlements/`}
                                    component={SelectField}
                                    disabled={!isEditing}
                                    checkDefaultById
                                />
                            </InputItem>
                            <InputItem label="Вулиця:">
                                <InputField name="legal_entity_authorized_person_address.street" component={TextField}/>
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <ItemRow>
                                <InputItem label="№ будинку:">
                                    <InputField name="legal_entity_authorized_person_address.building_number" component={TextField}/>
                                </InputItem>
                                <InputItem label="Корпус:">
                                    <InputField name="legal_entity_authorized_person_address.building_block_number" component={TextField}/>
                                </InputItem>
                            </ItemRow>
                            <InputItem label="№ квартири/офісу:">
                                <InputField name="legal_entity_authorized_person_address.apartment_or_office_number" component={TextField}/>
                            </InputItem>
                        </ItemRow>

                        <InputItem label="Назва документа на підставі якого діє особа:">
                            <InputField name="legal_entity_authorized_person_authorization_document_name" component={TextField}/>
                        </InputItem>

                        <ItemRow>
                            <InputItem label="Дата документа:">
                                <InputField name="legal_entity_authorized_person_authorization_document_issue_dt" component={DateField}/>
                            </InputItem>
                            <InputItem label="Номер документа:">
                                <InputField name="legal_entity_authorized_person_authorization_document_number" component={NumericField}/>
                            </InputItem>
                        </ItemRow>

                        <InputField
                            component={FileField}
                            disabled={!isEditing}
                            name="legal_entity_authorized_person_authorization_document_id"
                            description="Максимальний розмір файлу 50 Мб "
                            title="Завантажити документ"
                            url={`/api/v1/user/${profileId}/upload-legal-authorized-person-authorization-document/`}
                            uploadMethod="formData"
                            checkDefault={true}
                        />

                        <InputItem label="Ким видано:">
                            <InputField name="legal_entity_authorized_person_authorization_document_issued_by" component={TextField}/>
                        </InputItem>

                    </Block>
                }
            </>

        )
    }

    function BeneficialBlock() {
        const props = useFormikContext();
        return(
            <Block collapsedComponent={
                <>
                    <Title style={{marginBottom:0}}>Відомості про кінцевого бенефенціарного власника юридичної особи</Title>
                </>
            }>
                <Title>Відомості про кінцевого бенефенціарного власника юридичної особи</Title>

                    <FieldArray name="beneficial_owners">
                        {({pop, push}) => (
                            <>
                                <InputItemShort>
                                    <InputItem label="Кінцевий бенефеціарний власник:">
                                        <InputField
                                            placeholder={"Відсутній"}
                                            name="beneficial_owner_exist"
                                            data={[
                                                { name: "Наявний", id: true },
                                                { name: "Відсутній", id: false },
                                            ]}
                                            component={SelectField}
                                            disabled={!isEditing}
                                            checkDefaultById
                                        />
                                    </InputItem>
                                </InputItemShort>

                                {props.values.beneficial_owner_exist &&
                                    <ItemRow>
                                        <InputItem label="Кількість">
                                            {/*<InputField name="beneficial_owners_count" component={NumericField}/>*/}
                                            <InputField
                                                name="beneficial_owners_count"
                                                data={[
                                                    { name: "1", id: 1 },
                                                    { name: "2", id: 2 },
                                                    { name: "3", id: 3 },
                                                    { name: "4", id: 4 },
                                                    { name: "5", id: 5 },
                                                    { name: "6", id: 6 },
                                                    { name: "7", id: 7 },
                                                    { name: "8", id: 8 },
                                                    { name: "9", id: 9 },
                                                    { name: "10", id: 10 },
                                                ]}
                                                component={SelectField}
                                                disabled={!isEditing}
                                                onSelect={(value)=>{
                                                    const ownersLength = props.values.beneficial_owners.length;
                                                    const selectedLength = value[0].id;
                                                    if(selectedLength > ownersLength){
                                                        for(let i = ownersLength; i<selectedLength;i++){
                                                            push({
                                                                'beneficial_owner_first_name': null,
                                                                'beneficial_owner_second_name': null,
                                                                'beneficial_owner_last_name': null,

                                                                'beneficial_owner_citizenship': null,

                                                                'beneficial_owner_has_tax_number': true,
                                                                'beneficial_owner_tax_number': true,
                                                                'beneficial_owner_tax_number_document_id': null,

                                                                'beneficial_owner_identity_document_type': null,
                                                                'beneficial_owner_identity_document_id': null,

                                                                'beneficial_owner_identity_document_serial_number': null,
                                                                'beneficial_owner_identity_document_number': null,
                                                                'beneficial_owner_identity_document_issue_date': null,
                                                                'beneficial_owner_unified_state_demographic_register': null,

                                                                'beneficial_owner_address': {
                                                                    'country': null,
                                                                    'postal_code': null,
                                                                    'region_id': null,
                                                                    'district_id': null,
                                                                    'settlement_id': null,
                                                                    'street': null,
                                                                    'building_number': null,
                                                                    'building_block_number': null,
                                                                    'apartment_or_office_number': null,
                                                                },

                                                                'direct_decisive_influence': false,
                                                                'direct_decisive_influence_percentage': null,
                                                                'indirect_decisive_influence': false,
                                                                'indirect_decisive_influence_percentage': null,

                                                                'other_nature_and_extent_of_influence': false,
                                                                'other_nature_and_extent_of_influence_percentage': null,
                                                            })
                                                        }
                                                    }else if(selectedLength < ownersLength){
                                                        for(let i = selectedLength; i<ownersLength;i++) pop();
                                                    }
                                                    props.setFieldValue('beneficial_owners_count', selectedLength)
                                                }}
                                                checkDefaultById
                                            />
                                        </InputItem>
                                    </ItemRow>
                                }
                                {props.values.beneficial_owner_exist === false &&
                                    <InputItem label="Причина відсутності бенефеціарного власника:">
                                        <InputField name={`beneficial_owner_absence_reason`} component={TextAreaField}/>
                                    </InputItem>
                                }
                                {props.values.beneficial_owner_exist &&
                                    <div>
                                        {!!props.values.beneficial_owners_count && props.values.beneficial_owners
                                                .map((friend, index) =>
                                                    <div key={index}>
                                                        <Line/>
                                                        <ItemRow>
                                                            <InputItem label="Прізвище:">
                                                                <InputField name={`beneficial_owners.${index}.beneficial_owner_second_name`} component={TextField}/>
                                                            </InputItem>
                                                            <InputItem label="Ім’я:">
                                                                <InputField name={`beneficial_owners.${index}.beneficial_owner_first_name`} component={TextField}/>
                                                            </InputItem>
                                                        </ItemRow>

                                                        <ItemRow>
                                                            <InputItem label="По батькові:">
                                                                <InputField name={`beneficial_owners.${index}.beneficial_owner_last_name`} component={TextField}/>
                                                            </InputItem>
                                                            <InputItem label="Громадянство:">
                                                                <InputField
                                                                    name={`beneficial_owners.${index}.beneficial_owner_citizenship`}
                                                                    data={dictionary.CITIZENSHIP}
                                                                    component={SelectField}
                                                                    disabled={!isEditing}
                                                                    checkDefaultById
                                                                />
                                                            </InputItem>
                                                        </ItemRow>

                                                        <ItemRow>
                                                            <InputItem label="Реєстраційний номер облікової картки платника податків (РНОКПП):">
                                                                <InputField mask={"9999999999"} name={`beneficial_owners.${index}.beneficial_owner_tax_number`} component={NumberMaskField}/>
                                                            </InputItem>
                                                        </ItemRow>

                                                        <ItemRow>
                                                            <InputField
                                                                style={{marginTop:'50px'}}
                                                                name={`beneficial_owners.${index}.beneficial_owner_has_tax_number`}
                                                                component={CheckboxField}
                                                                disabled={!isEditing}
                                                                modifier={(e)=>!e}
                                                            >
                                                                Особа відмовилася від РНОКПП через свої релігійні переконання та має про це відмітку у паспорті
                                                            </InputField>
                                                            {props.values.beneficial_owners[index].beneficial_owner_has_tax_number &&
                                                                <InputField
                                                                    component={FileField}
                                                                    disabled={!isEditing}
                                                                    name={`beneficial_owners.${index}.beneficial_owner_tax_number_document_id`}
                                                                    description="Максимальний розмір файлу 50 Мб "
                                                                    title="Завантажити документ"
                                                                    url={`/api/v1/user/${profileId}/upload-legal-beneficial-owner-taxt-document/`}
                                                                    uploadMethod="formData"
                                                                    checkDefault={true}
                                                                />
                                                            }
                                                        </ItemRow>

                                                        <Line/>

                                                        <ItemRow>
                                                            <InputItem label="Документ що посвідчує особу:">
                                                                <InputField
                                                                    name={`beneficial_owners.${index}.beneficial_owner_identity_document_type`}
                                                                    data={dictionary.DOCUMENT_TYPES}
                                                                    component={SelectField}
                                                                    disabled={!isEditing}
                                                                    checkDefaultById
                                                                />
                                                            </InputItem>
                                                            <InputItem label="Дата видачі:">
                                                                <InputField name={`beneficial_owners.${index}.beneficial_owner_identity_document_issue_date`} component={DateField}/>
                                                            </InputItem>
                                                        </ItemRow>

                                                        <ItemRow>
                                                            <ItemRow>
                                                                {props.values.beneficial_owners[index].beneficial_owner_identity_document_type === "PASSPORT" &&
                                                                    <InputItem label="Серія:">
                                                                        <InputField maxLength={2} name={`beneficial_owners.${index}.beneficial_owner_identity_document_serial_number`} component={TextField}/>
                                                                    </InputItem>
                                                                }

                                                                <InputItem label="Номер:">
                                                                    <InputField
                                                                        mask={
                                                                            props.values.beneficial_owners[index].beneficial_owner_identity_document_type === "PASSPORT"?
                                                                                "999999":
                                                                                props.values.beneficial_owners[index].beneficial_owner_identity_document_type !== "ANOTHER_DOCUMENT"?
                                                                                    "999999999":
                                                                                    null
                                                                        }
                                                                        name={`beneficial_owners.${index}.beneficial_owner_identity_document_number`}
                                                                        component={NumberMaskField}
                                                                    />
                                                                </InputItem>
                                                            </ItemRow>
                                                            <InputItem>
                                                                <InputField
                                                                    component={FileField}
                                                                    disabled={!isEditing}
                                                                    name={`beneficial_owners.${index}.beneficial_owner_identity_document_id`}
                                                                    description="Максимальний розмір файлу 50 Мб "
                                                                    title="Завантажити документ"
                                                                    url={`/api/v1/user/${profileId}/upload-legal-beneficial-owner-idenity-document/`}
                                                                    uploadMethod="formData"
                                                                    checkDefault={true}
                                                                />
                                                            </InputItem>
                                                        </ItemRow>

                                                        <ItemRow>
                                                            <InputItem label="Унікальний № запису в ЄДДР (за наявності):">
                                                                <InputField mask={"99999999-99999"} name={`beneficial_owners.${index}.beneficial_owner_unified_state_demographic_register`} component={NumberMaskField}/>
                                                            </InputItem>
                                                        </ItemRow>

                                                        <Line/>

                                                        <Subtitle>Місце проживання</Subtitle>

                                                        <ItemRow>
                                                            <InputItem label="Країна:">
                                                                <InputField
                                                                    name={`beneficial_owners.${index}.beneficial_owner_address.country`}
                                                                    url={`/api/v1/location/countries/`}
                                                                    component={SelectField}
                                                                    disabled={!isEditing}
                                                                    checkDefaultById
                                                                    customId="code"
                                                                />
                                                            </InputItem>
                                                            <InputItem label="Поштовий індекс:">
                                                                <InputField
                                                                    name={`beneficial_owners.${index}.beneficial_owner_address.postal_code`}
                                                                    maskLength={5}
                                                                    component={NumberMaskField}
                                                                />

                                                            </InputItem>
                                                        </ItemRow>

                                                        <ItemRow>
                                                            <InputItem label="Область:">
                                                                <InputField
                                                                    name={`beneficial_owners.${index}.beneficial_owner_address.region_id`}
                                                                    url={`/api/v1/location/regions/`}
                                                                    component={SelectField}
                                                                    disabled={!isEditing}
                                                                    checkDefaultById
                                                                />
                                                            </InputItem>
                                                            <InputItem label="Район:">
                                                                <InputField
                                                                    name={`beneficial_owners.${index}.beneficial_owner_address.district_id`}
                                                                    queryName={'region_id'}
                                                                    queryValue={props.values.beneficial_owners[index]?.beneficial_owner_address.region_id}
                                                                    url={`/api/v1/location/districts/`}
                                                                    component={SelectField}
                                                                    disabled={!isEditing}
                                                                    checkDefaultById
                                                                />
                                                            </InputItem>
                                                        </ItemRow>

                                                        <ItemRow>
                                                            <InputItem label="Населений пункт:">
                                                                <InputField
                                                                    name={`beneficial_owners.${index}.beneficial_owner_address.settlement_id`}
                                                                    queryName={'district_id'}
                                                                    queryValue={props.values.beneficial_owners[index]?.beneficial_owner_address.district_id}
                                                                    url={`/api/v1/location/settlements/`}
                                                                    component={SelectField}
                                                                    disabled={!isEditing}
                                                                    checkDefaultById
                                                                />
                                                            </InputItem>
                                                            <InputItem label="Вулиця:">
                                                                <InputField name={`beneficial_owners.${index}.beneficial_owner_address.street`} component={TextField}/>
                                                            </InputItem>
                                                        </ItemRow>

                                                        <ItemRow>
                                                            <ItemRow>
                                                                <InputItem label="№ будинку:">
                                                                    <InputField name={`beneficial_owners.${index}.beneficial_owner_address.building_number`} component={TextField}/>
                                                                </InputItem>
                                                                <InputItem label="Корпус:">
                                                                    <InputField name={`beneficial_owners.${index}.beneficial_owner_address.building_block_number`} component={TextField}/>
                                                                </InputItem>
                                                            </ItemRow>
                                                            <InputItem label="№ квартири/офісу:">
                                                                <InputField name={`beneficial_owners.${index}.beneficial_owner_address.apartment_or_office_number`} component={TextField}/>
                                                            </InputItem>
                                                        </ItemRow>

                                                        <Line/>

                                                        <Subtitle>
                                                            Характер та міра (рівень ступінь, частка) бенефеціарного володіння (вигоди, інтересу, впливу):
                                                        </Subtitle>

                                                        <Field name={`beneficial_owners.${index}.direct_decisive_influence`} disabled={!isEditing} component={CheckboxField}>
                                                            Прямий вирішальний вплив (контроль):<br/>
                                                            відсоток частки статусного (складеного) капіталу (пайового фонду) чи відсоток права голосу:
                                                        </Field>
                                                        {getIn(props.values, `beneficial_owners.${index}.direct_decisive_influence`) &&
                                                            <InputItemShort style={{marginLeft: '40px'}}>
                                                                <InputField component={NumericField} name={`beneficial_owners.${index}.direct_decisive_influence_percentage`}/>
                                                            </InputItemShort>
                                                        }

                                                        <Field name={`beneficial_owners.${index}.indirect_decisive_influence`} disabled={!isEditing} component={CheckboxField}>
                                                            Непрямий вирішальний вплив (контроль):<br/>
                                                            відсоток частки статусного (складеного) капіталу (пайового фонду) чи відсоток права голосу:
                                                        </Field>
                                                        {getIn(props.values, `beneficial_owners.${index}.indirect_decisive_influence`) &&
                                                            <InputItemShort style={{marginLeft: '40px'}}>
                                                                <InputField component={NumericField} name={`beneficial_owners.${index}.indirect_decisive_influence_percentage`}/>
                                                            </InputItemShort>
                                                        }

                                                        <Field name={`beneficial_owners.${index}.other_nature_and_extent_of_influence`} disabled={!isEditing} component={CheckboxField}>
                                                            Інший характер та міра впливу:
                                                        </Field>
                                                        {getIn(props.values, `beneficial_owners.${index}.other_nature_and_extent_of_influence`) &&
                                                            <InputItemShort style={{marginLeft: '40px'}}>
                                                                <InputField component={NumericField} name={`beneficial_owners.${index}.other_nature_and_extent_of_influence_percentage`}/>
                                                            </InputItemShort>
                                                        }
                                                    </div>
                                                )}

                                    </div>
                                }
                            </>
                        )}
                    </FieldArray>
            </Block>
        )
    }

    function ContactPersonsBlock() {
        const props = useFormikContext();
        return (
            <Block collapsedComponent={
                <>
                    <Title style={{marginBottom:0}}>Контактні особи</Title>
                </>
            }>
                <Title>Контактні особи</Title>

                <FieldArray name="contact_persons">
                    {({ insert, remove, push }) => (
                        <div>
                            {props.values.contact_persons.map((friend, index) => (
                                <div key={index}>
                                    <ItemRow>
                                        <InputItem label="Прізвище:">
                                            <InputField name={`contact_persons.${index}.contact_person_second_name`} component={TextField}/>
                                        </InputItem>
                                        <InputItem label="Ім’я:">
                                            <InputField name={`contact_persons.${index}.contact_person_first_name`} component={TextField}/>
                                        </InputItem>
                                    </ItemRow>

                                    <ItemRow>
                                        <InputItem label="По батькові:">
                                            <InputField name={`contact_persons.${index}.contact_person_last_name`} component={TextField}/>
                                        </InputItem>
                                        <InputItem label="Посада:">
                                            <InputField name={`contact_persons.${index}.contact_person_job_title`} component={TextField}/>
                                        </InputItem>
                                    </ItemRow>

                                    <ItemRow>
                                        <InputItem label="E-mail:">
                                            <InputField name={`contact_persons.${index}.contact_person_email`} component={TextField}/>
                                        </InputItem>
                                        <InputItem label="Номер телефону:">
                                            <InputField name={`contact_persons.${index}.contact_person_phone_number`} component={PhoneNumberField}/>
                                        </InputItem>
                                    </ItemRow>

                                    {index!==0 &&
                                        <>
                                            <RemoveButton disabled={!isEditing} onClick={() => remove(index)} style={{width:'calc(50% - 20px)'}}>
                                                Видалити контактну особу
                                            </RemoveButton>
                                        </>
                                    }
                                    <Line/>
                                </div>

                            ))}

                            <PlusButton
                                disabled={!isEditing}
                                style={{width:'calc(50% - 20px)'}}
                                onClick={() => push({
                                    'contact_person_first_name': '',
                                    'contact_person_second_name': '',
                                    'contact_person_last_name': '',
                                    'contact_person_job_title': '',
                                    'contact_person_email': '',
                                    'contact_person_phone_number': '',
                                })}
                            >
                                Додати контактну особу
                            </PlusButton>
                        </div>
                    )}
                </FieldArray>
            </Block>
        )

    }
    
    const BankDetailsBlock = () => {
        const props = useFormikContext();
        return (
            <Block collapsedComponent={
                <>
                    <Title style={{marginBottom:0}}>Банківські реквізити</Title>
                </>
            }>
                <Title>Банківські реквізити</Title>



                <FieldArray name="settlement_accounts">
                    {({ insert, remove, push }) => (
                        <div>
                            {props.values.settlement_accounts.map((friend, index) => (
                                <div key={index}>
                                    <ItemRow>
                                        <InputItem label="Назва банку:">
                                            <InputField name={`settlement_accounts.${index}.bank_name`} component={TextField}/>
                                        </InputItem>
                                        <InputItem label="Призначення рахунка:">
                                            <InputField
                                                name={`settlement_accounts.${index}.account_assignment`}
                                                data={dictionary.PAYMENT_PURPOSES['ALL']}
                                                component={SelectField}
                                                disabled={!isEditing}
                                                checkDefaultById
                                            />
                                        </InputItem>
                                    </ItemRow>

                                    <ItemRow>
                                        <InputItem label="Код ЄДРПОУ банку:">
                                            <InputField mask="99999999" name={`settlement_accounts.${index}.tax_code`} component={NumberMaskField}/>
                                        </InputItem>
                                        <InputItem label="МФО банку:">
                                            <InputField mask="999999" name={`settlement_accounts.${index}.account_mfo`} component={NumberMaskField}/>
                                        </InputItem>
                                    </ItemRow>

                                    <ItemRow>
                                        <InputItem label="ІВАN:">
                                            <InputField name={`settlement_accounts.${index}.iban`} component={IbanField}/>
                                        </InputItem>
                                        <InputItem label="Валюта рахунка:">
                                            <InputField
                                                name={`settlement_accounts.${index}.currency`}
                                                data={dictionary.CURRENCY}
                                                component={SelectField}
                                                disabled={!isEditing}
                                                checkDefaultById
                                            />
                                        </InputItem>
                                    </ItemRow>

                                    {index!==0 &&
                                        <>
                                            <RemoveButton disabled={!isEditing} onClick={() => remove(index)} style={{width:'calc(50% - 20px)'}}>
                                                Видалити рахунок
                                            </RemoveButton>
                                        </>
                                    }
                                    <Line/>
                                </div>

                            ))}

                            <PlusButton
                                disabled={!isEditing}
                                style={{width:'calc(50% - 20px)'}}
                                onClick={() => push({
                                    'bank_name': "",
                                    'account_assignment': "",
                                    'tax_code': '',
                                    'account_mfo': '',
                                    'iban': '',
                                    'currency': '',

                                })}
                            >
                                Додати рахунок
                            </PlusButton>

                        </div>
                    )}
                </FieldArray>

            </Block>
        )
    }

    const PhotoChangeModal = () => {
        async function fileListToBase64(fileList) {
            function getBase64(file) {
                const reader = new FileReader()
                const fileExtension = file.name.split('.').pop();
                const fileName = file.name.split('.')[0];
                return new Promise(resolve => {
                    reader.onload = ev => {
                        resolve({
                            "file_extension": fileExtension,
                            "encoded_file": ev.target.result,
                            "filename": fileName,
                            "is_main_picture": false
                        })
                    }
                    reader.readAsDataURL(file)
                })
            }
            const promises = []
            for (let i = 0; i < fileList.length; i++) {
                promises.push(getBase64(fileList[i]))
            }
            return await Promise.all(promises)
        }

        const filesHandler = async (files) => {
            const images = await fileListToBase64(files);
            images.map((img)=>{
                const promise = request(config.baseUrl + '/api/v1/user/photo/change/', 'POST', img);
                promise.then((data)=>{
                    setPreviewPhoto(img.encoded_file);
                    getUserInfo();
                    setPhotoModalShown(false);
                })
            })
        }

        return(
            <div className={s.success}>
                <div className={s.successContent}>
                    <h2>Завантажити фото</h2>
                    <p>Доступні формати: .jpg, .jpeg, .png. Максимальний розмір файлу не повинен перевищувати 10 Mb. </p>
                    <FileDrop onDrop={filesHandler} className={s.dropBox} name="document">
                        <div>
                            <h3>Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері</h3>
                            <input onChange={(e)=>filesHandler(e.target.files)} type="file" accept=".jpg, .jpeg, .tiff, .png"/>
                            <button onClick={(e)=>{e.target.parentElement.children[1].click()}}>Обрати файл</button>
                        </div>
                    </FileDrop>
                    <div>
                        <button className={s.closeBtn} onClick={()=>{setPhotoModalShown(false)}}>Відхилити</button>
                        <button className={s.acceptBtn} onClick={()=>{setPhotoModalShown(false)}}>Готово</button>
                    </div>
                </div>
            </div>
        )
    }


    return(
        <div className={s.Cabinet}>
            {isPhotoModalShown && <PhotoChangeModal/>}
            {isModalShown &&
                <Modal isVisible={true} onClose={()=>{
                    setModalShown(false);
                    setIsEditing(false);
                }}>
                    <div className={s.successModal}>
                        <h1>Дані успішно оновлено</h1>
                        <button onClick={()=>{
                            setModalShown(false);
                            setIsEditing(false);
                        }}>Готово</button>
                    </div>
                </Modal>
            }
            {isValidationError &&
                <Modal isVisible={true} onClose={()=>{
                    setValidationError(false);
                }}>
                    <div className={s.successModal}>
                        <h1>Помилка</h1>
                        <p>Будь ласка виправіть всі помилки</p>
                        <button onClick={()=>{
                            setValidationError(false);
                        }}>Закрити</button>
                    </div>
                </Modal>
            }
            {loading && <Loading/>}
            {!userProfile? <Loading/>:
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                    'legal_entity_chief_has_tax_number': true,
                    'legal_entity_authorized_person_has_tax_number': true,
                    'legal_entity_has_authorized_person': false,
                    ...userProfile,

                }}
                onSubmit={submitHandler}
            >
                {props =>
                    <Form>
                        <fieldset disabled={!isEditing}>
                            {props.values.settlement_accounts.length === 0 && <AlertBlock/>}
                            <MainBlock/>
                            <LocationBlock props={props}/>
                            <ChiefBlock props={props}/>
                            <AgentBlock props={props}/>
                            <BeneficialBlock props={props}/>
                            <ContactPersonsBlock props={props}/>
                            <BankDetailsBlock props={props}/>

                            <div className={[s.block, s.contractBlock].join(' ')}>
                                <a target="_blank" href={
                                    props.values.contract_document?
                                        config.baseUrl + props.values.contract_document.file:
                                        null
                                }>Договір приєднання до майданчика</a>
                                <p>Підписано: {props.values.register_at}</p>
                            </div>

                            <Button type="submit" style={{width:'30%', float:'right', marginBottom:'40px'}} color="#057E36">
                                Зберегти зміни
                            </Button>

                            <ErrorsHandler errors={errors}/>
                        </fieldset>
                    </Form>
                }
            </Formik>}

        </div>
    )
}