import React, {Fragment, useState, useRef, useContext, useEffect} from 'react';
import s from './Header.module.css'
import {NavLink, Link, useLocation} from 'react-router-dom';
import logo from '../../assets/img/logo2.svg';
import {AuthContext} from '../../context/AuthContext';
import  userIcon from '../../assets/img/userIcon.png';
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import ErrorHandler from "../../utils/ErrorLoger";
import {UserInfoContext} from "../../context/UserInfoContext";
import {useAuth} from "../../hooks/AuthHook";


function Header(props){
    const {request} = useHttp();
    const [navStyle, setNavStyle] = useState({});
    const [newNotificationsLength, setNewNotificationsLength] = useState(0);
    const {userInfo} = useContext(UserInfoContext);
    const inputRef = useRef()
    const auth = useAuth()

    const getData = async () => {
        let notifications;
        try{
            if(props.isLogined) notifications = await request(config.baseUrl + '/api/v1/notifications/?is_read=False', 'GET');
            if(notifications && notifications.results && notifications.results.length>0) setNewNotificationsLength(notifications.results.length);
            else setNewNotificationsLength(0);
        }catch(e){
            ErrorHandler(e)
        }
    }

    useEffect(()=>{
        getData()
    },[props])
    return(
        <Fragment>
                <nav className={s.navigation} style={navStyle}>
                <Link onClick={()=>inputRef.current.checked = false} to="/auction/" className={s.logo}><img src={logo} alt="logo" /></Link>

                    <div className={s.bottomButtons}>
                    {props.isLogined?
                        <>
                            <Link onClick={()=>inputRef.current.checked = false} className={s.sellLotBut} to="/create/lot/">
                                <p>Продати</p>
                                <i className={s.plusButton + " fa fa-plus-square-o"}/>
                            </Link>
                            <Link onClick={()=>inputRef.current.checked = false} to="/notifications/">
                                <i className={s.bellButton + " fas fa-bell"}/>
                            </Link>
                            <label htmlFor={"list-button"}><i className={s.menuButton + " fa fa-bars"}/></label>
                            <input ref={inputRef} className={s.menuInput} style={{"display":"none"}} id={"list-button"} type={"checkbox"}/>
                            <section className={s.sideMenu}>
                                <h1>Мій кабінет</h1>
                                <ul onClick={()=>inputRef.current.checked = false}>
                                    <li><NavLink to="/mylots/auction/" activeClassName={s.active}><i className="fas fa-layer-group"/>Оголошення</NavLink></li>
                                    <li><NavLink to="/invoices/" activeClassName={s.active}><i className="fas fa-file-invoice-dollar"/>Мої рахунки</NavLink></li>
                                    <li><NavLink to="/applications/" activeClassName={s.active}><i className="fas fa-file-contract"/>Заяви на участь</NavLink></li>
                                    <li><NavLink to="/documents/" activeClassName={s.active}><i className="fas fa-file-image"/>Мої документи</NavLink></li>
                                    <li><NavLink to="/protocols/" activeClassName={s.active}><i className="fas fa-file-powerpoint"/>Мої протоколи</NavLink></li>
                                    <li><NavLink to="/contracts/" activeClassName={s.active}><i className="fas fa-file-alt"/>Мої договори</NavLink></li>
                                    <li><NavLink to="/notifications/" activeClassName={s.active}><i className="fas fa-bell"/>Усі сповіщення</NavLink></li>
                                    <li><NavLink to="/support/" activeClassName={s.active}><i className="fas fa-headset"/>Техпідтримка</NavLink></li>
                                    <li><NavLink to="/profile/" activeClassName={s.active}><i className="fas fa-cog"/>Налаштування</NavLink></li>
                                    <p onClick={auth.logout} className={s.logout}><i className="fas fa-sign-out-alt"/>Вийти</p>
                                </ul>
                            </section>
                        </>

                        :
                            <Link className={s.loginBut} to="/login/">Увійти</Link>
                        }
                    {props.isLogined?
                        <Link to="/mylots/auction" className={s.userBar}>
                            {userInfo?<img alt="img" src={userInfo.photo_url?(config.baseUrl+userInfo.photo_url):userIcon} className={s.userIcon}/>:null}
                            <p className={s.cabinetText}>Мій кабінет</p>
                            <div style={!newNotificationsLength?{visibility:'hidden'}:{}} className={s.notificationCircle}><span>{newNotificationsLength>9?'9+':newNotificationsLength}</span></div>
                        </Link>:null}
                    </div>

                </nav>
                <div className={s.fakeDiv}></div>
        </Fragment>
    )
}

export default Header;