import s from "../NewLot.module.css";
import checkmarkIcon from "../../../assets/img/checkmark-blue.png";
import React from "react";

export default function LotPayment({paymentStatus,auctionInfo, id}){

    const localizePaymentStatuses = (status) => {
        switch (status) {
            case 'AWAITING_WINNER_PAYMENT' : return 'Очікується оплата переможцем'
            case 'WINNER_FULLY_PAYED' : return 'Оплачено'
            case 'PAYMENT_NOT_RECEIVED_FROM_WINNER' : return 'Переможець не оплатив'
            default : return '--'
        }
    }

    return(
        <div id={id} className={s.lotPayment}>
            <h2 className={s.title}>Оплата</h2>
            <p className={s.paymentStatus}>Статус:</p>
            <p className={s.paymentStatusText}>{localizePaymentStatuses(paymentStatus)}</p>
        </div>
    )
}