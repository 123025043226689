import {Form, Formik} from "formik";
import config from "../../../config/config";
import s from "../RegisterRedesign.module.css";
import smsPicture from "../../../assets/img/sms.png";
import InputMask from "react-input-mask";
import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import {AuthContext} from "../../../context/AuthContext";
import Loading from "../../../components/Loading/Loading";
import ErrorHandler from "../../../utils/ErrorLoger";
import Modal from "../../../components/Modal/Modal";

const CodeComponent = () => {
    const auth = useContext(AuthContext);
    const {request, requestErrors, loading} = useHttp();
    const [showModal, setShowModal] = useState(false);

    const resendCode = async () => {
        try {
            let data = await request(config.baseUrl + '/api/v1/user/sms/resend/', 'POST');
            if(data && data.status === 'OK') {
                setShowModal(true);
            }
        }catch (e) {
            ErrorHandler(e);
        }
    }

    const phoneNumber = JSON.parse(localStorage.getItem('registration_data'))?
        JSON.parse(localStorage.getItem('registration_data')).phone_number: ''

        return (
            <>
                {loading && <Loading/>}
                {showModal &&
                    <Modal onClose={()=>{setShowModal(false)}} isVisible={true}>
                        <div className={s.codeModal}>
                            <h1>Код надіслано</h1>
                            <p>СМС-код було повторно надіслано на номер <span>{phoneNumber}</span></p>
                            <button onClick={()=>{setShowModal(false)}}>Закрити</button>
                        </div>
                    </Modal>
                }
            <Formik
                initialValues={{}}
                onSubmit={async (values)=>{
                    try{
                        let data = await request(config.baseUrl + '/api/v1/user/sms/verify/','POST', values);
                        if(data.token && data.token.access) {
                            auth.login(data.token.access, data.token.refresh)
                        }
                    }catch(e){
                        ErrorHandler(e)
                    }
                }}
            >
                {props =>
                    <Form>
                        <p className={s.backButton} onClick={()=>{
                            localStorage.removeItem('registration_data');
                            auth.logout();
                        }}>
                            <i className="fas fa-long-arrow-alt-left"/>
                            Назад
                        </p>
                        <div className={s.confirmationBox}>
                            <h3 className={s.subtitle}>Підтвердження</h3>
                            <div className={s.confirmationContent}>
                                <img src={smsPicture} alt="SMS"/>
                                <div>
                                    <p className={s.blueText}>Ми відправили код підтвердження на ваш номер :</p>
                                    <p className={s.grayText}>{phoneNumber}</p>
                                </div>
                            </div>
                        </div>
                        <div className={s.codeInput}>
                            <label htmlFor="code-input">Введіть код підтвердження:</label>
                            <InputMask
                                id="code-input"
                                onChange={(e)=>{props.setFieldValue("sms_code", e.target.value.replace(/\s+/g, ''),false)}}
                                mask="9 9 9 9"
                                maskChar="_"
                            />
                            {requestErrors && <p className={s.error}>{requestErrors[0]}</p>}
                        </div>
                        <p onClick={resendCode} className={s.resendCodeBtn}>Надіслати код повторно</p>
                        <p className={s.grayText}>В разі якщо код підтвердження активації все ж таки не надходить
                            - зверніться в нашу Службу підтримки</p>
                        <button className={s.registerButton} type="submit">Продовжити реєстрацію</button>
                    </Form>
                }
            </Formik>
                <p className={s.alreadyRegistedText}>Вже зареєстровані?
                    <Link to="/login/">Увійти</Link>
                </p>
                </>
        )
    }

    export default CodeComponent