import s from "../RegisterRedesign.module.css";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import Modal from "../../../components/Modal/Modal";
import LegalThirdStage, {legalSchema} from "./legalEntity/LegalThirdStage";
import IndividualThirdStage, {individualSchema} from "./individual/IndividualThirdStage";
import EntrepreneurThirdStage, {entrepreneurSchema} from "./individualEntrepreneur/EntrepreneurThirdStage";

const ThirdStage = ({mainForm}) => {
        const auth = useContext(AuthContext)
        const [tokens, setTokens] = useState(null);


        const SuccessModal = () => {
            return(
                <Modal isVisible={true} onClose={()=>{
                    auth.login(tokens.access, tokens.refresh, '/profile/');
                }}>
                    <div className={s.successModal}>
                        <h1>Вітаємо</h1>
                        <p>Ви успішно пройшли реєстрацію на електронному майданчику</p>
                        <p>Для продовження роботи перейдіть у свій профіль для доповнення та превірки внесених даних</p>
                        <button onClick={()=>{
                            auth.login(tokens.access, tokens.refresh, '/profile/');
                        }}>Перейти у профіль</button>
                    </div>
                </Modal>
            )
        }



        return (
            <div>
                {tokens && <SuccessModal/>}

                {mainForm.profile_type === 'LEGAL_ENTITY' &&
                    <LegalThirdStage onSuccess={(authTokens)=>setTokens(authTokens)} mainForm={mainForm}/>
                }

                {mainForm.profile_type === 'INDIVIDUAL' &&
                    <IndividualThirdStage onSuccess={(authTokens)=>setTokens(authTokens)} mainForm={mainForm}/>
                }

                {mainForm.profile_type === 'INDIVIDUAL_ENTREPRENEUR' &&
                    <EntrepreneurThirdStage onSuccess={(authTokens)=>setTokens(authTokens)} mainForm={mainForm}/>
                }
            </div>
        )
    }

    export default ThirdStage;