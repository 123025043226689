import s from "../NewLot.module.css";
import React, {useEffect, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import config from "../../../config/config";
import ErrorHandler from "../../../utils/ErrorLoger";

export default function LotAuctionDocuments({auctionInfo, id}) {
    const [protocols, setProtocols] = useState([]);
    const [contracts, setContracts] = useState([]);

    const {request} = useHttp();

    useEffect(()=>{
        try{
            request(`${config.baseUrl}/api/v1/auctions/auction-protocols/published/${auctionInfo.id}/`,'GET').then((data)=>{
                if(data) setProtocols(data);
            });
            request(`${config.baseUrl}/api/v1/auctions/auction-contracts/published/${auctionInfo.id}/`,'GET').then((data)=>{
                if(data) setContracts(data);
            });
        }catch(e){
            ErrorHandler(e)
        }
    },[])

    const DocumentItem = ({date, name, link}) => {
        return(
            <div className={s.documentItem}>
                <table>
                    <tbody>
                    <tr>
                        <th className={s.tableName}>Назва документу</th>
                        <th className={s.tableDate}>Дата і час  розміщення:</th>
                    </tr>
                    <tr>
                        <td>
                            <p>Протокол про проведення аукціону</p>
                            <a target="_blank" href={link} className={s.document}>
                                <i className="far fa-file-alt"/>
                                <p>{name}</p>
                            </a>
                        </td>
                        <td>{date}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div id={id} className={s.lotDocuments}>
            <h2 className={s.title}>Документи земельних торгів</h2>
            {!(protocols.length === 0 && contracts.length === 0) ?
                <>
                        {protocols.map((elem, i) => {
                            const fileParts = elem.signed_file.split('/');
                            const filename = fileParts[fileParts.length-1];
                            return <>
                                {elem.signed_file && <DocumentItem
                                    name={filename}
                                    date={elem.created_at}
                                    link={config.baseUrl + elem.signed_file}
                                    key={i}
                                />}
                            <DocumentItem
                                name={filename}
                                date={elem.created_at}
                                link={config.baseUrl + elem.file}
                                key={i}
                            />
                            </>
                        })}
                        {contracts.map((elem, i) => {
                            const fileParts = elem.signed_file.split('/');
                            const filename = fileParts[fileParts.length-1];
                            return <>
                                {elem.signed_file && <DocumentItem
                                    name={filename}
                                    date={elem.created_at}
                                    link={config.baseUrl + elem.signed_file}
                                    key={i}
                                />}

                                <DocumentItem
                                name={filename}
                                date={elem.created_at}
                                link={config.baseUrl + elem.file}
                                key={i}
                            />
                            </>
                        })}
                </>:
                <p className={s.documentsNotFound}>Документів не знайдено</p>
            }
        </div>
    )
}