export default function formikLocalization(error){
    const errorsTranslation = {
        "null" : "Поле обов\'язкове",
        "blank" : "Поле обов'язкове",
        "required" : "Поле обов'язкове",
        "invalid" : "Невірно введено",
        "invalid_choice": "Невірно обрано"
    }

    return errorsTranslation[error] || error
}
