import React from 'react';
import * as Yup from "yup";
import {Field} from "formik";
import {InputItemShort, Title} from "../../../../components/UI/UI";
import {NumberMaskField, TextAreaField, TextField} from "../../../../components/FormikFields/FormikFields";
import InputField from "../../../../components/FormikFields/InputField";

export default function LegalSecondStage(){
    return(
        <>
            <InputItemShort label="Код ЄДРПОУ:">
                <InputField name="legal_entity_tax_number" maskLength={8} component={NumberMaskField}/>
            </InputItemShort>

            <InputItemShort label="Повна назва юридичної особи:">
                <InputField name="legal_entity_name" component={TextAreaField}/>
            </InputItemShort>

            <Title style={{marginTop:'30px'}}>
                КЕРІВНИК ЮРИДИЧНОЇ ОСОБИ
            </Title>

            <InputItemShort label="Прізвище:">
                <InputField name="legal_entity_chief_second_name" component={TextField}/>
            </InputItemShort>

            <InputItemShort label="Ім'я:">
                <InputField name="legal_entity_chief_first_name" component={TextField}/>
            </InputItemShort>

            <InputItemShort label="По батькові:">
                <InputField name="legal_entity_chief_last_name" component={TextField}/>
            </InputItemShort>

            <InputItemShort label="Назва посади:">
                <InputField name="legal_entity_chief_job_title" component={TextField}/>
            </InputItemShort>

            <InputItemShort label="Назва документа, на підставі якого діє керівник:">
                <InputField name="legal_entity_chief_document_name" component={TextField}/>
            </InputItemShort>
        </>
    )
}

export const legalSchema = Yup.object().shape({
    legal_entity_tax_number: Yup.string()
        .required('Поле обов\'язкове')
        .matches(/^[0-9]+$/, "Невірно введено")
        .min(8, 'Довжина повинна бути 8 цифр')
        .max(8, 'Довжина повинна бути 8 цифр'),
    legal_entity_name: Yup.string()
        .required('Поле обов\'язкове'),
    legal_entity_chief_second_name: Yup.string()
        .required('Поле обов\'язкове'),
    legal_entity_chief_first_name: Yup.string()
        .required('Поле обов\'язкове'),
    legal_entity_chief_last_name: Yup.string()
        .required('Поле обов\'язкове'),
    legal_entity_chief_job_title: Yup.string()
        .required('Поле обов\'язкове'),
    legal_entity_chief_document_name: Yup.string()
        .required('Поле обов\'язкове'),
    profile_type: Yup.string()
        .required('Поле обов\'язкове'),
});

