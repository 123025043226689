import React from 'react';
import s from './UI.module.css';
import Checkbox from "@mui/material/Checkbox";


export function ItemRow({children, style= {}}) {
    return(
        <div style={style} className={s.itemRow}>
            {children}
        </div>
    )
}

export function ItemRowTriple({children, style= {}}) {
    return(
        <div style={style} className={s.itemRowTriple}>
            {children}
        </div>
    )
}

export function InputItem({children, style= {}, label}) {
    return(
        <div style={style} className={s.inputItem}>
            {label && <label>{label}</label>}
            {children}
        </div>
    )
}

export function InputItemShort({children, style= {}, label}) {
    return(
        <div style={style} className={s.inputItem + ' ' + s.short}>
            {label && <label>{label}</label>}
            {children}
        </div>
    )
}

export function Title({children, style= {}}) {
    return(
        <h2 style={style} className={s.title}>
            {children}
        </h2>
    )
}

export function Subtitle({children, style= {}}) {
    return(
        <h2 style={style} className={s.subtitle}>
            {children}
        </h2>
    )
}

export function Error({children, style= {}}) {
    return(
        <p id={"error"} style={style} className={s.error}>
            {children}
        </p>
    )
}

export function PlusButton({children ,style= {}, disabled, onClick, color = '#103989'}) {
    const buttonStyle = {
        color,
        borderColor:color
    };
    const styles = disabled?
        [s.removeAccountBtn, s.blueBtn, s.btnDisabled]:
        [s.removeAccountBtn, s.blueBtn]
    return(
        <div style={{...style, ...buttonStyle}} className={styles.join(' ')} onClick={onClick}>
            <div style={buttonStyle} className={s.removeAccountBtnPlus}>
                <p>+</p>
            </div>
            <p>{children}</p>
        </div>
    )
}

export function RemoveButton({children ,style= {}, disabled, onClick, color = '#ea1a28'}) {
    const buttonStyle = {
        color,
        borderColor:color
    };
    const styles = disabled?
        [s.removeAccountBtn, s.blueBtn, s.btnDisabled]:
        [s.removeAccountBtn, s.blueBtn]
    return(
        <div style={{...style, ...buttonStyle}} className={styles.join(' ')} onClick={onClick}>
            <div style={buttonStyle} className={s.removeAccountBtnPlus}>
                <p>-</p>
            </div>
            <p>{children}</p>
        </div>
    )
}

export function CheckboxItem({style= {}, onChange, children, defaultValue, disabled}) {
    return(
        <div style={style} className={s.checkboxItem}>
            <Checkbox disabled={disabled} defaultChecked={defaultValue} onChange={onChange} color={"success"} style={{padding:0}}/>
            <label className={s.checkboxItemLabel}>{children}</label>
        </div>
    )
}

export function OutlinedButton({children ,style= {}, onClick, color = '#ea1a28'}) {
    const buttonStyle = {
        color,
        borderColor:color,
        backgroundColor:'inherit',
    };
    return(
        <p style={{...style, ...buttonStyle}} className={s.outlinedBtn} onClick={onClick}>
           {children}
        </p>
    )
}

export function Button({style, children, onClick, color , ...props}) {
    const styleObj = Object.assign({},style, {backgroundColor:color})
    return(
        <button {...props} style={styleObj} className={s.button} onClick={onClick}>
            {children}
        </button>
    )
}

export function Line({style}) {
    return(
        <div style={style} className={s.line}/>
    )
}
