import React, {useEffect, useState} from "react";
import s from "./FileDropBox.module.css";
import {FileDrop} from "react-file-drop";
import config from "../../config/config";
import PreviewItem from "../PreviewItem/PreviewItem";
import {useHttp} from "../../hooks/HttpHook";
import ErrorHandler from "../../utils/ErrorLoger";

function FileDropBox(props) {
    const [previewFiles, setPreviewFiles] = useState([])
    const [filesIds, setFilesIds] = useState([])
    const {request} = useHttp()

    const deleteFilesHandler = async (id) => {
        let oldIds = [...filesIds];
        const fileId = oldIds.splice(id, 1);
        props.onSelect(oldIds)
        setFilesIds(oldIds);
        let oldPreviews = [...previewFiles];
        oldPreviews.splice(id, 1);
        setPreviewFiles(oldPreviews);
        try{
            if(props.type==='photo') await request(config.baseUrl + `/api/v1/land-market/lands/photos/${fileId}/delete/`, 'DELETE');
            else await request(config.baseUrl + `/api/v1/user/documents/${fileId}/delete/`, 'DELETE');
        }catch (e) {
            ErrorHandler(e)
        }

    }

    async function fileListToBase64(fileList) {
        function getBase64(file) {
            const reader = new FileReader()
            const fileExtension = file.name.split('.').pop();
            const fileName = file.name.split('.')[0];
            return new Promise(resolve => {
                reader.onload = ev => {
                    resolve({
                        "file_extension": fileExtension,
                        "encoded_file": ev.target.result,
                        "filename": fileName,
                        "is_main_picture": false
                    })
                }
                reader.readAsDataURL(file)
            })
        }
        const promises = []
        for (let i = 0; i < fileList.length; i++) {
            promises.push(getBase64(fileList[i]))
        }
        return await Promise.all(promises)
    }

    const filesDropHandler = async (targetFiles) => {
        const files = await fileListToBase64(targetFiles);
        let promises = [];
        let ids = [];
        let preview = [];
        console.log(files)
        files.map((file)=>{
            if(!previewFiles.some((el)=>el.encoded_file === file.encoded_file)){
                let promise;
                if(props.type==='photo') promise = request(config.baseUrl + '/api/v1/land-market/lands/photos/upload/','POST', file);
                else promise = request(config.baseUrl + '/api/v1/land-market/lands/documents/upload/','POST', file);

                promise.then((data)=>{
                    ids.push(data.file_id);
                    preview.push(file);
                })
                promises.push(promise);
            }
        })
        Promise.all(promises).then(()=>{
            setFilesIds([...filesIds,...ids]);
            setPreviewFiles([...previewFiles,...preview]);
            props.onSelect([...filesIds,...ids])
        })

    }

    useEffect(()=>{
        console.log('default',props.defaultFiles)
        if(props.defaultFiles){
            setPreviewFiles(props.defaultFiles.map((file)=>{
                console.log('file',file)
                const fileParts = file.url.split('/');
                return {
                    ...file,
                    encoded_file: file.url,
                    filename: fileParts[fileParts.length-1]
                }
            }))
            setFilesIds(props.defaultFiles.map((file)=>file.id))
        }
    },[props.defaultFiles])
    return(
        <div className={s.photos}>
            <h2>{props.header}</h2>
            <p>{props.title}</p>
            <FileDrop className={s.dropBox} name="document">
                <div>
                    <h3>{props.subtitle}</h3>
                    <input onChange={(e)=>filesDropHandler(e.target.files)} type="file" multiple accept={props.accept}/>
                    <button type="button" onClick={(e)=>{e.target.parentNode.children[1].click()}}>Обрати файл</button>
                </div>
            </FileDrop>
            <div className={s.documentsItems}>
                {previewFiles.map((item, i)=>{
                    return <PreviewItem onDelete={deleteFilesHandler} id={i}  key={item.encoded_file} item={item}/>
                })}
            </div>
        </div>
    )
}

export default FileDropBox;