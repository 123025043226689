import React, {useCallback, useEffect, useState} from 'react';
import {CheckboxItem} from "../UI/UI";
import CustomSelect from "../CustomSelect/CustomSelect";
import NestedSelect from "../NestedSelect/NestedSelect";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import ErrorMessage from "./ErrorMessage";
import format from "date-fns/format";
import s from "../../pages/Lot/Lot.module.css";
import FileSign from "../FileSign/FileSignCopy";
import {Field, getIn} from "formik";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uk from 'date-fns/locale/uk';
registerLocale('uk', uk)

function ErrorsWrapper({errors, fieldName, children}){
   return(
       <>
           {children}
           {/*<ErrorMessage name={fieldName}/>*/}
           {/* <p>{index(errors,fieldName)}</p>*/}
       </>
       )
}



export function CheckboxField({field, form, modifier = (e)=>e, ...props}){
    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <CheckboxItem
                defaultValue={modifier(field.value)}
                onChange={(e, value)=>form.setFieldValue(field.name, modifier(value))}
                {...props}
            >
                {props.children}
            </CheckboxItem>
        </ErrorsWrapper>
    );
}

export function SelectField({field, form, ...props}){
    const value = field.value;
    const defaultValueProps = value && props.checkDefaultByName?
        {defaultValueByName: value}:
            value && props.checkDefaultById?
                {defaultValueId: value}:
                {}


    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
                <CustomSelect
                    {...defaultValueProps}
                    onSelect={(value, id)=>{
                        if(props.selectHandler) props.selectHandler(id)
                        form.setFieldValue(field.name, id)
                        if(props.modifier) props.modifier(value, id)
                    }}
                    {...props}
                />
        </ErrorsWrapper>
    );
}

export function FileField({field, form, ...props}){
    let documentFieldName = field.name.slice(0, -3);
    function getDefaultFile(){
        if(props.checkDefault && getIn(form.values, documentFieldName)) {
            const _file = getIn(form.values, documentFieldName)
            const pathParts = _file.file.split('/')
            let document = {}
            document.id = getIn(form.values,field.name)
            document.filename = pathParts[pathParts.length - 1]
            return document
        }else return null
    }

    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <FileSign
                onSelect={(id, _document)=>{
                    form.setFieldValue(field.name, id)
                    form.setFieldValue(documentFieldName, {
                        file: _document[0].filename,
                        created_at: Date.now()
                    })
                }}
                defaultDocument={getDefaultFile()}
                {...props}
            />
        </ErrorsWrapper>
    );
}

export function CategoryField({field, form, ...props}){
    function selectHandler(item){
        if(item) form.setFieldValue(field.name, item[props.selectLabel])
        if(props.onSelect) props.onSelect(item);
    }

    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <NestedSelect
                // onSelect={(value, id)=>form.setFieldValue(field.name, id)}
                {...props}
                onSelect={selectHandler}
                defaultValue={field.value}
            />
        </ErrorsWrapper>
    );
}



export function NumericField({field, form, ...props}){
    const [value, setValue] = useState(field.value)

    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <input
                step="any"
                type="number"
                value={value}
                onChange={(e) => {
                    if(props.maxLength){
                        if(e.target.value.length <= props.maxLength){
                            form.setFieldValue(field.name, e.target.value);
                            setValue(e.target.value)
                        }
                    }else{
                        form.setFieldValue(field.name, e.target.value);
                        setValue(e.target.value)
                    }
                }}
                {...props}
            />
        </ErrorsWrapper>
    );
}

export function NumberMaskField({field, form, ...props}){
    const [value, setValue] = useState(field.value)
    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <InputMask
                value={value}
                onBlur={()=>form.validateField(field.name)}
                onChange={(e)=>{
                    form.setFieldValue(field.name, e.target.value,false);
                    setValue(e.target.value);
                }}
                mask={
                    props.maskLength?
                        '9'.repeat(props.maskLength):
                        props.mask
                }
                name={field.name}
                {...props}
            />
        </ErrorsWrapper>
    );
}

export function PhoneNumberField({field, form, ...props}){
    const defaultNumber = props.defaultNumber || field.value;
    const [value, setValue] = useState(defaultNumber);
    const numberChangeHandler = (e) =>{
        const number = '+' + e.target.value.replace(/[^0-9]/g,'');
        form.setFieldValue(field.name, number,false);
        setValue(number)
    }
    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <InputMask
                value={value}
                onBlur={()=>form.validateField(field.name)}
                onChange={numberChangeHandler}
                mask="+38 (099) 999-99-99"
                name={field.name}
                {...props}
            />
        </ErrorsWrapper>
    );
}

export function IbanField({field, form, ...props}){
    const defaultNumber = props.defaultNumber || field.value;
    const [value, setValue] = useState(defaultNumber);
    const numberChangeHandler = (e) =>{
        const number = e.target.value;
        form.setFieldValue(field.name, number,false);
        setValue(number)
    }
    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <InputMask
                value={value}
                onBlur={()=>form.validateField(field.name)}
                onChange={numberChangeHandler}
                mask="aa999999999999999999999999999"
                name={field.name}
                {...props}
            />
        </ErrorsWrapper>
    );
}

export function DateField({field, form, ...props}){
    const [date, setDate] = useState(field.value && new Date(field.value))
    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <DatePicker
                placeholderText={props.placeholder}
                dateFormat="yyyy-MM-dd"
                selected={date}
                showYearDropdown
                disabled={props.disabled}
                scrollableYearDropdown
                yearDropdownItemNumber={60}
                maxDate={+(new Date()) + 31556926000}
                onChange={(d)=>{
                    const formattedDate = format(d,"yyyy-MM-dd", {awareOfUnicodeTokens: true });
                    form.setFieldValue(field.name, formattedDate)
                    //form.setFieldValue(field.name+'_timestamp', d)
                    setDate(new Date(d));
                    if(props.modifier) props.modifier(d)
                }}
                locale="uk"
                {...props}
            />
        </ErrorsWrapper>
    );
}

export function TextField({field, form, ...props}){
    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <input
                onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                defaultValue={field.value}
                autoComplete="no-auto-complete"
                {...props}
            />
        </ErrorsWrapper>
    );
}

export function TextAreaField({field, form, ...props}){

    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <textarea
                style={{resize:'none'}}
                defaultValue={field.value}
                onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                {...props}
            />
        </ErrorsWrapper>
    );
}

export function EmptyField({field, form, ...props}){
    return (
        <ErrorsWrapper errors={form.errors} fieldName={field.name}>
            <input
                onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                defaultValue={field.value}
                autoComplete="no-auto-complete"
                {...props}
            />
        </ErrorsWrapper>
    );
}