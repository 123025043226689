import {useState, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import jwt from 'jwt-decode';
import config from '../config/config.js'
export const useAuth = () => {
    const STORAGE_NAME = 'userData';
    //const dispatch = useDispatch();
    const [token, setToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [scope, setScope] = useState(null);
    const history = useHistory();

    const login = useCallback((jwtToken, jwtRefreshToken = '', urlToRedirect) => {

        if(jwtToken){
            setToken(jwtToken);
            localStorage.setItem(STORAGE_NAME, JSON.stringify({
                token: jwtToken,
            }))
        }
        if(jwtRefreshToken){
            setRefreshToken(jwtRefreshToken);
            localStorage.setItem(STORAGE_NAME, JSON.stringify({
                token: jwtToken,
                refreshToken: jwtRefreshToken
            }))
        }

        if(jwt(jwtToken).email) {
            setScope('google-add-phone');
        }else {
            const _scope = jwt(jwtToken).scope;
            if(_scope) setScope(_scope);
            else setScope('none');
        }
        if(urlToRedirect) history.push(urlToRedirect);
    }, []);
    const logout = useCallback(() => {
        setToken(null);
        setRefreshToken(null);
        setScope('none');
        localStorage.removeItem(STORAGE_NAME);
    }, []);

    const fetchRefreshToken = async() => {
        const tokenRefreshResponse = await fetch(config.baseUrl + '/api/v1/user/token/refresh/', {
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                "refresh":refreshToken
            }) 
        });
        const data = await tokenRefreshResponse.json();
        login(data.access, refreshToken);
        return data.access;
    }

    useEffect(()=>{
        let data;
        if(localStorage.getItem(STORAGE_NAME))
        data = JSON.parse(localStorage.getItem(STORAGE_NAME));
        
        if(data && data.token) {
            login(data.token, data.refreshToken || '');
        }else setScope('none')

    },[login])

    return { login, logout, token, refreshToken, scope, fetchRefreshToken }
}