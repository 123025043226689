import {Link, List, ListItem} from "@mui/material";
import s from "../NewLot.module.css";
import React from "react";

export default function Menu() {
    return (
        <List className={s.list}>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#info"
                    underline="none"
                >Загальна інформація</Link>
            </ListItem>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#description"
                    underline="none"
                >Опис лота</Link>
            </ListItem>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#documents"
                    underline="none"
                >Документи лоту</Link>
            </ListItem>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#organizer"
                    underline="none"
                >Організатор торгів</Link>
            </ListItem>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#bank"
                    underline="none"
                >Банківські реквізити</Link>
            </ListItem>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#questions"
                    underline="none"
                >Запитання</Link>
            </ListItem>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#details"
                    underline="none"
                >Деталі аукціону</Link>
            </ListItem>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#auction_documents"
                    underline="none"
                >Документи торгів</Link>
            </ListItem>
            <ListItem className={s.linkItem}>
                <Link
                    className={s.link}
                    href="#payment"
                    underline="none"
                >Оплата</Link>
            </ListItem>
        </List>
    )
}