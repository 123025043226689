import s from './Footer.module.css';
import logo from '../../assets/img/logo2.svg';
import facebookLogo from '../../assets/img/facebook.svg'
import youtubeLogo from '../../assets/img/youtube.svg'
import telegramLogo from '../../assets/img/telegram.svg'
import {OutlinedButton} from "../UI/UI";

export default function Footer(){
    return(
        <>
            <div style={{height:'360px'}}/>
            <div className={s.Footer}>
                <div className={s.footerTop}>
                    <img className={s.logo} src={logo}/>
                    <div className={s.footerContent}>
                        <div className={s.footerColumn}>
                            <a>Про нас</a>
                            <a>Регламент</a>
                            <a>Договір приєднання</a>
                        </div>
                        <div className={s.footerColumn}>
                            <a>Інструкції для користувачів</a>
                            <a>Питання та відповіді</a>
                            <a>Новини</a>
                        </div>
                        <div className={s.footerColumn}>
                            <a>Тарифи</a>
                            <a>Реквізити</a>
                            <a>Контакти</a>
                        </div>
                        <div className={s.footerColumn}>
                            <div className={s.imagesBlock}>
                                <img src={youtubeLogo}/>
                                <img src={telegramLogo}/>
                                <img src={facebookLogo}/>
                            </div>
                            <OutlinedButton style={{padding:'0 5px'}} color={'#103989'}>
                                Задати питання
                            </OutlinedButton>
                        </div>
                    </div>
                </div>
                <div className={s.footerBottom}>
                    <div className={s.line}/>
                    <p className={s.rights}>© Права на землю. Всі права захищено.</p>
                </div>
            </div>

        </>
    )
}