import React, {useEffect, useState} from 'react'
import s from './AddLotRedesign.module.css'
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {FileDrop} from "react-file-drop";
import PreviewItem from "../../components/PreviewItem/PreviewItem";
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import FileSign from "../../components/FileSign/FileSign";
import FileDropBox from "../../components/FileDropBox/FileDropBox";
import {Field, FieldArray, ErrorMessage, Form, Formik, useFormikContext} from "formik";
import DatePicker from "react-datepicker";
import { addDays } from '@progress/kendo-date-math';
import InputMask from "react-input-mask";
import Modal from '../../components/Modal/Modal'
import {useHistory} from 'react-router-dom';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uk from 'date-fns/locale/uk';
registerLocale('uk', uk)
import ErrorHandler from "../../utils/ErrorLoger";
import {
    Error,
    InputItem,
    InputItemShort,
    ItemRow,
    ItemRowTriple,
    Line,
    PlusButton,
    RemoveButton,
    Subtitle
} from "../../components/UI/UI";
import {
    CategoryField,
    DateField, IbanField, NumberMaskField,
    NumericField, PhoneNumberField,
    SelectField,
    TextField
} from "../../components/FormikFields/FormikFields";
import InputField from "../../components/FormikFields/InputField";
import dictionary from "../../config/dictionary";
function AddLotRedesign() {
    const [startDate, setStartDate] = useState();
    const [selectedCategory, setSelectedCategory] = useState({});
    const [purposes, setPurposes] = useState([]);
    const history = useHistory();
    const [modalText, setModalText] = useState(null);
    const [isModalShown, setModalShown] = useState(false);
    const {request, errors} = useHttp({useFormikLocalization:true});

    const getHolidays = async () => {
        let data;
        try{
            data = await request(config.baseUrl + `/api/v1/auctions/holidays/${'2022'}/`);
        }catch(e){
            ErrorHandler(e)
        }
    }
    const ErrorsHandler = ({errors}) => {
        const {setErrors} = useFormikContext();
        useEffect(()=>{
            if(errors) setErrors(errors);
        },[errors])
        return null;
    }

    useEffect(()=>{
        getHolidays();
    },[])

    return (
        <div className={s.AddLotRedesign}>
            {modalText &&
                <Modal
                    onClose={()=>{
                        setModalText(null);
                        history.push('/');
                    }}
                    isVisible={true}
                >
                    <div className={s.successModal}>
                        <h1>{modalText}</h1>
                        <button onClick={()=>{
                            setModalText(null);
                            history.push('/');
                        }}>На головну</button>
                    </div>
                </Modal>
            }

            <h1 className={s.title}>Оголошення про аукціон</h1>
            <div className={s.content}>
                <Formik
                    validateOnChange={false}
                    initialValues={{
                        lot:{
                            has_preference_subjects:false,
                            preference_subjects:[],
                            location:{}
                        },
                        bank_accounts:[{
                            bank_name:'',
                            bank_tax_number:'',
                            bank_code: '',
                            iban_number:'',
                            currency:'',
                            account_purpose:'',
                        }],
                    }}
                    onSubmit={async (values)=>{
                        let form = {...values};
                        if(!values.lot.has_preference_subjects) delete form.lot.preference_subjects;
                        try {
                            let data = await request(config.baseUrl + '/api/v1/auctions/create/', 'POST', form);
                            if(data && data.auction_id) setModalText('Оголошення було успішно створене')
                            else{
                                setModalShown(true)
                            }
                        } catch (e) {
                            ErrorHandler(e)
                        }
                    }}
                >
                    {props => (
                        <Form className={s.form}>
                            {isModalShown &&
                                <Modal isVisible={true} onClose={()=>{
                                    setModalShown(false);
                                }}>
                                    <div className={s.successModal}>
                                        <h1>Помилка</h1>
                                        <p>Будь ласка виправіть всі помилки</p>
                                        <button onClick={()=>{
                                            setModalShown(false);
                                        }}>Закрити</button>
                                    </div>
                                </Modal>
                            }

                            <Subtitle>Оголошення № 000 000 001</Subtitle>
                            <InputItem label="Тема оголошення:">
                                <InputField name="lot.theme" component={TextField}/>
                            </InputItem>

                            <ItemRow>
                                <InputItem label="Тип оголошення:">
                                    <InputField
                                        name="aaa"
                                        data={[{id:0, name:'Аукціон'}]}
                                        component={SelectField}
                                    />
                                </InputItem>
                                <InputItem label="Вид торгів:">
                                    <InputField
                                        name="auction_type"
                                        data={[
                                            { name: "Первинні", id: 'PRIMARY' },
                                            { name: "Вторинні", id: 'SECONDARY' },
                                        ]}
                                        modifier={(values, id) => {
                                            if(id==='PRIMARY') delete props.values.primary_auction_number;
                                        }}
                                        component={SelectField}
                                    />
                                </InputItem>
                            </ItemRow>


                            {props.values.auction_type==='SECONDARY' &&
                                <InputItemShort label="Номер вторинного аукціону:">
                                    <InputField
                                        name="primary_auction_number"
                                        component={TextField}
                                    />
                                </InputItemShort>
                            }
                            <ItemRow>
                                <InputItem label="Тип процедури:">
                                    <InputField
                                        name="lot.procedure_type_id"
                                        url="/api/v1/land-market/lands/procedure-types/"
                                        component={SelectField}
                                    />
                                </InputItem>
                                <InputItem label="Кількість лотів:">
                                    <InputField
                                        name="lots_count"
                                        component={NumericField}
                                    />
                                </InputItem>

                            </ItemRow>

                            <ItemRow>
                                <InputItem label="Дата початку аукціону:">
                                    <InputField name="date_start" customInput={
                                        <DatePicker
                                            name="date_start"
                                            dateFormat="yyyy-MM-dd HH:mm"
                                            selected={startDate}
                                            onChange={(date)=>{
                                                props.setFieldValue("date_start", date.toISOString(),false);
                                                setStartDate(+date)
                                            }}
                                            timeFormat="HH:mm"
                                            showTimeSelect
                                            locale="uk"
                                            minDate={addDays(new Date(),31)}
                                            maxDate={addDays(new Date(),46)}
                                            minTime={new Date('December 17, 1995 09:00:00')}
                                            maxTime={new Date('December 17, 1995 18:00:00')}
                                            component={DateField}
                                        />}
                                    />
                                </InputItem>
                                <InputItem label="Вид земельних угідь:">
                                    <InputField
                                        nestingKeys={['land_type_classifier_types']}
                                        name="lot.land_type_classifier_id"
                                        url="/api/v1/land-market/lands/land-type-classifiers/"
                                        placeholder="Оберіть вид земельних угідь"
                                        codeLabel="code"
                                        nameLabel="name"
                                        selectLabel="id"
                                        component={CategoryField}
                                        checkDefaultById
                                    />
                                </InputItem>
                            </ItemRow>

                            <ItemRowTriple>
                                <InputItem label="Період редагування:">
                                    <input disabled/>
                                </InputItem>
                                <InputItem label="Період уточнень:">
                                    <input disabled/>
                                </InputItem>
                                <InputItem label="Період подання пропозицій:">
                                    <input disabled/>
                                </InputItem>
                            </ItemRowTriple>

                            <div className={s.gap}/>
                            <Line/>

                            <Subtitle className={s.blue}>Інформація про лот</Subtitle>

                            <InputItem label="Номер лоту:">
                                <InputField name="lot.number" component={TextField}/>
                            </InputItem>

                            <InputItem>
                                <InputField name="lot.photo_ids" customInput={
                                    <FileDropBox
                                        type="photo"
                                        header="Фото ділянки"
                                        title="Доступні формати: .jpg, .jpeg, .tiff, .png . Максимальний розмір файлу не повинен перевищувати 10 Mb."
                                        subtitle="Перетягніть фото сюди
або вкажіть шлях до них на вашому комп’ютері"
                                        accept=".jpg, .jpeg, .tiff, .png"
                                        onSelect={(ids)=>{props.setFieldValue("lot.photo_ids", ids,false)}}
                                    />}
                                />
                            </InputItem>

                            <InputItem label="Опис лоту:">
                                <InputField name="lot.description" component={TextField}/>
                            </InputItem>

                            <div className={s.gap}/>

                            <Subtitle>Місцезнаходження</Subtitle>

                            <ItemRow>
                                <InputItem label="Область:">
                                    <InputField
                                        name="lot.location.region_id"
                                        url="/api/v1/location/regions/"
                                        component={SelectField}
                                    />
                                    {console.log(props.errors)}
                                    {props.errors["location.region_id"] && <Error>Поле обов'язкове</Error>}
                                </InputItem>
                                <InputItem label="Район:">
                                    <InputField
                                        name="lot.location.district_id"
                                        queryName={'region_id'}
                                        queryValue={props.values.lot?.location?.region_id}
                                        url={`/api/v1/location/districts/`}
                                        component={SelectField}
                                    />
                                    {props.errors["location.district_id"] && <Error>Поле обов'язкове</Error>}
                                </InputItem>
                            </ItemRow>

                            <ItemRow>
                                <InputItem label="Населенний пункт:">
                                    <InputField
                                        name="lot.location.settlement_id"
                                        queryName={'district_id'}
                                        queryValue={props.values.lot?.location?.district_id}
                                        url={`/api/v1/location/settlements/`}
                                        component={SelectField}
                                    />
                                    {props.errors["location.settlement_id"] && <Error>Поле обов'язкове</Error>}

                                </InputItem>
                                <InputItem label="Вулиця:">
                                    <InputField
                                        name="lot.location.street"
                                        component={TextField}
                                    />
                                    {props.errors["location.street"] && <Error>Поле обов'язкове</Error>}
                                </InputItem>
                            </ItemRow>

                            <ItemRowTriple>
                                <InputItem label="Кадастровий номер:">
                                    <InputField name="lot.cadastral_number" mask="9999999999:99:999:9999" component={NumberMaskField}/>
                                </InputItem>
                                <InputItem label="Площа (га):">
                                    <InputField
                                        name="lot.area"
                                        component={NumericField}
                                    />
                                </InputItem>
                                <InputItem label="Форма власності:">
                                    <InputField
                                        name="lot.ownership_type"
                                        data={[
                                            { name: "Комунальна", id: 'COMMUNAL' },
                                            { name: "Державна", id: 'STATE' },
                                            { name: "Приватна", id: 'PRIVATE' },
                                        ]}
                                        component={SelectField}
                                    />
                                </InputItem>
                            </ItemRowTriple>

                            <ItemRow>
                                <InputItem label="Категорія земель:">
                                    <InputField
                                        name="cav_ps_code"
                                        nestingKeys={['first_nested_categories', 'second_nested_categories', 'purpose_categories']}
                                        url="/api/v1/land-market/lands/purpose-categories/"
                                        placeholder="Класифікатор CAV-PS"
                                        codeLabel="cav_ps_code"
                                        nameLabel="name"
                                        selectLabel="cav_ps_code"
                                        component={CategoryField}
                                        onSelect={(item, form)=>{
                                            console.log(item)
                                            props.setFieldValue('lot.purpose_id', undefined, false)
                                            if(item.id) props.setFieldValue('lot.category_id', item.id, false)
                                            if(item.land_purposes){
                                                setPurposes(item.land_purposes)
                                                setSelectedCategory(item)
                                            }else {
                                                setPurposes([])
                                                setSelectedCategory(null)
                                            }
                                        }}
                                        checkDefaultById
                                    />
                                </InputItem>

                                <InputItem label="Цільове призначення:">
                                    <InputField
                                        disabled={purposes.length<=0}
                                        name="lot.purpose_id"
                                        nestingKeys={['first_nested_categories', 'second_nested_categories', 'purpose_categories']}
                                        placeholder="Оберіть цільове призначення"
                                        component={CategoryField}
                                        codeLabel="code"
                                        nameLabel="description"
                                        selectLabel="id"
                                        data={purposes}
                                        checkDefaultById
                                    />
                                </InputItem>
                            </ItemRow>

                            <InputItem label="Співвласники (за наявності):">
                                <InputField
                                    name="lot.co_owners"
                                    component={TextField}
                                />
                            </InputItem>

                            <InputItem label="Обтяження прав на земельну ділянку, обмеження у використанні земель (за наявності):">
                                <InputField
                                    name="lot.restrictions"
                                    component={TextField}
                                />
                            </InputItem>

                            <InputItem label="Містобудівні умови земельної ділянки (за наявності):">
                                <InputField
                                    name="lot.city_building_conditions"
                                    component={TextField}
                                />
                            </InputItem>

                            <InputItem label="Інженерні мережі (за наявності):">
                                <InputField
                                    name="lot.engineering_networks"
                                    component={TextField}
                                />
                            </InputItem>

                            {selectedCategory?.has_preference &&
                                <InputItemShort label="Наявність суб’єкта переважного права:">
                                    <InputField
                                        name="lot.has_preference_subjects"
                                        component={SelectField}
                                        onSelect={(values, id) => {
                                            if(id=='true') props.setFieldValue("lot.preference_subjects",[{
                                                name:'',
                                                preference_type:''
                                            }],false);
                                            else if(id=='false') props.setFieldValue("lot.preference_subjects",[],false);

                                        }}
                                        data={[
                                            { name: "Так", id: true },
                                            { name: "Ні", id: false },
                                        ]}
                                    />
                                </InputItemShort>
                            }


                            {props.values.lot?.has_preference_subjects &&
                                <FieldArray name="lot.preference_subjects">
                                    {({ remove, push }) => (
                                        <div>
                                            {props.values.lot.preference_subjects.length > 0 &&
                                                props.values.lot.preference_subjects.map((subject, index) => (
                                                    <div key={index}>
                                                        <InputItem label="Повне найменування або прізвище, ім’я, по батькові суб’єкта переважного права:">
                                                            <InputField
                                                                name={`lot.preference_subjects.${index}.name`}
                                                                component={TextField}
                                                            />
                                                        </InputItem>

                                                        <ItemRow>
                                                            <InputItem label="Вибрати чергу:">
                                                                <InputField
                                                                    name={`lot.preference_subjects.${index}.preference_type`}
                                                                    component={SelectField}
                                                                    data={[
                                                                        { name: "Суб’єкт переважного права 1 черги", id: 'SPECIAL_USE_PREFERENCE' },
                                                                        { name: "Суб’єкт переважного права 2 черги", id: 'RENTER_PREFERENCE' },
                                                                    ]}
                                                                />
                                                            </InputItem>
                                                        </ItemRow>


                                                        {index!==0 &&
                                                            <RemoveButton style={{width:'calc(50% - 20px)'}} onClick={() => remove(index)}>
                                                                Видалити суб'єкта
                                                            </RemoveButton>
                                                        }
                                                        <Line/>
                                                    </div>
                                                ))}
                                            <PlusButton style={{width:'calc(50% - 20px)'}} onClick={() => push({
                                                bank_name:'',
                                                bank_tax_number:'',
                                                bank_code: '',
                                                iban_number:'',
                                                currency:'',
                                                account_purpose:''
                                            })}>
                                                Додати суб'єкта
                                            </PlusButton>
                                        </div>
                                    )}
                                </FieldArray>}


                            <InputItem label="Дата реєстрації Організатором наміру щодо продажу земельної ділянки у Державному реєстрі речових прав на нерухоме майно:">
                                <InputField name="lot.registration_date_of_intention_to_sell_in_state_register" component={DateField}/>
                            </InputItem>

                            <Line/>

                            <ItemRow>
                                <InputItem label="Експертна / Нормативна грошова оцінка:">
                                    <InputField name="lot.expert_estimated_cost" component={NumericField}/>
                                </InputItem>
                                <InputItem label="Сума витрат (видатків), здійснених на підготовку лота:">
                                    <InputField name="lot.preparation_expenses" component={NumericField}/>
                                </InputItem>
                            </ItemRow>

                            <InputItemShort label="Стартова ціна:">
                                <InputField name="starting_price" component={NumericField}/>
                            </InputItemShort>


                            <Line/>

                            <Subtitle>Документи лоту</Subtitle>

                            <InputItem>
                                <InputField name="lot.photo_ids" customInput={
                                    <FileDropBox
                                    type="document"
                                    header="Документи лоту"
                                    title="Доступні формати: .jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx . Максимальний розмір файлу не повинен перевищувати 20 Mb."
                                    subtitle="Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері"
                                    accept=".jpg, .jpeg, .tiff, .png, .pdf, .doc, .docx"
                                    onSelect={(ids)=>{props.setFieldValue("lot.document_ids", ids,false)}}
                                    />}
                                />
                            </InputItem>


                            <Line/>

                            <Subtitle>Організатор земельних торгів</Subtitle>

                            <InputItem label="Повне найменування юридичної особи або повне прізчище, ім’я по батькові фізичної особи:">
                                <InputField name="organizer_name" component={TextField}/>
                            </InputItem>

                            <InputItem label="Адреса:">
                                <InputField name="organizer_address" component={TextField}/>
                            </InputItem>

                            <InputItem label="Код ЄДРПОУ або ІПН:">
                                <InputField mask="9999999999" name="organizer_tax_number" component={NumberMaskField}/>
                            </InputItem>

                            <Line/>

                            <Subtitle>Банківські реквізити</Subtitle>

                            <FieldArray name="bank_accounts">
                                {({ remove, push }) => (
                                    <div>
                                        {props.values.bank_accounts.map((friend, index) => (
                                            <div key={index}>
                                                <ItemRow>
                                                    <InputItem label="Назва банку:">
                                                        <InputField name={`bank_accounts.${index}.bank_name`} component={TextField}/>
                                                    </InputItem>
                                                    <InputItem label="Призначення рахунка:">
                                                        <InputField
                                                            name={`bank_accounts.${index}.account_purpose`}
                                                            data={dictionary.PAYMENT_PURPOSES['ALL']}
                                                            component={SelectField}
                                                            checkDefaultById
                                                        />
                                                    </InputItem>
                                                </ItemRow>

                                                <ItemRow>
                                                    <InputItem label="Код ЄДРПОУ банку:">
                                                        <InputField mask="99999999" name={`bank_accounts.${index}.bank_tax_number`} component={NumberMaskField}/>
                                                    </InputItem>
                                                    <InputItem label="МФО банку:">
                                                        <InputField mask="999999" name={`bank_accounts.${index}.bank_code`} component={NumberMaskField}/>
                                                    </InputItem>
                                                </ItemRow>

                                                <ItemRow>
                                                    <InputItem label="ІВАN:">
                                                        <InputField name={`bank_accounts.${index}.iban_number`} component={IbanField}/>
                                                    </InputItem>
                                                    <InputItem label="Валюта рахунка:">
                                                        <InputField
                                                            name={`bank_accounts.${index}.currency`}
                                                            data={dictionary.CURRENCY}
                                                            component={SelectField}
                                                            checkDefaultById
                                                        />
                                                    </InputItem>
                                                </ItemRow>

                                                {index!==0 &&
                                                    <>
                                                        <RemoveButton onClick={() => remove(index)} style={{width:'calc(50% - 20px)'}}>
                                                            Видалити рахунок
                                                        </RemoveButton>
                                                    </>
                                                }
                                                <Line/>
                                            </div>

                                        ))}

                                        <PlusButton
                                            style={{width:'calc(50% - 20px)'}}
                                            onClick={() => push({
                                                'bank_name': "",
                                                'account_assignment': "",
                                                'tax_code': '',
                                                'account_mfo': '',
                                                'iban': '',
                                                'currency': '',

                                            })}
                                        >
                                            Додати рахунок
                                        </PlusButton>

                                    </div>
                                )}
                            </FieldArray>

                            <Line/>

                            <Subtitle>Контактна особа</Subtitle>

                            <InputItem label="Прізвище, ім’я, по батькові:">
                                <InputField name="contact_person_name" component={TextField}/>
                            </InputItem>

                            <InputItem label="E-mail:">
                                <InputField name="contact_person_email" component={TextField}/>
                            </InputItem>

                            <InputItem label="Номер телефону:">
                                <InputField
                                    name="contact_person_phone_number"
                                    id="contact_person_phone_number"
                                    component={PhoneNumberField}
                                />
                            </InputItem>
                            <ErrorsHandler errors={errors}/>
                            <button className={s.saveButton} type="submit">Зберегти</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}


export default AddLotRedesign;