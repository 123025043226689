import './App.css';
import {useState} from 'react';
import {useRoutes} from './routes';
import Header from './components/Header/Header'
import {AuthContext} from './context/AuthContext'
import {UserInfoContext} from './context/UserInfoContext'
import { useAuth } from './hooks/AuthHook';
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {ErrorBoundary} from "@sentry/react";
import Modal from "./components/Modal/Modal";
import {Button} from "./components/UI/UI";
import {useHistory} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import {useUserInfo} from "./hooks/UserInfoHook";
// import {useUserInfo} from "./hooks/UserInfoHook";
// import {StaticDataContext} from "./context/StaticDataContext";
// import {useStaticData} from "./hooks/StaticDataHook";

function UnhandledErrorModal(props) {
    return <Modal style={{padding: "30px"}} onClose={props.onClose} isVisible={true}>
        <h1>Сталась невідома помилка</h1>
        <p>Ми вже працюємо щоб це виправити</p>
        <Button onClick={props.onClose} style={{width: "40%", margin: "auto", marginTop: "30px"}}>
            На головну
        </Button>
    </Modal>;
}


function App() {
const [renderStatus, setRenderStatus] = useState(false);
const {token, refreshToken, scope, login, logout, fetchRefreshToken} = useAuth();
// const {getLocationData, location} = useStaticData();
const {getUserInfo, userInfo, getNotifications, notifications, getNewNotifications, newNotifications, profileId} = useUserInfo(scope);
const isAuthenticated = !!token;
const history = useHistory();
const renderHeader = () => {
    setRenderStatus(!renderStatus);
}
const routes = useRoutes(scope, renderHeader);

  return (
      // <ErrorBoundary fallback={
      //         <UnhandledErrorModal onClose={() => {
      //             history.push('/')
      //         }}/>
      // }>
          <AuthContext.Provider value={{
              token,
              refreshToken,
              scope,
              login,
              logout,
              fetchRefreshToken,
              isAuthenticated
          }}>
              <UserInfoContext.Provider value={{
                  getUserInfo,
                  userInfo,
                  getNotifications,
                  notifications,
                  getNewNotifications,
                  newNotifications,
                  profileId
              }}>
                  {/*<StaticDataContext.Provider value={{*/}
                  {/*    getLocationData,*/}
                  {/*    location*/}
                  {/*}}>*/}
                      <div style={{minHeight:'100vh', position:'relative'}} className="App">
                          <Header status={renderStatus} isLogined={scope === 'main'}/>
                              {routes}
                          <Footer/>
                      </div>
                  {/*</StaticDataContext.Provider>*/}
              </UserInfoContext.Provider>

          </AuthContext.Provider>
      // </ErrorBoundary>
  );
}

export default App;
