import React, {useCallback, useContext, useEffect, useState} from "react";
import s from './NewOrganizerCabinet.module.css';
import {
    Button,
    CheckboxItem,
    InputItem,
    InputItemShort,
    ItemRow,
    Line, OutlinedButton,
    PlusButton,
    RemoveButton,
    Subtitle,
    Title
} from "../../components/UI/UI";
import userProfileLogo from '../../assets/img/userIcon.png';
import warningPic from '../../assets/img/warning.png';
import FileSign from "../../components/FileSign/FileSign";
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
import {useHttp} from "../../hooks/HttpHook";
import {Field, FieldArray, Form, Formik, getIn, useFormikContext} from "formik";
import {
    CheckboxField, DateField, FileField, IbanField,
    NumberMaskField, NumericField,
    PhoneNumberField,
    SelectField, TextAreaField,
    TextField,
} from "../../components/FormikFields/FormikFields";
import Loading from "../../components/Loading/Loading";
import InputField from "../../components/FormikFields/InputField";
import InputMask from "react-input-mask";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {FormikPropsContext} from "../../context/FormikPropsContext";
import Modal from "../../components/Modal/Modal";
import {FileDrop} from "react-file-drop";
import {UserInfoContext} from "../../context/UserInfoContext";
import dictionary from "../../config/dictionary";

export default function IndividualCabinet() {
    const [userProfile, setUserProfile] = useState(null);
    const [profileId, setProfileId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalShown, setModalShown] = useState(false);
    const [loading, setLoading] = useState(false)
    const [isValidationError, setValidationError] = useState(false);
    const [isPhotoModalShown, setPhotoModalShown] = useState(false);
    const [previewPhoto, setPreviewPhoto] = useState(userProfileLogo);


    const [dropdownData, setDropdownData] = useState({
        countries:[],
        regions:[],
        districts:[],
        settlements:[],
    });

    const {request, errors} = useHttp({useFormikLocalization:true});
    const {getUserInfo} = useContext(UserInfoContext)
    async function getLocationData(){
        const countries = await request(config.baseUrl + `/api/v1/location/countries/`,'GET');
        const regions = await request(config.baseUrl + `/api/v1/location/regions/`,'GET');
        const settlements = await request(config.baseUrl + `/api/v1/location/settlements/`,'GET');
        const districts = await request(config.baseUrl + `/api/v1/location/districts/`,'GET');
        setDropdownData({...dropdownData, countries, regions, settlements, districts})
    }

    const ErrorsHandler = ({errors}) => {
        const {setErrors} = useFormikContext();
        useEffect(()=>{
            if(errors && isEditing) setErrors(errors);
        },[errors])
        return null;
    }

    useEffect(async ()=>{
        try{
            const info = await request(config.baseUrl + `/api/v1/user/info/`,'GET');
            const profId = info.individual_enterpreneur_profile_id ||
                info.individual_profile_id ||
                info.legal_entity_profile_id
            setProfileId(profId)
            const profile = await request(config.baseUrl + `/api/v1/user/${profId}/user-profile/`,'GET');
            if(info.photo_url) setPreviewPhoto(config.baseUrl + info.photo_url)
            setUserProfile(profile);
            getLocationData();
        }catch(e){
            ErrorHandler(e)
        }
    },[])

    const scrollToError = () => {
        const element = document.querySelector("#error")
        const topPos = element.getBoundingClientRect().top + window.pageYOffset-300;

        window.scrollTo({
            top: topPos, // scroll so that the element is at the top of the view
            behavior: 'smooth' // smooth scroll
        })
    }

    const submitHandler = async (values, {setErrors}) => {
        setLoading(true)
        setUserProfile(values)
        try{
            let data = await request(config.baseUrl + `/api/v1/user/${profileId}/user-profile/`,'PUT', {
                ...values,
            });
            if(data && Object.keys(data).length === 0) {
                setLoading(false)
                setModalShown(true);
                setIsEditing(false)
                setErrors(null)
            }else {
                setLoading(false)
                setValidationError(true)
                scrollToError()
            }
        }catch(e){
            ErrorHandler(e)
        }
    }

    const Block = ({children, collapsedComponent}) => {
        const [isCollapsed, setCollapsed] = useState(false)
        return(
            <div className={s.block}>
                <p type="button" className={s.collapseButton} onClick={setCollapsed.bind(this, !isCollapsed)}>
                    {isCollapsed?'+':'-'}
                </p>
                {
                    isCollapsed?
                        collapsedComponent:
                        children
                }
            </div>
        )
    }

    function AlertBlock(){
        return(
            <div className={s.alertBlock}>
                <p><img src={warningPic}/>Для продовження роботи на майданчику доповніть інформацією та документами свій профіль</p>
            </div>
        )
    }

    function MainBlock(){
        const props = useFormikContext();
        return (
            <div className={s.block}>
                <div className={s.mainBlock}>
                    <div className={s.photoChangeIcon}>
                        <img src={previewPhoto}/>
                        <p onClick={setPhotoModalShown.bind(this, true)}>Змінити фото</p>
                    </div>
                    <div className={s.photoButtons}>
                        <OutlinedButton onClick={setIsEditing.bind(this, !isEditing)} color="#103989">
                            Редагувати
                        </OutlinedButton>
                        <OutlinedButton color="#057E36">
                            Змінити пароль
                        </OutlinedButton>
                    </div>
                </div>

                <InputItemShort label="Організаційно-правова форма:">
                    {/*<InputField/>*/}
                    <input value="Фізична особа" disabled={true}/>
                </InputItemShort>
                <ItemRow>
                    <InputItem label="Прізвище:">
                        <InputField name="individual_second_name" component={TextField}/>
                    </InputItem>
                    <InputItem label="Ім'я:">
                        <InputField name="individual_first_name" component={TextField}/>
                    </InputItem>
                </ItemRow>
                <ItemRow>
                    <InputItem label="По батькові:">
                        <InputField name="individual_last_name" component={TextField}/>
                    </InputItem>
                    <InputItem label="Громадянство:">
                        <InputField
                            name="individual_citizenship"
                            data={[
                                { name: "Україна", id: 'Ukraine' },
                            ]}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                </ItemRow>
                <ItemRow>
                    <div>
                        {props.values.individual_has_tax_number &&
                            <InputItem label="Реєстраційний номер облікової картки платника податків (РНОКПП):">
                                <InputField mask={"9999999999"} name="individual_tax_number" component={NumberMaskField}/>
                            </InputItem>
                        }
                        <InputField
                            style={{marginTop:'50px'}}
                            name="individual_has_tax_number"
                            component={CheckboxField}
                            disabled={!isEditing}
                            modifier={(e)=>!e}
                        >
                            Особа відмовилась від РНОКПП через свої релігійні переконання та має про це відмітку у паспорті
                        </InputField>
                    </div>

                    {props.values.individual_has_tax_number &&
                        <InputItem label=" ">
                            <InputField
                                component={FileField}
                                disabled={!isEditing}
                                name="individual_tax_number_document_id"
                                description="Максимальний розмір файлу 50 Мб "
                                title="Завантажити документ"
                                url={`/api/v1/user/${profileId}/upload-individual-tax-document/`}
                                uploadMethod="formData"
                                checkDefault={true}
                            />
                        </InputItem>
                    }
                </ItemRow>
                <Line/>

                <ItemRow>
                    <InputItem label="Документ що посвідчує особу:">
                        <InputField
                            name="individual_identity_document_type"
                            data={dictionary.DOCUMENT_TYPES}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Дата видачі:">
                        <InputField maxDate={new Date()} name="individual_identity_document_issue_date" component={DateField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        {props.values.individual_identity_document_type === "PASSPORT" &&
                            <InputItem label="Серія:">
                                <InputField maxLength={2} name="individual_identity_document_serial_number" component={TextField}/>
                            </InputItem>
                        }

                        <InputItem label="Номер:">
                            <InputField
                                mask={
                                    props.values.individual_identity_document_type === "PASSPORT"?
                                        "999999":
                                        props.values.individual_identity_document_type !== "ANOTHER_DOCUMENT"?
                                            "999999999":
                                            null
                                }
                                name="individual_identity_document_number"
                                component={NumberMaskField}
                            />
                        </InputItem>
                    </ItemRow>

                    <div>
                        <InputField
                            component={FileField}
                            disabled={!isEditing}
                            name="individual_identity_document_id"
                            description="Максимальний розмір файлу 50 Мб "
                            title="Завантажити документ"
                            url={`/api/v1/user/${profileId}/upload-individual-identity-document/`}
                            uploadMethod="formData"
                            checkDefault={true}
                        />
                    </div>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Унікальний № запису в ЄДДР (за наявності):">
                        <InputField mask={"99999999-99999"} name="individual_unified_state_demographic_register"
                                    component={NumberMaskField}/>
                    </InputItem>
                </ItemRow>
            </div>
        )
    }

    function LocationBlock() {
        const props = useFormikContext();
        return(
            <Block collapsedComponent={
                <>
                    <Title style={{marginBottom:0}}>Адреса фізичної особи</Title>
                </>
            }>
                <Title>Адреса фізичної особи</Title>
                <ItemRow>
                    <InputItem label="Країна:">
                        <InputField
                            name="individual_address.country"
                            data={dropdownData.countries}
                            component={SelectField}
                            disabled={!isEditing}
                            customId="code"
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Поштовий індекс:">
                        <InputField
                            name="individual_address.postal_code"
                            maskLength={5}
                            component={NumberMaskField}
                        />

                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Область:">
                        <InputField
                            name="individual_address.region_id"
                            url={`/api/v1/location/regions/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Район:">
                        <InputField
                            name="individual_address.district_id"
                            queryName={'region_id'}
                            queryValue={props.values.individual_address?.region_id}
                            url={`/api/v1/location/districts/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Населений пункт:">
                        <InputField
                            name="individual_address.settlement_id"
                            queryName={'district_id'}
                            queryValue={props.values.individual_address?.district_id}
                            url={`/api/v1/location/settlements/`}
                            component={SelectField}
                            disabled={!isEditing}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Вулиця:">
                        <InputField name="individual_address.street" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        <InputItem label="№ будинку:">
                            <InputField name="individual_address.building_number" component={TextField}/>
                        </InputItem>
                        <InputItem label="Корпус:">
                            <InputField name="individual_address.building_block_number" component={TextField}/>
                        </InputItem>
                    </ItemRow>
                    <InputItem label="№ квартири/офісу:">
                        <InputField name="individual_address.apartment_or_office_number" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Номер телефону:">
                        <InputField
                            name="individual_address.phone_number"
                            component={PhoneNumberField}
                        />
                    </InputItem>
                    <InputItem label="E-mail:">
                        <InputField name="individual_address.email" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <InputField
                    style={{marginTop:'50px'}}
                    name="individual_address_and_postal_address_is_different"
                    component={CheckboxField}
                    disabled={!isEditing}
                    modifier={(e)=>!e}
                >
                    Юридична адреса збігається з адресою для листування
                </InputField>
                {props.values.individual_address_and_postal_address_is_different && <>
                    <Subtitle style={{marginTop:'50px'}}>Адреса для листування</Subtitle>

                    <ItemRow>
                        <InputItem label="Країна:">
                            <InputField
                                name="individual_postal_address.postal_address_country"
                                data={dropdownData.countries}
                                component={SelectField}
                                disabled={!isEditing}
                                customId="code"
                                checkDefaultById
                            />
                        </InputItem>
                        <InputItem label="Поштовий індекс:">
                            <InputField
                                name="individual_postal_address.postal_address_postal_code"
                                maskLength={5}
                                component={NumberMaskField}
                            />
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <InputItem label="Область:">
                            <InputField
                                name="individual_postal_address.postal_address_region_id"
                                data={dropdownData.regions}
                                component={SelectField}
                                disabled={!isEditing}
                                checkDefaultById
                            />
                        </InputItem>
                        <InputItem label="Район:">
                            <InputField
                                name="individual_postal_address.postal_address_district_id"
                                queryName={'region_id'}
                                queryValue={props.values.individual_postal_address?.postal_address_region_id}
                                url={`/api/v1/location/districts/`}
                                component={SelectField}
                                disabled={!isEditing}
                                checkDefaultById
                            />
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <InputItem label="Населений пункт:">
                            <InputField
                                name="individual_postal_address.postal_address_settlement_id"
                                queryName={'district_id'}
                                queryValue={props.values.individual_postal_address?.postal_address_district_id}
                                url={`/api/v1/location/settlements/`}
                                component={SelectField}
                                disabled={!isEditing}
                                checkDefaultById
                            />
                        </InputItem>
                        <InputItem label="Вулиця:">
                            <InputField name="individual_postal_address.postal_address_street" component={TextField}/>
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <ItemRow>
                            <InputItem label="№ будинку:">
                                <InputField name="individual_postal_address.postal_address_building_number" component={TextField}/>
                            </InputItem>
                            <InputItem label="Корпус:">
                                <InputField name="individual_postal_address.postal_address_building_block_number" component={TextField}/>
                            </InputItem>
                        </ItemRow>
                        <InputItem label="№ квартири/офісу:">
                            <InputField name="individual_postal_address.postal_address_apartment_or_office_number" component={TextField}/>
                        </InputItem>
                    </ItemRow>
                </>}
            </Block>
        )
    }

    const BankDetailsBlock = () => {
        const props = useFormikContext();
        return (
            <Block collapsedComponent={
                <>
                    <Title style={{marginBottom:0}}>Банківські реквізити</Title>
                </>
            }>
                <Title>Банківські реквізити</Title>

                <FieldArray name="settlement_accounts">
                    {({ insert, remove, push }) => (
                        <div>
                            {props.values.settlement_accounts.map((friend, index) => (
                                <div key={index}>
                                    <ItemRow>
                                        <InputItem label="Назва банку:">
                                            <InputField name={`settlement_accounts.${index}.bank_name`} component={TextField}/>
                                        </InputItem>
                                        <InputItem label="Призначення рахунка:">
                                            <InputField
                                                name={`settlement_accounts.${index}.account_assignment`}
                                                data={dictionary.PAYMENT_PURPOSES['ALL']}
                                                component={SelectField}
                                                disabled={!isEditing}
                                                checkDefaultById
                                            />
                                        </InputItem>
                                    </ItemRow>

                                    <ItemRow>
                                        <InputItem label="Код ЄДРПОУ банку:">
                                            <InputField mask="99999999" name={`settlement_accounts.${index}.tax_code`} component={NumberMaskField}/>
                                        </InputItem>
                                        <InputItem label="МФО банку:">
                                            <InputField mask="999999" name={`settlement_accounts.${index}.account_mfo`} component={NumberMaskField}/>
                                        </InputItem>
                                    </ItemRow>

                                    <ItemRow>
                                        <InputItem label="ІВАN:">
                                            <InputField name={`settlement_accounts.${index}.iban`} component={IbanField}/>
                                        </InputItem>
                                        <InputItem label="Валюта рахунка:">
                                            <InputField
                                                name={`settlement_accounts.${index}.currency`}
                                                data={dictionary.CURRENCY}
                                                component={SelectField}
                                                disabled={!isEditing}
                                                checkDefaultById
                                            />
                                        </InputItem>
                                    </ItemRow>

                                    {index!==0 &&
                                        <>
                                            <RemoveButton disabled={!isEditing} onClick={() => remove(index)} style={{width:'calc(50% - 20px)'}}>
                                                Видалити рахунок
                                            </RemoveButton>
                                        </>
                                    }
                                    <Line/>
                                </div>

                            ))}

                            <PlusButton
                                disabled={!isEditing}
                                style={{width:'calc(50% - 20px)'}}
                                onClick={() => push({
                                    'bank_name': "",
                                    'account_assignment': "",
                                    'tax_code': '',
                                    'account_mfo': '',
                                    'iban': '',
                                    'currency': '',

                                })}
                            >
                                Додати рахунок
                            </PlusButton>

                        </div>
                    )}
                </FieldArray>

            </Block>
        )
    }

    const PhotoChangeModal = () => {
        async function fileListToBase64(fileList) {
            function getBase64(file) {
                const reader = new FileReader()
                const fileExtension = file.name.split('.').pop();
                const fileName = file.name.split('.')[0];
                return new Promise(resolve => {
                    reader.onload = ev => {
                        resolve({
                            "file_extension": fileExtension,
                            "encoded_file": ev.target.result,
                            "filename": fileName,
                            "is_main_picture": false
                        })
                    }
                    reader.readAsDataURL(file)
                })
            }
            const promises = []
            for (let i = 0; i < fileList.length; i++) {
                promises.push(getBase64(fileList[i]))
            }
            return await Promise.all(promises)
        }

        const filesHandler = async (files) => {
            const images = await fileListToBase64(files);
            images.map((img)=>{
                const promise = request(config.baseUrl + '/api/v1/user/photo/change/', 'POST', img);
                promise.then((data)=>{
                    setPreviewPhoto(img.encoded_file);
                    getUserInfo();
                    setPhotoModalShown(false);
                })
            })
        }

        return(
            <div className={s.success}>
                <div className={s.successContent}>
                    <h2>Завантажити фото</h2>
                    <p>Доступні формати: .jpg, .jpeg, .png. Максимальний розмір файлу не повинен перевищувати 10 Mb. </p>
                    <FileDrop onDrop={filesHandler} className={s.dropBox} name="document">
                        <div>
                            <h3>Перетягніть фото та файли сюди або вкажіть шлях до них на вашому комп’ютері</h3>
                            <input onChange={(e)=>filesHandler(e.target.files)} type="file" accept=".jpg, .jpeg, .tiff, .png"/>
                            <button onClick={(e)=>{e.target.parentElement.children[1].click()}}>Обрати файл</button>
                        </div>
                    </FileDrop>
                    <div>
                        <button className={s.closeBtn} onClick={()=>{setPhotoModalShown(false)}}>Відхилити</button>
                        <button className={s.acceptBtn} onClick={()=>{setPhotoModalShown(false)}}>Готово</button>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className={s.Cabinet}>
            {isPhotoModalShown && <PhotoChangeModal/>}
            {isModalShown &&
                <Modal isVisible={true} onClose={()=>{
                    setModalShown(false);
                    setIsEditing(false);
                }}>
                    <div className={s.successModal}>
                        <h1>Дані успішно оновлено</h1>
                        <button onClick={()=>{
                            setModalShown(false);
                            setIsEditing(false);
                        }}>Готово</button>
                    </div>
                </Modal>
            }
            {isValidationError &&
                <Modal isVisible={true} onClose={()=>{
                    setValidationError(false);
                }}>
                    <div className={s.successModal}>
                        <h1>Помилка</h1>
                        <p>Будь ласка виправіть всі помилки</p>
                        <button onClick={()=>{
                            setValidationError(false);
                        }}>Закрити</button>
                    </div>
                </Modal>
            }
            {loading && <Loading/>}
            {!userProfile? <Loading/>:
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                        'individual_has_tax_number': true,
                        ...userProfile,

                    }}
                    onSubmit={submitHandler}
                >
                    {props =>
                        <Form>
                            <fieldset disabled={!isEditing}>

                                {props.values.settlement_accounts.length === 0 && <AlertBlock/>}

                                <MainBlock/>
                                <LocationBlock props={props}/>
                                <BankDetailsBlock props={props}/>

                                <div className={[s.block, s.contractBlock].join(' ')}>
                                    <a target="_blank" href={
                                        props.values.contract_document?
                                            config.baseUrl + props.values.contract_document.file:
                                            null
                                    }>Договір приєднання до майданчика</a>
                                    <p>Підписано: {props.values.register_at}</p>
                                </div>

                                <Button type="submit" style={{width:'30%', float:'right', marginBottom:'40px'}} color="#057E36">
                                    Зберегти зміни
                                </Button>

                                <ErrorsHandler errors={errors}/>
                            </fieldset>
                        </Form>
                    }
                </Formik>}

        </div>
    )
}