import React, {useEffect, useState} from 'react';
import s from './Notifications.module.css';
import {useHttp} from '../../hooks/HttpHook';
import {Link} from 'react-router-dom';
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
import CabinetSideBar from "../../components/CabinetSideBar/CabinetSideBar";
function Notifications(props) {
    const {request} = useHttp();
    const [userNotifications, setUserNotifications] = useState();
    useEffect(async()=>{
        let data;
        try{
            data = await request(`${config.baseUrl}/api/v1/notifications/`,'GET');
            await request(`${config.baseUrl}/api/v1/notifications/read/`,'POST');
            if(data && data.results) setUserNotifications(data.results);
        }catch (e) {
            ErrorHandler(e)
        }
        //props.renderHeader();
        //TODO fix notifications
    },[])


    const renderNotifications = (notifications) => {
        if(notifications.length==0) return;
        let counter = 0;
        let currentDate = notifications[0].date;
        let newArr = [];
        do{
            if(notifications[counter].date==currentDate){
                if(newArr[currentDate]) newArr[currentDate].push(notifications[counter]);
                else newArr[currentDate]=[notifications[counter]];
            }else{
                currentDate = notifications[counter].date;
                newArr[currentDate]=[notifications[counter]];
                counter++;
                continue;
            }
            counter++;
        }while (counter<notifications.length);
     const elements = [];
        Object.keys(newArr).map((key)=>{
            const notificationsList = newArr[key],
            checkDate = new Date(newArr[key][0].timestamp*1000).setHours(0, 0, 0, 0),
            todayDate = new Date().setHours(0, 0, 0, 0),
            yesterdayDate = new Date().setHours(0, 0, 0, 0) - 86400000;
            const isToday = checkDate===todayDate,
            isYesterday = checkDate===yesterdayDate;
            elements.push(
                <div className={s.notifBox}>
                    <h3>{isToday?'Сьогодні':isYesterday?'Вчора':key}</h3>
                    {notificationsList.map((elem,i)=> {
                        return <div className={s.notifItem}>
                            <div className={s.notifLeft}>
                                <p className={s.itemTime}>{elem.time}</p>
                                <p>{elem.text}</p>
                            </div>
                            {elem.payload && elem.payload.redirect_url?<Link to={elem.payload.redirect_url}>Перейти</Link>:null}
                        </div>
                    })}
                </div>
                )
            });
        return elements;
        }

    return(
        <CabinetSideBar>
        <div className={s.Notifications}>
            {/*<h1 onClick={()=>{props.renderHeader();}}>Усі сповіщення</h1>*/}
            <div className={s.notificationsContent}>
                {userNotifications && userNotifications.length>0?renderNotifications(userNotifications):<div style={{'justifyContent':'center'}} className={s.notifItem}><p>Немає нових сповіщень</p></div>}
            </div>
        </div>
        </CabinetSideBar>
    )
}

export default Notifications;