import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import rootReducer from './redux/rootReducer'
import reportWebVitals from './reportWebVitals';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {createStore} from 'redux';
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from '@sentry/integrations';
import config from './config/config';
import ErrorHandler from "./utils/ErrorLoger";

const store = createStore(rootReducer);
const history = createBrowserHistory();

// Sentry.init({
//     dsn: "https://5acd8bec053e4c5481dc0b24551ada06@o1121914.ingest.sentry.io/6159012",
//     integrations: [new Integrations.BrowserTracing(), new CaptureConsole({
//         levels: ['error']
//     })],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     // tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
      {/*<Provider store={store}>*/}
        <Router history={history}>
          <App />

        </Router>
      {/*</Provider>*/}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
