import React, {useCallback, useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import s from './SignComponent.module.css';
import {EndUser, EndUserConstants, EndUserKeyMedia} from "euscp";
import Loading from "../Loading/Loading";
import config from '../../config/config'


function SignComponent({isVisible, onSign, onClose, transparentBackground, defaultFile, defaultFileUrl}) {
    const [keyFile, setKeyFile] = useState(null)
    const [signFile, setSignFile] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [euSign, setEuSign] = useState({})

    const euSettings = {
        language: "uk",
        encoding: "utf-8",
        httpProxyServiceURL: "",
        directAccess: true,
        CAs: `${config.baseUrl}/media/cas_file/_AdditionalFile%20object%20(None)/CAs.json`,
        CACertificates: `${config.baseUrl}/media/certificate_files/_AdditionalFile%20object%20(None)/CACertificates.p7b`,
        allowedKeyMediaTypes: [
            "е.ключ ІІТ Алмаз-1К",
            "е.ключ ІІТ Кристал-1",
            "ID-карта громадянина (БЕН)",
            "е.ключ ІІТ Алмаз-1К (PKCS#11)",
            "е.ключ ІІТ Кристал-1 (PKCS#11)"
        ]
    };

    useEffect(()=>{
        setEuSign(new EndUser(
            null,
            EndUserConstants.EndUserLibraryType.JS))
    },[])

    function readFile(file) {
        return new Promise(function(resolve, reject) {
            const reader = new FileReader();
            reader.onloadend  = function(evt) {
                if (evt.target.readyState != FileReader.DONE)
                    return;
                resolve({
                    "file": file,
                    "data": new Uint8Array(evt.target.result)
                });
            };
            reader.readAsArrayBuffer(file);
        });
    }
//
    function initialize() {
        console.log('initialize')
        return new Promise(function(resolve, reject) {
            euSign.IsInitialized()
                .then(function(result) {
                    if (result) {
                        console.log("EndUser: already initialized");
                        resolve();
                        return;
                    }
                    console.log("EndUser: initializing...");
                    return euSign.Initialize(euSettings)
                }).then(function() {
                console.log("EndUser: initialized");
                resolve()
            })
                .catch(function(e) {
                    reject(e);
                });
        });
    }

//===============================================================================

    function readPrivateKey() {
        var useFile = true;
        var pkFileInput = useFile ?
            document.getElementById('pkFile') : null;
        var passwordInput = document.getElementById(
            useFile ? 'pkFilePassword' : 'pkKeyMediaPassword');
        var selectedKM = null
        var caCN = null;
        var pkCertificates = null;

        return new Promise(function(resolve, reject) {
            if (useFile && (
                pkFileInput.value == null ||
                pkFileInput.value == '')) {
                pkFileInput.focus();
                reject('Не обрано файл з ос. ключем');
                return;
            }

            if (passwordInput.value == null ||
                passwordInput.value == '') {
                passwordInput.focus();
                reject('Не вказано пароль до ос. ключа');
                return;
            }


                console.log('pkFileInput',pkFileInput)
                readFile(pkFileInput.files[0])
                    .then(function (result) {
                        console.log("Private key file readed");
                        if (result.file.name.endsWith(".jks")) {
                            return euSign.GetJKSPrivateKeys(result.data)
                                .then(function (jksKeys) {
                                    console.log("EndUser: jks keys got");
                                    return euSign.ReadPrivateKeyBinary(
                                        jksKeys[0].privateKey,
                                        passwordInput.value, pkCertificates, caCN);
                                });
                        }
                        return euSign.ReadPrivateKeyBinary(
                            result.data, passwordInput.value, pkCertificates, caCN);
                    })
                    .then(function (result) {
                        resolve(result)
                    })
                    .catch(function (e) {
                        console.log('rejected',e)
                        reject(e);
                    });
        });
}
//

    function convertToBlob(uint, fileName, mimeType) {
        return new Blob([uint], {
            type: mimeType
        });
    }

    function downloadBlob(data, fileName, mimeType) {
        const url = window.URL.createObjectURL(convertToBlob(data));
        //downloadURL(url, fileName);
        setTimeout(function() {
            return window.URL.revokeObjectURL(url);
        }, 1000);
    };

    // function downloadURL (data, fileName) {
    //     var a;
    //     a = document.createElement('a');
    //     a.innerHTML = 'DOWNLOAD'
    //     a.href = data;
    //     a.download = fileName;
    //     document.body.appendChild(a);
    //     a.style = 'display: none';
    //     a.click();
    //     a.remove();
    // };

    async function signData() {
        setError(null)
        setLoading(true);
        await initialize()
            .catch(function(e) {
                var msg = (e.message || e);

                console.log("Initialize error: " + msg);
                setLoading(false)
                setError(msg);
            })


        console.log('key', keyFile)
        console.log('sign', signFile)
        if(!keyFile){
            setError('Оберіть особистий ключ')
            setLoading(false)
            return;
        }
        if(!signFile){
            setError('Оберіть файл для підпису')
            setLoading(false)
            return;
        }
        readFile(signFile)
            .then(function(fileResult){
                readPrivateKey()
                    .then(function(result) {
                        console.log("EndUser: private key readed " + result.subjCN + ".");
                        return euSign.SignDataInternal(true, fileResult.data, false);
                    })
                    .then(function(sign) {
                        downloadBlob(sign, 'signed-file.pdf.p7s', 'application/octet-stream');
                        console.log("EndUser: data signed");
                        console.log("Data: " + fileResult.data);
                        console.log("Sign: " + sign);
                        console.log(new File(sign, "test.pdf.p7s", {
                            type: 'application/pkcs7-signature'
                        }))
                        console.log({
                            signedFile: convertToBlob(sign, 'signed-file.pdf.p7s', 'application/octet-stream'),
                            sourceFile: signFile
                        })
                        onSign({
                            signedFile: convertToBlob(sign, 'signed-file.pdf.p7s', 'application/octet-stream'),
                            sourceFile: signFile
                        })
                        console.log('Дані успішно підписані');
                        setLoading(false);
                    })
                    .catch(function(e) {
                        var msg = (e.message || e);
                        console.log("Sign data error: " + msg);
                        setError('Виникла помилка при підписі даних. ' +
                            'Опис помилки: ' + msg);
                        setLoading(false);
                    });
            })
    }

    function FilePreview({file}){
        const [url, setUrl] = useState('')
        useEffect(()=>{
            setUrl(file?window.URL.createObjectURL(file):'')
            return ()=>{
                window.URL.revokeObjectURL(url)
            }
        },[file])

        console.log('PREVIEW',file)
        //const url = file?window.URL.createObjectURL(file):'';


        return <a download={file?.name} href={url} className={s.selectedFileName}>{file?.name}</a>

    }



    useEffect(()=>{
      if(defaultFile) setSignFile(defaultFile)
      if(defaultFileUrl) {
          fetch(defaultFileUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
          })
            .then((response) => response.blob())
            .then((blob) => {
                const nameParts = defaultFileUrl.split('/');
                const filename = nameParts[nameParts.length-1];
                setSignFile(new File([blob],filename,{type:blob.type}))
            })
      }
    },[])

    return(
        <Modal transparentBackground={transparentBackground} isVisible={isVisible} onClose={onClose}>
            {isLoading && <Loading/>}
            <div className={s.SignComponent}>
                <h1>Підпис даних</h1>
                <div className={s.keyTypeBlock}>
                    <p>Оберіть тип носія ос. ключа</p>
                    <input type="radio" id="pkTypeFile" name="pkType"/>
                    <label htmlFor="pkTypeFile">Файл</label>
                    <input type="radio" id="pkTypeKeyMedia" name="pkType"/>
                    <label htmlFor="pkTypeKeyMedia">Носій ос. ключа</label>
                </div>
                <div className={s.keySelectBlock} id="pkFileBlock">
                    <label>Особистий ключ:</label>
                    <input className={s.hiddenInput} onChange={(e)=>{setKeyFile(e.target.files[0])}}  id="pkFile" type="file"/>
                    <button type={"button"} className={s.fileSelectButton} onClick={(e)=>{e.target.parentNode.children[1].click()}}>Обрати файл</button>
                    <p className={s.selectedFileName}>{keyFile?.name}</p>
                    {keyFile &&
                    <>
                        <label>Пароль:</label>
                        <input id="pkFilePassword" type="password"/>
                    </>}

                </div>
                <div className={s.keyMediaBlock} id="pkKeyMediaBlock" >
                    <label>Носій ос. ключа:</label>
                    <select id="pkKeyMediaSelect"/>
                    <label>Пароль:</label>
                    <input id="pkKeyMediaPassword" type="password"/>
                </div>
                <div className={s.signBlock} id="signBlock">
                    <label>Дані для підпису:</label>
                    <input className={s.hiddenInput} onChange={(e)=>{setSignFile(e.target.files[0])}}   id='data-textarea' type='file'/>
                    {(!defaultFile && !defaultFileUrl) &&
                        <p className={s.fileSelectButton} onClick={(e)=>{e.target.parentNode.children[1].click()}}>Обрати файл</p>
                    }
                    {
                        signFile && <FilePreview file={signFile}/>}
                    {error && <p className={s.error}>{error}</p>}
                    <p onClick={signData} className={s.signButton} id='sign-button'>Накласти підпис</p>

                </div>
            </div>
        </Modal>
    )
}

export default SignComponent;