import s from "../NewLot.module.css";
import React, {useEffect, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import config from "../../../config/config";

export default function LotBank({auctionInfo, id}) {

    const localizeAccountPurpose = (purpose) => {
        switch (purpose) {
            case 'ALL_PAYMENTS': return 'Всі платежі'
            default: return purpose
        }
    }

    return (
        <div id={id} className={s.lotPayments}>
            <h1 className={s.title}>Банківські реквізити</h1>
            {auctionInfo.bank_accounts && auctionInfo.bank_accounts.map((account,i)=>{
                return(
                    <div key={i}>
                        <div className={s.line}/>
                        <div className={s.textItem}>
                            <p className={s.label}>Назва банку:</p>
                            <h5 className={s.value}>{account.bank_name}</h5>
                        </div>
                        <div className={s.lotDetails}>
                            <div className={s.gridItem}>
                                <p className={s.label}>Код ЄДРПОУ банку:</p>
                                <h5 className={s.value}>{account.bank_code}</h5>
                            </div>
                            <div className={s.gridItem}>
                                <p className={s.label}>МФО банку:</p>
                                <h5 className={s.value}>{account.bank_tax_number}</h5>
                            </div>
                        </div>
                        <div className={s.lotDetails}>
                            <div className={s.gridItem}>
                                <p className={s.label}>Валюта рахунка:</p>
                                <h5 className={s.value}>{account.currency}</h5>
                            </div>
                            <div className={s.gridItem}>
                                <p className={s.label}>ІВАN:</p>
                                <h5 className={s.value}>{account.iban_number}</h5>
                            </div>
                        </div>
                        <div className={s.textItem}>
                            <p className={s.label}>Призначення:</p>
                            <h5 className={s.value}>{localizeAccountPurpose(account.account_purpose)}</h5>
                        </div>
                        <div className={s.line}/>
                    </div>
                )
            })}
        </div>
    )
}