import React, {useEffect, useState, Fragment, useMemo, useCallback} from 'react';
import s from './Home.module.css';
import Card from '../../components/Card/Card'
import {Link, NavLink, Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import config from "../../config/config";
import {useHttp} from '../../hooks/HttpHook';
import OutsideClickHandler from "react-outside-click-handler";
import InputRange from "react-input-range";
import FixedMarket from "../FixedMarket/FixedMarket";
import Auction from "../Auction/Auction";
import Loading from "../../components/Loading/Loading";
import Slider from "./components/Slider";
import ErrorHandler from "../../utils/ErrorLoger";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import ExtendedFilters from "../../components/ExtendedFilters/ExtendedFilters";
import Saved from "../Saved/Saved";
import MainSearchFilter from "../../components/Filters/MainSearchFilter/MainSearchFilter";
import {AuctionFilterContext} from '../../context/AuctionFilterContext';
//import backgroundImage from '../../assets/img/topSearchBackground2.jpg';
const backgroundImage = require('../../assets/img/topSearchBackground2.jpg').default;
function Home(){

    const location = useLocation();
    const [auctionsCount, setAuctionsCount] = useState();
    const [filter, setFilter] = useState({});
    const [priceShown, setPriceShown] = useState(false);


    const {request, loading} = useHttp();



    const Tabs = () => {
        return(
            <div className={s.topBar}>
                <ul className={s.list}>
                    <li>
                        <NavLink activeClassName={s.active} exact to="/auction/">
                            <div className={s.listItem}>
                                <h3>Аукціони</h3>
                                <div className={s.count}>
                                    <p>{auctionsCount}</p>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={s.active} to="/saved/">
                            <div className={s.listItem}>
                                <h3>Збережені</h3>
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </div>
        )
    }


    // const extendedFiltersSelectHandler = (values) => {
    //     setPropsFilter({...propsFilter, ...values});
    //     history.push('/auction/')
    //     // const searchParams = new URLSearchParams({...propsFilter, ...values}).toString();
    //     // alert(searchParams)
    //     // history.push('/auction/' + searchParams)
    // }

    const PageContent = () => (
        <Switch>
            <Route path="/auction/">
                <Tabs/>
                <Auction/>
            </Route>
            <Route path="/saved/">
                <Tabs/>
                <Saved/>
            </Route>
            <Route exact path="/filters/">
                <ExtendedFilters/>
            </Route>
        </Switch>
    )


    useEffect(async ()=>{
        const params = new URLSearchParams(filter);
        if(params) {
            const auctions = await request(config.baseUrl + `/api/v1/auctions/count/?${params}`);
            if(auctions) setAuctionsCount(auctions.count);
        }
    },[filter])

    return(
            <div className={s.Auction}>
                {loading && <Loading/>}
                <AuctionFilterContext.Provider value={{filter, setFilter}}>
                    <MainSearchFilter/>
                    <PageContent/>
                </AuctionFilterContext.Provider>

            </div>
    )
}

export default Home;