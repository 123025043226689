import config from "../../../config/config";
import React, {Fragment, useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import Loading from "../../Loading/Loading";
import s from "../Participation.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import FileSign from "../../FileSign/FileSign";
import {Field, useFormikContext} from "formik";
import {
    CheckboxField,
    DateField,
    FileField,
    NumericField, PhoneNumberField,
    SelectField,
    TextAreaField,
    TextField
} from "../../FormikFields/FormikFields";
import {InputItem, InputItemShort, ItemRow, Line, OutlinedButton} from "../../UI/UI";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";
import SignComponent from "../../SignComponent/SignComponent";
import ErrorHandler from "../../../utils/ErrorLoger";
import {AuthContext} from "../../../context/AuthContext";
import SignFileModal from "../../SignFileModal/SignFileModal";
import InputField from "../../FormikFields/InputField";
import dictionary from "../../../config/dictionary";

export default function IndividualDocuments(){
    const {values, setFieldValue} = useFormikContext();
    const [identitySignStatus, setIdentitySignStatus] = useState('unsigned')
    const {nextStage} = useContext(ParticipationFormContext)

    return(
        <>
            <SignFileModal
                isVisible={identitySignStatus==='signing'}
                onSign={(fileId)=>{
                    setIdentitySignStatus('signed');
                    setFieldValue('identity_document', fileId)
                }}
                onClose={setIdentitySignStatus.bind(this, 'unsigned')}
                defaultFileUrl={values.identity_document.file}
                transparentBackground={false}
                documentType={"IDENTITY_DOCUMENT"}
            />

            <fieldset disabled={true}>

                <ItemRow>
                    <InputItem label="Документ що посвідчує особу">
                        <InputField
                            name="identity_document_type"
                            data={dictionary.DOCUMENT_TYPES}
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Дата видачі">
                        <InputField name="identity_document_issue_date" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        <InputItem label="Серія">
                            <InputField name="identity_document_serial_number" component={TextField}/>
                        </InputItem>
                        <InputItem label="Номер">
                            <InputField name="identity_document_number" component={NumericField}/>
                        </InputItem>
                    </ItemRow>
                    <InputItem label={"Документ, що посвідчує особу"}>
                        <OutlinedButton
                            onClick={setIdentitySignStatus.bind(this, 'signing')}
                            disabled={identitySignStatus==='signed'}
                            color="#057E36"
                        >
                            {identitySignStatus==='signed'?'Підписано':'Підписати'}
                        </OutlinedButton>
                    </InputItem>
                </ItemRow>

                <Line/>



            </fieldset>

            <InputItem label="Email">
                <InputField name="email" component={TextField}/>
            </InputItem>

            <InputItem label="Номер телефону">
                <InputField name="phone_number" component={PhoneNumberField}/>
            </InputItem>

            <button
                disabled={!(
                    identitySignStatus === 'signed'
                )}
                className={s.nextStageButton}
                onClick={nextStage}
            >Далі</button>
        </>
    )
}