import React, {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import s from "../RegisterRedesign.module.css";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import InputMask from "react-input-mask";
import {InputItemShort, Subtitle, Title} from "../../../components/UI/UI";
import LegalSecondStage, {legalSchema} from "./legalEntity/LegalSecondStage";
import IndividualSecondStage, {individualSchema} from "./individual/IndividualSecondStage";
import EntrepreneurSecondStage, {entrepreneurSchema} from "./individualEntrepreneur/EntrepreneurSecondStage";
import {SelectField} from "../../../components/FormikFields/FormikFields";

const SecondStage = ({setMainForm, mainForm, setCurrentStage, currentStage}) => {
        const [validationSchema, setValidationSchema] = useState(null)

        const chooseValidationSchema = (profileType) => {
            switch(profileType){
                case 'LEGAL_ENTITY': setValidationSchema(legalSchema); break;
                case 'INDIVIDUAL': setValidationSchema(individualSchema); break;
                case 'INDIVIDUAL_ENTREPRENEUR': setValidationSchema(entrepreneurSchema); break;
                default: setValidationSchema({});
            }
        }

        useEffect(()=>{
            setCurrentStage(2)
        },[])

        return (
            <Formik
                validationSchema={validationSchema}
                initialValues={{}}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values)=>{
                    setMainForm({...mainForm,...values});
                    setCurrentStage(currentStage+1);
                }}
            >
                {props =>
                    <Form>
                        {console.log(props.errors)}
                        <InputItemShort label="Оберіть організаційно - правову форму">
                            <Field
                                name="profile_type"
                                data={[
                                    { name: "Фізична особа", id: 'INDIVIDUAL' },
                                    { name: "Юридична особа", id: 'LEGAL_ENTITY' },
                                    { name: "Фізична особа - підприємець", id: 'INDIVIDUAL_ENTREPRENEUR' },
                                ]}
                                placeholder="Організаційно-правова форма"
                                component={SelectField}
                                selectHandler={(type)=>chooseValidationSchema(type)}
                            />
                        </InputItemShort>
                        {props.values.profile_type === 'INDIVIDUAL' &&
                            <IndividualSecondStage/>
                        }

                        {props.values.profile_type === 'INDIVIDUAL_ENTREPRENEUR' &&
                            <EntrepreneurSecondStage/>
                        }

                        {props.values.profile_type === 'LEGAL_ENTITY' &&
                            <LegalSecondStage/>
                        }

                        <button
                            className={s.registerButton}
                            type="submit"
                            disabled={!props.values.profile_type}
                        >Продовжити реєстрацію</button>
                    </Form>
                }
            </Formik>
        )
    }

    export default SecondStage;