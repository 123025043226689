import React from 'react';
import s from './Tarifs.module.css';
function Tarifs(){
    return(
        <div className={s.Tarifs}>
            <div className={s.content}>
                <h1 className={s.title}>Тарифи</h1>
                <h3>На проведення земельних торгів для продажу земельних ділянок<br/>
                    та продажу права користування (оренди, емфітевзису, супрерфіцію) земельними ділянками</h3>
                <div className={s.container}>
                    <div className={s.div1}><p>Винагорода майданчика, що сплачується переможцем земельних торгів за</p></div>
                    <div className={s.div2}><p>Реєстраційний внесок, грн, без ПДВ</p></div>
                    <div className={s.div3}><p>продаж земельної ділянки та продаж права емфітевзису, супрерфіцію</p></div>
                    <div className={s.div4}><p>продаж права оренди</p></div>
                    <div className={s.div5}><p>Реєстраційний внесок за лотом встановлюється у розмірі 0,1 мінімальної заробітної плати, визначеної законом про Державний бюджет України на 1 січня року, в якому оприлюднюється оголошення про проведення земельних торгів *</p></div>
                    <div className={s.div6}><p>5 відсотків ціни продажу земельної ділянки, з урахуванням податку на додану вартість, але не більше суми гарантійного внеску</p></div>
                    <div className={s.div7}><p>5 відсотків ціни річної орендної плати, з урахуванням податку на додану вартість, але не більше суми гарантійного внеску</p></div>
                    <div className={s.div8}>
                        <p className={s.annotation}>* Для участі в аукціоні також необхідно сплатити реєстраційний внесок. У 2022 році розмір реєстраційного внеску складає 650,00 грн</p>
                        <div className={s.infoBlock}>
                            <p>Зазначені тарифи встановлено на підставі  частини 7 статті 135 Земельного кодексу України та пункту 82 Вимог щодо підготовки до проведення та проведення земельних торгів для продажу земельних ділянок та набуття прав користування ними (оренди, суперфіцію, емфітевзису), затверджених постановою Кабінету Міністрів України від 22 вересня 2021 року № 1013.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tarifs