import React, {useContext, useRef} from "react";
import {Field, FieldArray, getIn, useFormikContext} from "formik";
import {
    CheckboxField, DateField,
    FileField, NumberMaskField,
    NumericField,
    SelectField, TextAreaField,
    TextField
} from "../../FormikFields/FormikFields";
import {InputItem, InputItemShort, ItemRow, Line, Subtitle, Title} from "../../UI/UI";
import InputField from "../../FormikFields/InputField";
import s from "../Participation.module.css";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";
import FileSign from "../../FileSign/FileSign";

export default function BeneficialOwners(){
    const props = useFormikContext();
    const {nextStage} = useContext(ParticipationFormContext);
    return(
        <>
            <Title>Кінцевий бенефіціарний власник:</Title>
            {props.values.beneficial_owner_exist?
                props.values.beneficial_owners.map((friend, index) =>
                    <div key={index}>

                            <InputItemShort label="Прізвище:">
                                <Field disabled={true} name={`beneficial_owners.${index}.beneficial_owner_second_name`} component={TextField}/>
                            </InputItemShort>
                            <InputItemShort label="Ім’я:">
                                <Field disabled={true} name={`beneficial_owners.${index}.beneficial_owner_first_name`} component={TextField}/>
                            </InputItemShort>

                            <InputItemShort label="По батькові:">
                                <Field disabled={true} name={`beneficial_owners.${index}.beneficial_owner_last_name`} component={TextField}/>
                            </InputItemShort>
                            <Line/>
                    </div>):
                <InputItem label={"Причини відсутності кінцевого бенефіціарного власника"}>
                    <Field name={`beneficial_owner_absence_reason`} component={TextAreaField}/>
                </InputItem>
            }

            <button
                className={s.nextStageButton}
                onClick={nextStage}
                disabled={props.values.beneficial_owner_exist?false:!props.values.beneficial_owner_absence_reason}
            >Далі</button>
        </>
    )
}


/*

<>
            <Title>Кінцевий бенефеціарний власник</Title>
            {props.values.beneficial_owner_exist?
            <fieldset disabled={true}>
                <FieldArray name="beneficial_owners">
                    {({pop, push}) => (
                        <>
                            <InputItemShort>
                                <InputItem label="Кінцевий бенефеціарний власник:">
                                    <InputField
                                        name="beneficial_owner_exist"
                                        data={[
                                            { name: "Наявний", id: true },
                                            { name: "Відсутній", id: false },
                                        ]}
                                        component={SelectField}
                                        disabled={true}
                                        checkDefaultById
                                    />
                                </InputItem>
                            </InputItemShort>

                            {props.values.beneficial_owner_exist &&
                                <ItemRow>
                                    <InputItem label="Кількість">

<InputField
    name="beneficial_owners_count"
    data={[
        { name: "1", id: 1 },
        { name: "2", id: 2 },
        { name: "3", id: 3 },
        { name: "4", id: 4 },
        { name: "5", id: 5 },
        { name: "6", id: 6 },
        { name: "7", id: 7 },
        { name: "8", id: 8 },
        { name: "9", id: 9 },
        { name: "10", id: 10 },
    ]}
    component={SelectField}
    disabled={true}
    onSelect={(value)=>{
        const ownersLength = props.values.beneficial_owners.length;
        const selectedLength = value[0].id;
        if(selectedLength > ownersLength){
            for(let i = ownersLength; i<selectedLength;i++){
                push({
                    'beneficial_owner_first_name': null,
                    'beneficial_owner_second_name': null,
                    'beneficial_owner_last_name': null,

                    'beneficial_owner_citizenship': null,

                    'beneficial_owner_has_tax_number': true,
                    'beneficial_owner_tax_number': true,
                    'beneficial_owner_tax_number_document_id': null,

                    'beneficial_owner_identity_document_type': null,
                    'beneficial_owner_identity_document_id': null,

                    'beneficial_owner_identity_document_serial_number': null,
                    'beneficial_owner_identity_document_number': null,
                    'beneficial_owner_identity_document_issue_date': null,
                    'beneficial_owner_unified_state_demographic_register': null,

                    'beneficial_owner_address': {
                        'country': null,
                        'postal_code': null,
                        'region_id': null,
                        'district_id': null,
                        'settlement_id': null,
                        'street': null,
                        'building_number': null,
                        'building_block_number': null,
                        'apartment_or_office_number': null,
                    },

                    'direct_decisive_influence': false,
                    'direct_decisive_influence_percentage': null,
                    'indirect_decisive_influence': false,
                    'indirect_decisive_influence_percentage': null,

                    'other_nature_and_extent_of_influence': false,
                    'other_nature_and_extent_of_influence_percentage': null,
                })
            }
        }else if(selectedLength < ownersLength){
            for(let i = selectedLength; i<ownersLength;i++) pop();
        }
        props.setFieldValue('beneficial_owners_count', selectedLength)
    }}
    checkDefaultById
/>
</InputItem>
</ItemRow>
}

{props.values.beneficial_owner_exist &&
<div>
    {!!props.values.beneficial_owners_count && props.values.beneficial_owners
        .map((friend, index) =>
            <div key={index}>
                <Line/>
                <ItemRow>
                    <InputItem label="Прізвище:">
                        <InputField name={`beneficial_owners.${index}.beneficial_owner_second_name`} component={TextField}/>
                    </InputItem>
                    <InputItem label="Ім’я:">
                        <InputField name={`beneficial_owners.${index}.beneficial_owner_first_name`} component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="По батькові:">
                        <InputField name={`beneficial_owners.${index}.beneficial_owner_last_name`} component={TextField}/>
                    </InputItem>
                    <InputItem label="Громадянство:">
                        <InputField
                            name={`beneficial_owners.${index}.beneficial_owner_citizenship`}
                            data={[
                                { name: "Україна", id: 'Ukraine' },
                            ]}
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Реєстраційний номер облікової картки платника податків (РНОКПП):">
                        <InputField name={`beneficial_owners.${index}.beneficial_owner_tax_number`} component={NumericField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputField
                        style={{marginTop:'50px'}}
                        name={`beneficial_owners.${index}.beneficial_owner_has_tax_number`}
                        component={CheckboxField}
                        modifier={(e)=>!e}
                    >
                        Особа відмовилася від РНОКПП через свої релігійні переконання та має про це відмітку у паспорті
                    </InputField>
                    {props.values.beneficial_owners[index].beneficial_owner_has_tax_number &&
                        <InputField
                            component={FileField}
                            disabled={true}
                            name={`beneficial_owners.${index}.beneficial_owner_tax_number_document_id`}
                            description="Максимальний розмір файлу 50 Мб "
                            title="Завантажити документ"
                            url={`/api/v1/user/1/upload-legal-beneficial-owner-taxt-document/`}
                            uploadMethod="formData"
                        />
                    }
                </ItemRow>

                <Line/>

                <ItemRow>
                    <InputItem label="Документ що посвідчує особу:">
                        <InputField
                            name={`beneficial_owners.${index}.beneficial_owner_identity_document_type`}
                            data={[
                                { name: "паспорт громадянина України (ГУ)", id: "PASSPORT" },
                                { name: "паспорт ГУ у формі картки", id: "PASSPORT_AS_CARD" },
                                {
                                    name: "посвідка на тимчасове проживання",
                                    id: "TEMPORARY_RESIDENCE_PERMIT",
                                },
                                {
                                    name: "посвідка на постійне проживання",
                                    id: "PERMANENT_RESIDENCE_PERMIT",
                                },
                                { name: "інший документ", id: "ANOTHER_DOCUMENT" },
                            ]}
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Дата видачі:">
                        <InputField name={`beneficial_owners.${index}.beneficial_owner_identity_document_issue_date`} component={DateField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        <InputItem label="Серія:">
                            <InputField name={`beneficial_owners.${index}.beneficial_owner_identity_document_serial_number`} component={TextField}/>
                        </InputItem>
                        <InputItem label="Номер:">
                            <InputField name={`beneficial_owners.${index}.beneficial_owner_identity_document_number`} component={NumericField}/>
                        </InputItem>
                    </ItemRow>
                    <InputField
                        component={FileField}
                        disabled={true}
                        name={`beneficial_owners.${index}.beneficial_owner_identity_document_id`}
                        description="Максимальний розмір файлу 50 Мб "
                        title="Завантажити документ"
                        url={`/api/v1/user/1/upload-legal-beneficial-owner-idenity-document/`}
                        uploadMethod="formData"
                    />
                </ItemRow>

                <ItemRow>
                    <InputItem label="Унікальний № запису в ЄДДР (за наявності):">
                        <InputField name={`beneficial_owners.${index}.beneficial_owner_unified_state_demographic_register`} component={TextField}/>
                    </InputItem>
                </ItemRow>

                <Line/>

                <Subtitle>Місце проживання</Subtitle>

                <ItemRow>
                    <InputItem label="Країна:">
                        <InputField
                            name={`beneficial_owners.${index}.beneficial_owner_address.country`}
                            url="/api/v1/location/countries/"
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                            customId="code"
                        />
                    </InputItem>
                    <InputItem label="Поштовий індекс:">
                        <InputField
                            name={`beneficial_owners.${index}.beneficial_owner_address.postal_code`}
                            maskLength={5}
                            component={NumberMaskField}
                        />

                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Область:">
                        <InputField
                            name={`beneficial_owners.${index}.beneficial_owner_address.region_id`}
                            url="/api/v1/location/regions/"
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Район:">
                        <InputField
                            name={`beneficial_owners.${index}.beneficial_owner_address.district_id`}
                            url="/api/v1/location/districts/"
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <InputItem label="Населений пункт:">
                        <InputField
                            name={`beneficial_owners.${index}.beneficial_owner_address.settlement_id`}
                            url="/api/v1/location/settlements/"
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Вулиця:">
                        <InputField name={`beneficial_owners.${index}.beneficial_owner_address.street`} component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        <InputItem label="№ будинку:">
                            <InputField name={`beneficial_owners.${index}.beneficial_owner_address.building_number`} component={TextField}/>
                        </InputItem>
                        <InputItem label="Корпус:">
                            <InputField name={`beneficial_owners.${index}.beneficial_owner_address.building_block_number`} component={TextField}/>
                        </InputItem>
                    </ItemRow>
                    <InputItem label="№ квартири/офісу:">
                        <InputField name={`beneficial_owners.${index}.beneficial_owner_address.apartment_or_office_number`} component={TextField}/>
                    </InputItem>
                </ItemRow>

                <Line/>

                <Subtitle>
                    Характер та міра (рівень ступінь, частка) бенефеціарного володіння (вигоди, інтересу, впливу):
                </Subtitle>

                <Field name={`beneficial_owners.${index}.direct_decisive_influence`} component={CheckboxField}>
                    Прямий вирішальний вплив (контроль):<br/>
                    відсоток частки статусного (складеного) капіталу (пайового фонду) чи відсоток права голосу:
                </Field>
                {getIn(props.values, `beneficial_owners.${index}.direct_decisive_influence`) &&
                    <InputItemShort style={{marginLeft: '40px'}}>
                        <InputField name={`beneficial_owners.${index}.direct_decisive_influence_percentage`}/>
                    </InputItemShort>
                }

                <Field name={`beneficial_owners.${index}.indirect_decisive_influence`} component={CheckboxField}>
                    Непрямий вирішальний вплив (контроль):<br/>
                    відсоток частки статусного (складеного) капіталу (пайового фонду) чи відсоток права голосу:
                </Field>
                {getIn(props.values, `beneficial_owners.${index}.indirect_decisive_influence`) &&
                    <InputItemShort style={{marginLeft: '40px'}}>
                        <InputField name={`beneficial_owners.${index}.indirect_decisive_influence_percentage`}/>
                    </InputItemShort>
                }

                <Field name={`beneficial_owners.${index}.other_nature_and_extent_of_influence`} component={CheckboxField}>
                    Інший характер та міра впливу:
                </Field>
                {getIn(props.values, `beneficial_owners.${index}.other_nature_and_extent_of_influence`) &&
                    <InputItemShort style={{marginLeft: '40px'}}>
                        <InputField name={`beneficial_owners.${index}.other_nature_and_extent_of_influence_percentage`}/>
                    </InputItemShort>
                }
            </div>
        )}

</div>
}
</>
)}
</FieldArray>
</fieldset>:
<InputItem label={"Причини відсутності кінцевого бенефіціарного власника"}>
    <Field name="beneficial_owner_absence_reason" component={TextAreaField}/>
</InputItem>}
</>
 */