import React, {useState, useEffect, useRef} from 'react';
import {useHttp} from '../../hooks/HttpHook';
import config from '../../config/config.js';
import ErrorHandler from "../../utils/ErrorLoger";
import s from './NestedSelect.module.css'
import OutsideClickHandler from "react-outside-click-handler";
import arrowImg from '../../assets/img/arrow.png';

function NestedSelect({url, onSelect, ...props}){
    const [dropdownShown, setDropdownShown] = useState(false);
    const inputRef = useRef()
    const [data, setData] = useState([])
    const {request} = useHttp();

    function findNestedObj(entireObj, keyToFind, valToFind) {
        let foundObj;
        JSON.stringify(entireObj, (_, nestedValue) => {
            if (nestedValue && nestedValue[keyToFind] === valToFind) {
                foundObj = nestedValue;
            }
            return nestedValue;
        });
        return foundObj;
    };

    const getOptions = async (url) => {
        let obj = {};
        if(props.data) {
            setData(props.data)
            obj = props.data;
        }
        else {
            try {
                const response = await request(config.baseUrl + url, 'GET');
                if(Array.isArray(response)) {
                    setData(response)
                    obj=response;
                }
                else {
                    setData([response])
                    obj=[response]
                }
            } catch (e) {
                ErrorHandler(e)
            }
        }

        if(props.defaultValue){
            console.log(obj, props.selectLabel, props.defaultValue)
            const defaultSelectedValue = findNestedObj(obj, props.selectLabel, props.defaultValue);
            console.log(defaultSelectedValue)
            if(defaultSelectedValue && inputRef.current){
                inputRef.current.value = defaultSelectedValue[props.nameLabel]
                onSelect(defaultSelectedValue);
            }
        }
    }
    const renderNestedItems = (data, nestingLevel) => data.map((el, i)=><CategoryItem key={i} nestingLevel={nestingLevel} item={el}/>)
    const CategoryItem = ({item, nestingLevel}) => {
        function clickHandler(e) {
            e.preventDefault();
            onSelect(item)
            inputRef.current.value = item[props.nameLabel];
            setDropdownShown(false);
        }
        const nestedElementName = Object.keys(item)
            .filter((key)=> props.nestingKeys
            .includes(key))[0]
        const a=0;
        return <div>
            <div style={{paddingLeft:nestingLevel*20+'px'}} className={s.categoryItem} onClick={clickHandler}>
                <img src={arrowImg}/>
                <p>{item[props.codeLabel]} : {item[props.nameLabel]}</p>
            </div>
            {nestedElementName && renderNestedItems(item[nestedElementName], 1+nestingLevel)}
        </div>
    }

    useEffect( async ()=> {
        getOptions(url)
    },[])

    useEffect(()=>{
        if(Array.isArray(props.data)){
            if(props.data.length>0){
                setData(props.data)
            } else{
                setData([]);
                inputRef.current.value = '';
                onSelect(undefined);
            }
        }
    },[props.data])
    return(
            <OutsideClickHandler onOutsideClick={setDropdownShown.bind(this,false)}>
                <div style={props.style} className={[props.className,s.NestedSelect].join(' ')}>
                    <input disabled={props.disabled} placeholder={props.placeholder} onClick={setDropdownShown.bind(this, true)} ref={inputRef}/>
                    {dropdownShown &&
                        <div style={{width: props.dropdownWidth}} className={[s.dropdown,'nested-select-dropdown'].join(' ')}>
                            {renderNestedItems(data, 1)}
                        </div>}
                </div>
            </OutsideClickHandler>
    )
}

export default NestedSelect;