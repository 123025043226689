import ErrorHandler from "../utils/ErrorLoger";

export default function localizate(error){
    let localizedError = error.code;
    let localizedField = error.field?error.field:'';
            switch(error.code){
                case 'auction_not_accepting_members':{
                    localizedError = 'Аукціон не приймає учасників';
                    break;
                }
                case 'authentication_failed':{
                    localizedError = 'Невірний пароль';
                    break;
                }
                case 'not_authenticated':{
                    localizedError = 'Не авторизований';
                    break;
                }
                case 'method_not_allowed':{
                    localizedError = 'Метод не дозволений';
                    break;
                }
                case 'not_found':{
                    localizedError = 'Не знайдено';
                    break;
                }
                case 'error_mark_auction_as_payed_wrong_status':{
                    localizedError = 'Не вдалось позначити аукціон як оплачений (невірний статус)';
                    break;
                }
                case 'error_mark_as_checked_signature':{
                    localizedError = 'Не вдалось позначити як перевірений (підпис)';
                    break;
                }
                case 'organizer_cant_publish_error':{
                    localizedError = 'Організатор не може опублікувати';
                    break;
                }
                case 'user_with_google_id_not_exist':{
                    localizedError = 'Користувача не існує';
                    break;
                }
                case 'invalid': {
                    localizedError = 'Невірно введено'
                    break;
                }
                case 'auction_entrant_application_edit_not_allowed': {
                    localizedError = 'Редагування недоступне на данному етапі'
                    break;
                }
                case 'invalid_choice': {
                    localizedError = 'Невірно обрано'
                    break;
                }
                case 'required': {
                    localizedError = 'Введіть'
                    break;
                }
                case 'initial_bid_too_small': {
                    localizedError = 'Мінімальна ставка більша за'
                    break;
                }
                case 'empty': {
                    localizedError = 'Оголошення повинно містити хоча б 1'
                    break;
                }

                case 'blank': {
                    localizedError = 'Введіть';
                    break;
                }

                case 'password_too_short': {
                    localizedError = 'Пароль надто короткий';
                    break;
                }
                
                case 'password_too_similar': {
                    localizedError = 'Пароль надто подібний до інших данних';
                    break;
                }

                case 'password_entirely_numeric': {
                    localizedError = 'Пароль повинен містити букви та цифри';
                    break;
                }

                case 'password_too_common': {
                    localizedError = 'Надто простий';
                    break;
                }

                case 'user_already_exists': {
                    localizedError = 'Користувач вже існує';
                    break;
                }

                case 'password_mismatch': {
                    localizedError = 'Паролі не співпадають';
                    break;
                }

                case 'wrong_old_password': {
                    localizedError = 'Неправильний старий пароль';
                    break;
                }

                case 'user_not_exist': {
                    localizedError = 'Користувача не існує';
                    break;
                }

                case 'resend_sms_request_too_often': {
                    localizedError = 'Зачекайте 60 секунд перед надсиланням наступного коду';
                    break;
                }

                case 'invalid_sms_code': {
                    localizedError = 'Неправильний код';
                    break;
                }

                case 'null': {
                    localizedError = 'Завантажте';
                    break;
                }
                case 'phone_number_already_exist': {
                    localizedError = 'Користувач з таким номером вже існує';
                    break;
                }
                case 'expired_sms_code': {
                    localizedField = 'СМС-код застарілий';
                    break;
                }
                case 'permission_denied': {
                    localizedField = 'Немає доступу';
                    break;
                }
                case 'date_start_not_in_valid_range': {
                    localizedField = 'Дата початку не в допустимому діапазоні';
                    break;
                }

                default:{
                    localizedError = error.code;
                    ErrorHandler(`Need localization for code: ${error.code}`)
                    break;
                }
            }

            if(error.field) switch (error.field) {

                case 'new_password2': {
                    localizedField = 'повтор нового паролю';
                    break;
                }
                case 'lots_count': {
                    localizedField = 'кількість лотів';
                    break;
                }
                case 'number': {
                    localizedField = 'номер';
                    break;
                }
                case 'organizer_tax_number': {
                    localizedField = 'код ЄДРПОУ або ІПН організатора';
                    break;
                }
                case 'starting_price': {
                    localizedField = 'стартову ціну';
                    break;
                }
                case 'account_purpose': {
                    localizedField = 'призначення рахунка';
                    break;
                }
                case 'auction_type': {
                    localizedField = 'тип аукціону';
                    break;
                }
                case 'theme': {
                    localizedField = 'тему оголошення';
                    break;
                }
                case 'procedure_type_id': {
                    localizedField = 'тип процедури';
                    break;
                }
                case 'engineering_networks': {
                    localizedField = 'інженерні мережі';
                    break;
                }
                case 'restrictions': {
                    localizedField = 'обтяження прав на земельну ділянку';
                    break;
                }
                case 'location': {
                    localizedField = 'місцезнаходження';
                    break;
                }
                case 'organizer_name': {
                    localizedField = 'ім`я організатора';
                    break;
                }
                case 'contact_person_name': {
                    localizedField = 'ім`я контактної особи';
                    break;
                }
                case 'co_owners': {
                    localizedField = 'співвласників';
                    break;
                }
                case 'ownership_type': {
                    localizedField = 'форму власності';
                    break;
                }
                case 'bank_tax_number': {
                    localizedField = 'код ЄДРПОУ банку';
                    break;
                }
                case 'category_id': {
                    localizedField = 'категорію';
                    break;
                }
                case 'bank_name': {
                    localizedField = 'назву банку';
                    break;
                }
                case 'iban_number': {
                    localizedField = 'IBAN номер';
                    break;
                }
                case 'currency': {
                    localizedField = 'валюту';
                    break;
                }
                case 'preparation_expenses': {
                    localizedField = 'суму витрат на підготовку лота';
                    break;
                }
                case 'registration_date_of_intention_to_sell_in_state_register': {
                    localizedField = 'дату реєстрації наміру щодо продажу';
                    break;
                }
                case 'bank_code': {
                    localizedField = 'код банку';
                    break;
                }
                case 'organizer_address': {
                    localizedField = 'адресу організатора';
                    break;
                }
                case 'city_building_conditions': {
                    localizedField = 'містобудівні умови земельної ділянки';
                    break;
                }
                case 'expert_estimated_cost': {
                    localizedField = 'експертну грошову оцінку';
                    break;
                }
                case 'code': {
                    localizedField = 'код';
                    break;
                }
                case 'email': {
                    localizedField = 'електронну адресу';
                    break;
                }
                case 'phone_number':{
                    localizedField = 'номер телефону';
                    break;
                }
                case 'password':{
                    localizedField = 'пароль';
                    break;
                }
                case 'cadastral_number':{
                    localizedField = 'кадастровий номер';
                    break;
                }

                case 'price':{
                    localizedField = 'ціну';
                    break;
                }

                case 'purpose_id':{
                    localizedField = 'призначення землі';
                    break;
                }
                case 'area':{
                    localizedField = 'площу';
                    break;
                }
                case 'description':{
                    localizedField = 'опис';
                    break;
                }
                case 'region_id':{
                    localizedField = 'область';
                    break;
                }
                case 'district_id':{
                    localizedField = 'район';
                    break;
                }
                case 'settlement_id':{
                    localizedField = 'населенний пункт';
                    break;
                }
                case 'min_price':{
                    localizedField = 'мінімальну ціну';
                    break;
                }
                case 'street_id':{
                    localizedField = 'вулицю';
                    break;
                }
                case 'new_password1':{
                    localizedField = 'новий пароль';
                    break;
                }
                case 'old_password':{
                    localizedField = 'пароль';
                    break;
                }
                case 'type_id':{
                    localizedField = 'тип відчуження земельної ділянки';
                    break;
                }
                case 'document_ids':{
                    localizedField = 'документ';
                    break;
                }
                case 'photo_ids':{
                    localizedField = 'фото';
                    break;
                }
                case 'legal_entity_name':{
                    localizedField = 'ім`я юридичної особи';
                    break;
                }
                case 'legal_entity_tax_number':{
                    localizedField = 'ЄДРПОУ';
                    break;
                }
                case 'postal_address_country':{
                    localizedField = 'країну листування';
                    break;
                }
                case 'postal_address_postal_code':{
                    localizedField = 'поштовий індекс листування';
                    break;
                }
                case 'postal_address_region':{
                    localizedField = 'регіон листування';
                    break;
                }
                case 'postal_address_settlement':{
                    localizedField = 'населений пункт листування';
                    break;
                }
                case 'postal_address_street':{
                    localizedField = 'вулиця листування';
                    break;
                }
                case 'postal_address_building_number':{
                    localizedField = 'номер будинку листування';
                    break;
                }
                case 'postal_address_building_block_number':{
                    localizedField = 'номер корпусу листування';
                    break;
                }
                case 'postal_address_apartment_or_office_number':{
                    localizedField = 'номер квартири/офісу листування';
                    break;
                }
                case 'beneficial_owner':{
                    localizedField = 'бенефіціарного власника';
                    break;
                }
                case 'beneficial_owner_absence_reason':{
                    localizedField = 'причину відсутності бенефіціарного власника';
                    break;
                }
                case 'legal_entity_documents':{
                    localizedField = 'документи юридичної особи';
                    break;
                }
                case 'legal_entity_chief_first_name':{
                    localizedField = 'ім`я керівника';
                    break;
                }
                case 'legal_entity_chief_second_name':{
                    localizedField = 'по-батькові керівника';
                    break;
                }
                case 'legal_entity_chief_last_name':{
                    localizedField = 'прізвище керівника';
                    break;
                }
                case 'legal_entity_chief_document_name':{
                    localizedField = 'назву документа керівника';
                    break;
                }
                case 'legal_entity_chief_document_date':{
                    localizedField = 'дату документа керівника керівника';
                    break;
                }
                case 'legal_entity_chief_document_number':{
                    localizedField = 'номер документа керівника';
                    break;
                }
                case 'legal_entity_chief_tax_number':{
                    localizedField = 'РНОКПП керівника';
                    break;
                }
                case 'legal_entity_chief_identity_document_type':{
                    localizedField = 'тип документа що посвідчує особу керівника';
                    break;
                }
                case 'legal_entity_chief_tax_number_document':{
                    localizedField = 'РНОКПП керівника';
                    break;
                }
                case 'legal_entity_chief_identity_document':{
                    localizedField = 'документ що посвідчує особу керівника';
                    break;
                }
                case 'legal_entity_chief_identity_document_serial_number':{
                    localizedField = 'серія документа керівника';
                    break;
                }
                case 'legal_entity_chief_identity_document_number':{
                    localizedField = 'номер документа керівника';
                    break;
                }
                case 'legal_entity_chief_identity_document_issue_date':{
                    localizedField = 'дату документа керівника';
                    break;
                }
                case 'legal_entity_authorized_person_type':{
                    localizedField = 'тип уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_first_name':{
                    localizedField = 'ім`я уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_second_name':{
                    localizedField = 'по-батькові уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_last_name':{
                    localizedField = 'прізвище уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_authorization_document_name':{
                    localizedField = 'назву документу що підтверджує повноваження';
                    break;
                }
                case 'legal_entity_authorized_person_authorization_document_number':{
                    localizedField = 'номер документу що підтверджує повноваження';
                    break;
                }
                case 'legal_entity_authorized_person_authorization_document_issue_date':{
                    localizedField = 'дату видачі документу що підтверджує повноваження';
                    break;
                }
                case 'legal_entity_authorized_person_authorization_document_issuer':{
                    localizedField = 'видавника документу що підтверджує повноваження';
                    break;
                }
                case 'legal_entity_authorized_person_authorization_document':{
                    localizedField = 'документ що підтверджує повноваження';
                    break;
                }
                case 'legal_entity_authorized_person_tax_number':{
                    localizedField = 'РНОКПП уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_tax_number_document':{
                    localizedField = 'РНОКПП уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_identity_document_type':{
                    localizedField = 'тип документу уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_identity_document':{
                    localizedField = 'документ уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_identity_document_serial_number':{
                    localizedField = 'серію документу уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_identity_document_number':{
                    localizedField = 'номер документу уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_identity_document_issue_date':{
                    localizedField = 'дату документу уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_country':{
                    localizedField = 'країну уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_postal_code':{
                    localizedField = 'поштовий індекс уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_region':{
                    localizedField = 'область уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_settlement':{
                    localizedField = 'населений пункт уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_street':{
                    localizedField = 'вулицю уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_building_number':{
                    localizedField = 'номер будинку уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_building_block_number':{
                    localizedField = 'номер корпусу уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_apartment_or_office_number':{
                    localizedField = 'номер квартири/офісу уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_email':{
                    localizedField = 'електронну адресу уповноваженої особи';
                    break;
                }
                case 'legal_entity_authorized_person_phone_number':{
                    localizedField = 'номер телефону уповноваженої особи';
                    break;
                }
                case 'first_name':{
                    localizedField = 'ім`я';
                    break;
                }
                case 'second_name':{
                    localizedField = 'по-батькові';
                    break;
                }
                case 'last_name':{
                    localizedField = 'прізвище';
                    break;
                }
                case 'is_citizen':{
                    localizedField = 'громадянство України';
                    break;
                }
                case 'has_tax_number':{
                    localizedField = 'наявність РНОКПП';
                    break;
                }
                case 'tax_number':{
                    localizedField = 'РНОКПП';
                    break;
                }
                case 'tax_number_document':{
                    localizedField = 'РНОКПП';
                    break;
                }
                case 'identity_document_type':{
                    localizedField = 'тип документа що посвідчує особу';
                    break;
                }
                case 'identity_document':{
                    localizedField = 'документ що посвідчує особу';
                    break;
                }
                case 'identity_document_serial_number':{
                    localizedField = 'серію документа що посвідчує особу';
                    break;
                }
                case 'identity_document_number':{
                    localizedField = 'номер документа що посвідчує особу';
                    break;
                }
                case 'identity_document_issue_date':{
                    localizedField = 'дату документа що посвідчує особу';
                    break;
                }
                case 'country':{
                    localizedField = 'країну';
                    break;
                }
                case 'postal_code':{
                    localizedField = 'поштовий індекс';
                    break;
                }
                case 'region':{
                    localizedField = 'область';
                    break;
                }
                case 'settlement':{
                    localizedField = 'населений пункт';
                    break;
                }
                case 'street':{
                    localizedField = 'вулицю';
                    break;
                }
                case 'building_number':{
                    localizedField = 'номер будинку';
                    break;
                }
                case 'building_block_number':{
                    localizedField = 'номер блоку';
                    break;
                }
                case 'apartment_or_office_number':{
                    localizedField = 'номер квартири/офісу';
                    break;
                }
                case 'initial_bid_amount':{
                    localizedField = 'розмір закритої цінової пропозиції';
                    break;
                }
                case 'refund_iban':{
                    localizedField = 'рахунок IBAN';
                    break;
                }
                case 'has_preference':{
                    localizedField = 'наявність переважного права';
                    break;
                }
                case 'preference_type':{
                    localizedField = 'тип переважного права';
                    break;
                }
                case 'preference_document_number':{
                    localizedField = 'номер документу переважного права';
                    break;
                }
                case 'preference_document_date':{
                    localizedField = 'дату документу переважного права';
                    break;
                }
                case 'preference_document_expire_at':{
                    localizedField = 'термін дії документу переважного права';
                    break;
                }
                case 'preference_document':{
                    localizedField = 'документ переважного права';
                    break;
                }
                case 'additional_documents':{
                    localizedField = 'додаткові документи';
                    break;
                }
                case 'personal_data_process_consent':{
                    localizedField = 'згоду на обробку персональних данних';
                    break;
                }
                case 'document_number':{
                    localizedField = 'номер документа';
                    break;
                }
                case 'document_date':{
                    localizedField = 'дату документа';
                    break;
                }
                case 'document_expire_at':{
                    localizedField = 'термін дії до документа';
                    break;
                }
                case 'detail':{
                    localizedField = '';
                    break;
                }
                default: {
                    localizedField = error.field?error.field:'';
                    ErrorHandler(`Need localization for field: ${error.field}`)
                }
            }

        if(error.field) return localizedError + ' ' + localizedField;
        else return localizedField;
}