import config from "../../../config/config";
import React, {Fragment, useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import Loading from "../../Loading/Loading";
import s from "../Participation.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import FileSign from "../../FileSign/FileSign";
import {Field, useFormikContext} from "formik";
import {TextAreaField, TextField} from "../../FormikFields/FormikFields";
import {InputItem, InputItemShort, ItemRow} from "../../UI/UI";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";

export default function ShortInfo(){
    const {nextStage} = useContext(ParticipationFormContext)
    return(
        <>
            <fieldset disabled={true}>
                    <InputItemShort label="Організаційно-правова форма">
                        <input defaultValue={"Юридична особа"}/>
                    </InputItemShort>

                    <ItemRow>
                        <InputItem label={"Повна назва юридичної особи"}>
                            <Field disabled={false} name={"legal_entity_name"} component={TextField}/>
                        </InputItem>
                        <InputItem label={"Скорочена назва юридичної особи"}>
                            <Field disabled={false} name={"legal_entity_short_name"} component={TextField}/>
                        </InputItem>
                    </ItemRow>

                    <InputItemShort label={"Код ЄДРПОУ"}>
                        <Field name={"legal_entity_tax_number"} component={TextField}/>
                    </InputItemShort>
            </fieldset>
            <button
                className={s.nextStageButton}
                onClick={nextStage}
            >Далі</button>
        </>
    )
}