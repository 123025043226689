import React from 'react';
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Button,
    CheckboxItem,
    Error,
    InputItem,
    InputItemShort,
    ItemRow,
    Subtitle,
    Title
} from "../../../../components/UI/UI";
import {
    CheckboxField,
    NumberMaskField,
    PhoneNumberField,
    SelectField,
    TextField
} from "../../../../components/FormikFields/FormikFields";
import config from "../../../../config/config";
import ErrorHandler from "../../../../utils/ErrorLoger";
import {useHttp} from "../../../../hooks/HttpHook";
import Loading from "../../../../components/Loading/Loading";
import s from "../../Register.module.css";
import InputField from "../../../../components/FormikFields/InputField";


export default function EntrepreneurThirdStage({onSuccess, mainForm}){
    const {request, loading, requestErrors} = useHttp();
    const userEmail = JSON.parse(localStorage.getItem('registration_data')).email

    async function submitHandler(values){
        const requestData = {
            ...mainForm,
            ...values,
        }
        if(values.individual_address_and_postal_address_is_different === false){
            requestData.individual_postal_address = {
                postal_address_postal_code: values.individual_address.postal_code,
                postal_address_region_id: values.individual_address.region_id,
                postal_address_settlement_id: values.individual_address.settlement_id,
                postal_address_district_id: values.individual_address.district_id,
                postal_address_phone_number: values.individual_address.phone_number,
                postal_address_email: values.individual_address.email,
                postal_address_country: values.individual_address.country,
                postal_address_street: values.individual_address.street,
                postal_address_building_number: values.individual_address.building_number,
                postal_address_building_block_number: values.individual_address.building_block_number,
                postal_address_apartment_or_office_number: values.individual_address.apartment_or_office_number,
            }
        }
        try{
            let data = await request(config.baseUrl + '/api/v1/user/registration/complete/','PUT', requestData);
            if(data && data.token && data.token.access) {
                onSuccess({access:data.token.access, refresh:data.token.refresh});
            }
        }catch(e){
            ErrorHandler(e)
        }
    }

    return(
        <Formik
            validationSchema={entrepreneurSchema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
                individual_enterpreneur_has_tax_number: true,
                rule_accepted:false,
                personal_data_process_consent:false,
                individual_address_and_postal_address_is_different: true,
                individual_address: {
                    email:userEmail,
                },
                individual_postal_address: {
                    email:userEmail,
                }
            }}
            onSubmit={submitHandler}
        >
            {props =>
                <Form>
                    {loading && <Loading/>}

                    <Title>
                        МІСЦЕЗНАХОДЖЕННЯ ФІЗИЧНОЇ ОСОБИ - ПІДПРИЄМЦЯ
                    </Title>

                    <ItemRow>
                        <InputItem label="Країна:">
                            <InputField
                                name="individual_address.country"
                                url="/api/v1/location/countries/"
                                component={SelectField}
                                customId="code"
                            />
                        </InputItem>
                        <InputItem label="Поштовий індекс:">
                            <InputField
                                name="individual_address.postal_code"
                                maskLength={5}
                                component={NumberMaskField}
                            />
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <InputItem label="Область:">
                            <InputField
                                name="individual_address.region_id"
                                url="/api/v1/location/regions/"
                                component={SelectField}
                            />
                        </InputItem>
                        <InputItem label="Район:">
                            <InputField
                                name="individual_address.district_id"
                                queryName={'region_id'}
                                queryValue={props.values.individual_address?.region_id}
                                url={`/api/v1/location/districts/`}
                                component={SelectField}
                            />
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <InputItem label="Населенний пункт:">
                            <InputField
                                name="individual_address.settlement_id"
                                queryName={'district_id'}
                                queryValue={props.values.individual_address?.district_id}
                                url={`/api/v1/location/settlements/`}
                                component={SelectField}
                            />
                        </InputItem>
                        <InputItem label="Вулиця:">
                            <InputField
                                name="individual_address.street"
                                component={TextField}
                            />
                        </InputItem>
                    </ItemRow>

                    <ItemRow>
                        <ItemRow>
                            <InputItem label="Номер будинку:">
                                <InputField
                                    name="individual_address.building_number"
                                    component={TextField}
                                />
                            </InputItem>
                            <InputItem label="Корпус:">
                                <InputField
                                    name="individual_address.building_block_number"
                                    component={TextField}
                                />
                            </InputItem>
                        </ItemRow>
                        <InputItemShort label="Номер квартири/офісу:">
                            <InputField
                                name="individual_address.apartment_or_office_number"
                                component={TextField}
                            />
                        </InputItemShort>
                    </ItemRow>

                    <ItemRow>
                        <InputItem label="E-mail:">
                            <InputField
                                name="individual_address.email"
                                component={TextField}
                            />
                        </InputItem>
                        <InputItem label="Номер телефону:">
                            <InputField
                                name="individual_address.phone_number"
                                component={PhoneNumberField}
                            />
                        </InputItem>
                    </ItemRow>

                    <InputField
                        name="individual_address_and_postal_address_is_different"
                        component={CheckboxField}
                        modifier={(e)=>!e}
                    >
                        Адреса фізичної особи-підприємця збігається з адресою для листування
                    </InputField>

                    {props.values.individual_address_and_postal_address_is_different &&
                        <>
                            <Subtitle style={{marginTop: '30px'}}>
                                Адреса для листування
                            </Subtitle>

                            <ItemRow>
                                <InputItem label="Країна:">
                                    <InputField
                                        name="individual_postal_address.postal_address_country"
                                        url="/api/v1/location/countries/"
                                        component={SelectField}
                                        customId="code"
                                    />
                                </InputItem>
                                <InputItem label="Поштовий індекс:">
                                    <InputField
                                        name="individual_postal_address.postal_address_postal_code"
                                        maskLength={5}
                                        component={NumberMaskField}
                                    />
                                </InputItem>
                            </ItemRow>

                            <ItemRow>
                                <InputItem label="Область:">
                                    <InputField
                                        name="individual_postal_address.postal_address_region_id"
                                        url="/api/v1/location/regions/"
                                        component={SelectField}
                                    />
                                </InputItem>
                                <InputItem label="Район:">
                                    <InputField
                                        name="individual_postal_address.postal_address_district_id"
                                        queryName={'region_id'}
                                        queryValue={props.values.individual_postal_address?.postal_address_region_id}
                                        url={`/api/v1/location/districts/`}
                                        component={SelectField}
                                    />
                                </InputItem>
                            </ItemRow>

                            <ItemRow>
                                <InputItem label="Населенний пункт:">
                                    <InputField
                                        name="individual_postal_address.postal_address_settlement_id"
                                        queryName={'district_id'}
                                        queryValue={props.values.individual_postal_address?.postal_address_district_id}
                                        url={`/api/v1/location/settlements/`}
                                        component={SelectField}
                                    />
                                </InputItem>
                                <InputItem label="Вулиця:">
                                    <InputField name="individual_postal_address.postal_address_street" component={TextField}/>
                                </InputItem>
                            </ItemRow>

                            <ItemRow>
                                <ItemRow>
                                    <InputItem label="Номер будинку:">
                                        <InputField name="individual_postal_address.postal_address_building_number" component={TextField}/>
                                    </InputItem>
                                    <InputItem label="Корпус:">
                                        <InputField name="individual_postal_address.postal_address_building_block_number" component={TextField}/>
                                    </InputItem>
                                </ItemRow>
                                <InputItemShort label="Номер квартири/офісу:">
                                    <InputField name="individual_postal_address.postal_address_apartment_or_office_number" component={TextField}/>
                                </InputItemShort>
                            </ItemRow>

                            <ItemRow>
                                <InputItem label="E-mail:">
                                    <InputField name="individual_postal_address.email" component={TextField}/>
                                </InputItem>
                                <InputItem label="Номер телефону:">
                                    <InputField name="individual_postal_address.phone_number" component={PhoneNumberField}/>
                                </InputItem>
                            </ItemRow>
                        </>
                    }
                    <InputField name="rule_accepted" component={CheckboxField}>
                        Погоджуюсь з умовами договору приєднання та регламентом роботи електронного майданчика
                    </InputField>
                    <InputField name="personal_data_process_consent" component={CheckboxField}>
                        Надаю згоду на обробку моїх персональних данних відповідно до Закону України «Про захист персональних данних»
                    </InputField>
                    {requestErrors && <Error>{requestErrors[0]}</Error>}
                    <Button type="submit" style={{width:'30%', marginTop:'20px'}}>
                        Зареєструватись
                    </Button>
                </Form>
            }
        </Formik>
    )
}




export const entrepreneurSchema = Yup.object().shape({
    rule_accepted: Yup.bool()
        .oneOf([true], 'Поле обов\'язкове'),
    personal_data_process_consent: Yup.bool()
        .oneOf([true], 'Поле обов\'язкове'),
    individual_address: Yup.object().shape({
        postal_code: Yup.string()
            .required('Поле обов\'язкове'),
        region_id: Yup.string()
            .required('Поле обов\'язкове'),
        settlement_id: Yup.string()
            .required('Поле обов\'язкове'),
        district_id: Yup.string()
            .required('Поле обов\'язкове'),
        phone_number: Yup.string()
            .required('Поле обов\'язкове'),
        email: Yup.string()
            .required('Поле обов\'язкове'),
        country: Yup.string()
            .required('Поле обов\'язкове'),
        street: Yup.string()
            .required('Поле обов\'язкове'),
        building_number: Yup.string()
            .required('Поле обов\'язкове'),
    }),
    individual_postal_address: Yup.object()
        .when("individual_address_and_postal_address_is_different", {
            is: true,
            then: Yup.object().shape({
                postal_address_postal_code: Yup.string()
                    .required('Поле обов\'язкове'),
                postal_address_region_id: Yup.string()
                    .required('Поле обов\'язкове'),
                postal_address_settlement_id: Yup.string()
                    .required('Поле обов\'язкове'),
                postal_address_district_id: Yup.string()
                    .required('Поле обов\'язкове'),
                phone_number: Yup.string()
                    .required('Поле обов\'язкове'),
                email: Yup.string()
                    .required('Поле обов\'язкове'),
                postal_address_country: Yup.string()
                    .required('Поле обов\'язкове'),
                postal_address_street: Yup.string()
                    .required('Поле обов\'язкове'),
                postal_address_building_number: Yup.string()
                    .required('Поле обов\'язкове'),
            })
        }),
});



