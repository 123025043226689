import React from 'react';
import {connect, Field, getIn, isObject} from 'formik';
import {Error} from "../UI/UI";
import ErrorMessage from "./ErrorMessage";
import {isArray} from "leaflet/src/core/Util";

const InputField = props => {
    //const fieldParts = props.name.split('.')
    //const error = getIn(props.formik.errors, fieldParts[fieldParts.length-1]);
    let error = null;
    if(props.formik.errors){
        const haveNestedObjects = Object.keys(props.formik.errors).some((el)=>typeof props.formik.errors[el] === 'object')
        if(haveNestedObjects) error = getIn(props.formik.errors, props.name)
        else{
            const name = props.name?.replace('0.', '')
            error = props.formik.errors[name]
        }
        if(props.name?.split('.')[2]=="beneficial_owner_address"){
            error = props.formik.errors["beneficial_owner_address."+props.name.split('.')[3]]
        }
    }

    console.log(props.name)


    //console.log(props.formik.errors, fieldParts[fieldParts.length-1])

    // function index(obj,is) {
    //     if(!is) return null;
    //     const path = is.split('.');
    //     if(path.length === 1 ) {
    //         return obj[path[0]]
    //     }
    //
    //     if(obj[path[0]]) {
    //         const newObj = obj[path[0]];
    //         path.shift();
    //         return index(newObj, path.join('.'))
    //     }
    // }
    // const error = index(props.formik.errors, props.name)
    return(
        <>
            {props.customInput? props.customInput : <Field {...props}/>}
            {error && <Error>{error}</Error>}
        </>
    )

};

export default connect(InputField);