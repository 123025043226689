import s from "../NewLot.module.css";
import React, {useEffect, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import config from "../../../config/config";
import ErrorHandler from "../../../utils/ErrorLoger";

export default function LotDocuments({auctionInfo, id}) {

    const DocumentItem = ({date, name, link}) => {
        return(
            <div id={id} className={s.documentItem}>
                <table>
                    <tbody>
                    <tr>
                        <th className={s.tableName}>Назва документу</th>
                        <th className={s.tableDate}>Дата і час  розміщення:</th>
                    </tr>
                    <tr>
                        <td>
                            <p>Протокол про проведення аукціону</p>
                            <a target="_blank" href={link} className={s.document}>
                                <i className="far fa-file-alt"/>
                                <p>{name}</p>
                            </a>
                        </td>
                        <td>{date}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div id={id} className={s.lotDocuments}>
            <h2 className={s.title}>Документи</h2>
            {auctionInfo.lot && auctionInfo.lot.documents.length > 0?
                <>
                    {auctionInfo.lot.documents.map((elem, i) => {
                        return <DocumentItem
                            name={elem.filename}
                            date={elem.created_at}
                            link={config.baseUrl + elem.url}
                            key={i}
                        />
                    })}
                </>:
                <p className={s.documentsNotFound}>Документів не знайдено</p>
            }
        </div>
    )
}