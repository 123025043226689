import config from "../../../config/config";
import React, {Fragment, useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import Loading from "../../Loading/Loading";
import s from "../Participation.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import FileSign from "../../FileSign/FileSign";
import {Field, useFormikContext} from "formik";
import {CheckboxField, SelectField, TextAreaField, TextField} from "../../FormikFields/FormikFields";
import {InputItem, InputItemShort, ItemRow, OutlinedButton} from "../../UI/UI";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";
import SignFileModal from "../../SignFileModal/SignFileModal";

export default function IndividualInfo({entityName = 'Фізична особа'}){
    const {nextStage} = useContext(ParticipationFormContext);
    const {values, setFieldValue} = useFormikContext();
    const [signStatus, setSignStatus] = useState('unsigned')
    return(
        <>
            <SignFileModal
                isVisible={signStatus==='signing'}
                onSign={(fileId)=>{
                    setSignStatus('signed');
                    setFieldValue('tax_number_document', fileId)
                }}
                onClose={setSignStatus.bind(this, 'unsigned')}
                defaultFileUrl={values.tax_number_document.file}
                transparentBackground={false}
                documentType={"TAX_NUMBER"}
            />
            <fieldset disabled={true}>
                <InputItemShort label="Організаційно-правова форма">
                    <input defaultValue={entityName}/>
                </InputItemShort>


                <InputItemShort label={"Прізвище"}>
                    <Field name={"last_name"} component={TextField}/>
                </InputItemShort>
                <InputItemShort label={"Ім'я"}>
                    <Field name={"first_name"} component={TextField}/>
                </InputItemShort>

                <InputItemShort label={"По батькові"}>
                    <Field name={"second_name"} component={TextField}/>
                </InputItemShort>

                <InputItemShort label={"Громадянство україни"}>
                    <Field
                        disabled={true}
                        data={[{name:'Так', id:true},{name:'Ні', id:false}]}
                        name={"is_citizen"}
                        component={SelectField}
                        checkDefaultById
                    />
                </InputItemShort>
                <ItemRow>
                    <InputItem label={"РНОКПП"}>
                        <Field name={"tax_number"} component={TextField}/>
                    </InputItem>
                    <Field name={"has_tax_number"} modifier={(e)=>!e} component={CheckboxField}>
                        Я відмовився від РНОКПП через свої релігійні переконання та маю про це відмітку в паспорті
                    </Field>
                </ItemRow>



                <InputItemShort label={""}>
                    <OutlinedButton
                        style={{marginTop:'30px'}}
                        onClick={setSignStatus.bind(this, 'signing')}
                        disabled={signStatus==='signed'}
                        color="#057E36"
                    >
                        {signStatus==='signed'?'Підписано':'Підписати'}
                    </OutlinedButton>
                </InputItemShort>

            </fieldset>
            <button
                disabled={!(signStatus==='signed')}
                className={s.nextStageButton}
                onClick={nextStage}
            >Далі</button>
        </>
    )
}