import React, {useEffect, useState} from 'react';
import s from './Instructions.module.css';
import {Subtitle, Title} from "../../components/UI/UI";
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
import {useHttp} from "../../hooks/HttpHook";
import Loading from "../../components/Loading/Loading";
import pdfFileImg from '../../assets/img/pdf-file-blue.png';
function Instructions(){
    const [data, setData] = useState(null);
    const {request} = useHttp()

    async function getData(){
        try{
            let response = await request(config.baseUrl + `/api/v1/information/user-instructions/`,'GET');
            setData(response)
        }catch(e){
            ErrorHandler(e)
        }
    }

    useEffect(()=>{
        getData()
    },[])

    const FileItem = () => {
        return(
            <div className={s.fileItem}>
                <img src={pdfFileImg}/>
                <a href={"#"}>Відкрити у .pdf</a>
            </div>
        )
    }

    return(
        <div className={s.Instructions}>
            {!data ?
                <Loading/>:
                <div className={s.content}>
                    <h1 className={s.title}>Інструкції для користувачів</h1>

                    <div className={s.block}>
                        <Title style={{paddingRight:'50px'}}>Порядок авторизації на електронному майданчику «Rynok-Zemli» <Subtitle>(покрокова інструкція)</Subtitle></Title>
                        <FileItem/>
                    </div>

                    <div className={s.block}>
                        <Title style={{paddingRight:'50px'}}>Процедура здійснення купівлі-продажу права власності на земельну ділянку через електронний майданчик «Rynok-Zemli» від А до Я</Title>
                        <FileItem/>
                    </div>

                    <div className={s.block}>
                        <Title style={{paddingRight:'50px'}}>Процес придбання права оренди на земельну ділянку через електронний майданчик «Rynok-Zemli»</Title>
                        <FileItem/>
                    </div>

                    <div className={s.block}>
                        <Title style={{paddingRight:'50px'}}>Механізм придбання права емфітевзису та суперфіцію від реєстрації заявки до укладення договору</Title>
                        <FileItem/>
                    </div>

                    <div className={s.block}>
                        <Title style={{paddingRight:'50px'}}>Основні кроки з продажу земельних ділянок, прав емфітевзису, суперфіцію на них державним виконавцем, приватним виконавцем під час виконання рішень, що підлягають примусовому виконанню в порядку, встановленому Законом України «Про виконавче провадження»</Title>
                        <FileItem/>
                    </div>

                </div>
            }
        </div>
    )
}

export default Instructions;