import config from "../../../config/config";
import React, {Fragment, useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import Loading from "../../Loading/Loading";
import s from "../Participation.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import FileSign from "../../FileSign/FileSign";
import {Field, useFormikContext} from "formik";
import {
    CheckboxField,
    DateField,
    FileField,
    NumericField,
    SelectField,
    TextAreaField,
    TextField
} from "../../FormikFields/FormikFields";
import {InputItem, InputItemShort, ItemRow, Line, OutlinedButton} from "../../UI/UI";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";
import SignComponent from "../../SignComponent/SignComponent";
import ErrorHandler from "../../../utils/ErrorLoger";
import {AuthContext} from "../../../context/AuthContext";
import SignFileModal from "../../SignFileModal/SignFileModal";
import InputField from "../../FormikFields/InputField";
import dictionary from "../../../config/dictionary";

export default function ChiefDocuments(){
    const {values, setFieldValue} = useFormikContext();
    const [taxSignStatus, setTaxSignStatus] = useState('unsigned')
    const [identitySignStatus, setIdentitySignStatus] = useState('unsigned')
    const {nextStage} = useContext(ParticipationFormContext)

    return(
        <>
            <SignFileModal
                isVisible={identitySignStatus==='signing'}
                onSign={(fileId)=>{
                    setIdentitySignStatus('signed');
                    setFieldValue('legal_entity_chief_identity_document', fileId)
                }}
                onClose={setIdentitySignStatus.bind(this, 'unsigned')}
                defaultFileUrl={values.legal_entity_chief_identity_document.file}
                transparentBackground={false}
                documentType={"LEGAL_ENTITY_CHIEF_IDENTITY_DOCUMENT"}
            />

            <SignFileModal
                isVisible={taxSignStatus==='signing'}
                onSign={(fileId)=>{
                    setTaxSignStatus('signed');
                    setFieldValue('legal_entity_chief_tax_number_document', fileId)
                }}
                onClose={setTaxSignStatus.bind(this, 'unsigned')}
                defaultFileUrl={values.legal_entity_chief_tax_number_document.file}
                transparentBackground={false}
                documentType={"LEGAL_ENTITY_CHIEF_TAX_NUMBER"}
            />
            <fieldset disabled={true}>
                <InputItem label="РНОКПП керівника">
                    <InputField name="legal_entity_chief_tax_number" component={TextField}/>
                </InputItem>
                <ItemRow>
                    {values.legal_entity_chief_has_tax_number &&
                        <InputItem>
                            <OutlinedButton
                                style={{marginTop:'50px'}}
                                onClick={setTaxSignStatus.bind(this, 'signing')}
                                disabled={taxSignStatus==='signed'}
                                color="#057E36"
                            >
                                {taxSignStatus==='signed'?'Підписано':'Підписати'}
                            </OutlinedButton>
                        </InputItem>
                    }
                    <InputField
                        style={{marginTop:'50px'}}
                        name="legal_entity_chief_has_tax_number"
                        component={CheckboxField}
                        disabled={true}
                        modifier={(e)=>!e}
                    >
                        Керівник відмовився від РНОКПП через свої релігійні переконання та має про це відмітку у паспорті
                    </InputField>
                </ItemRow>

                <Line/>

                <ItemRow>
                    <InputItem label="Документ що посвідчує особу">
                        <InputField
                            name="legal_entity_chief_identity_document_type"
                            data={dictionary.DOCUMENT_TYPES}
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItem>
                    <InputItem label="Дата видачі">
                        <InputField name="legal_entity_chief_identity_document_issue_date" component={TextField}/>
                    </InputItem>
                </ItemRow>

                <ItemRow>
                    <ItemRow>
                        <InputItem label="Серія">
                            <InputField name="legal_entity_chief_identity_document_serial_number" component={TextField}/>
                        </InputItem>
                        <InputItem label="Номер">
                            <InputField name="legal_entity_chief_identity_document_number" component={NumericField}/>
                        </InputItem>
                    </ItemRow>
                    <InputItem label={"Документ, що посвідчує особу"}>
                        <OutlinedButton
                            onClick={setIdentitySignStatus.bind(this, 'signing')}
                            disabled={identitySignStatus==='signed'}
                            color="#057E36"
                        >
                            {identitySignStatus==='signed'?'Підписано':'Підписати'}
                        </OutlinedButton>
                    </InputItem>
                </ItemRow>


            </fieldset>
            <button
                disabled={!(
                    identitySignStatus === 'signed' &&
                    taxSignStatus === 'signed'
                )}
                className={s.nextStageButton}
                onClick={nextStage}
            >Далі</button>
        </>
    )
}