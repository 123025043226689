import s from "../OrganizerLot.module.css";
import checkmarkIcon from "../../../assets/img/checkmark-blue.png";
import React, {useContext, useEffect, useState} from "react";
import config from "../../../config/config";
import ErrorHandler from "../../../utils/ErrorLoger";
import {useHttp} from "../../../hooks/HttpHook";
import Modal from "../../../components/Modal/Modal";
import SignComponent from "../../../components/SignComponent/SignComponent";
import {AuthContext} from "../../../context/AuthContext";
//rozkomentuvatu disabled button
// delete modal type
export default function DocumentsComponent({item}){
    const [protocols, setProtocols] = useState([])
    const {request} = useHttp();
    const getProtocols = async () => {
        try{
            let data = await request(config.baseUrl + `/api/v1/auctions/auction-protocols/${item.id}/`,'GET');
            if(data) setProtocols(data)
        }catch (e) {
            ErrorHandler(e)
        }
    }
    useEffect(()=>{
        getProtocols();
    },[])

    const DocumentItem = ({date, number, name, status}) => {
        return(
            <div className={s.documentItem}>
                <table>
                    <tbody>
                    <tr>
                        <th className={s.tableDate}>Дата і час  розміщення:</th>
                        <th className={s.tableNumber}>Номер документа:</th>
                        <th className={s.tableName}>Назва документу</th>
                        <th className={s.tableStatus}>Статус:</th>
                    </tr>
                    <tr>
                        <td>{date}</td>
                        <td>{number}</td>
                        <td>
                            <p>Протокол про проведення аукціону</p>
                            <a className={s.document}>
                                <i className="far fa-file-alt"/>
                                <p>{name}</p>
                            </a>
                        </td>
                        <td>{status}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    const localizeStatus = (status) => {
        switch (status) {
            case "AWAITING_WINNER_SIGNATURE": return 'Очікує підписання переможцем'
            case "AWAITING_ORGANIZER_SIGNATURE": return 'Очікує підписання організатором'
            case "WINNER_NOT_SIGNED": return 'Переможець не підписав'
            case "TOTALY_SIGNED": return 'Підписано'
            case "DECLINED": return 'Відмовлено'

        }
    }

    const AuctionDocuments = () => {
        return (
            <>
                <h2 className={s.title}>Протокол</h2>
                {protocols.length > 0 ?
                <>

                    {protocols.map((elem, i) => {
                        const fileParts = elem.file.split('/');
                        const filename = fileParts[fileParts.length-1];
                        return <DocumentItem
                            status={localizeStatus(elem.protocol_status)}
                            name={filename}
                            date={elem.created_at}
                            number={elem.id}
                            key={i}
                        />
                    })}
                </>:
                    <p className={s.documentsNotFound}>Документів не знайдено</p>
                }
            </>
        )
    }

    const Actions = () => {
        const [modalType, setModalType] = useState(null);
        const [signVisible, setSignVisible] = useState(false);
        const auth = useContext(AuthContext);
        const currentProtocol = protocols[protocols.length-1];

        const ActionItem = ({text, complete, onClick, disabled}) => {
            return (
                <a onClick={complete || disabled?()=>{}:onClick} className={complete?s.actionItem:disabled?s.actionItemDisabled:s.actionItem}>
                    {complete?
                        <img src={checkmarkIcon}/>:
                        <div className={s.circle}/>
                    }
                    <p>{text}</p>
                    <i className="fas fa-long-arrow-alt-right"/>
                </a>
            )
        }

        const checkHandler = async () => {
            try{
                let data = await request(config.baseUrl + `/api/v1/auctions/auction-protocols/${currentProtocol.id}/mark-as-checked-by-organizer/`,'PUT');
                if(data) getProtocols();
            }catch (e) {
                ErrorHandler(e)
            }
        }

        const signHandler = async (file) => {
            console.log('signHandler', file)
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${auth.token}`);
            const formData = new FormData();
            formData.append("file", new Blob([file.signedFile], {type: 'application/pkcs7-signature'}), file.sourceFile.name+'.p7s');
            const requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            };
            fetch(config.baseUrl + `/api/v1/auctions/auction-protocols/sign/${currentProtocol.id}/organizer/`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    getProtocols()
                    setModalType(null)
                })
                .catch(error => console.log('error', error));
        }


        const publishHandler = async () => {
            try{
                let data = await request(config.baseUrl + `/api/v1/auctions/auction-protocols/publish/${currentProtocol.id}/`,'PUT');
                if(data) getProtocols();
            }catch (e) {
                ErrorHandler(e)
            }
        }

        return(
            <div className={s.actions}>

                {modalType === 'check_signature' &&
                <Modal onClose={()=>{setModalType(null)}} isVisible={true}>
                    <div className={s.protocolCheckModal}>
                        <h1>Перевірити підпис</h1>
                        <p>Натисніть на файл, щоб переглянути його</p>
                        <a target="_blank" href={config.baseUrl + currentProtocol?.file}>{currentProtocol?.file.split('/')[currentProtocol?.file.split('/').length-1]}</a>

                        <button onClick={checkHandler}>Готово</button>
                        {/*<SignComponent transparentBackground={true} isVisible={true}/>*/}
                    </div>
                </Modal>}

                {modalType === 'sign_and_publish' &&
                <Modal onClose={()=>{setModalType(null)}} isVisible={true}>
                    <div className={s.protocolCheckModal}>
                        <h1>Підписати і оприлюднити протокол</h1>
                        <p>Натисніть на файл, щоб переглянути його</p>
                        <a target="_blank" href={config.baseUrl + currentProtocol?.file}>{currentProtocol?.file.split('/')[currentProtocol?.file.split('/').length-1]}</a>
                        <button
                            style={currentProtocol && currentProtocol.protocol_status === 'TOTALY_SIGNED'?{backgroundColor:'#6b6e75'}:{}}
                            disabled={currentProtocol && currentProtocol.protocol_status === 'TOTALY_SIGNED'}
                            onClick={()=>{
                                setSignVisible(true)
                            }}
                        >Підписати</button>
                        <button onClick={publishHandler}>Оприлюднити</button>
                        <SignComponent
                            transparentBackground={true}
                            isVisible={signVisible}
                            onClose={()=>setSignVisible(false)}
                            onSign={signHandler}
                            // defaultFile={new File(["foo"], "foo.txt", {
                            //     type: "text/plain",
                            // })}
                            defaultFileUrl={config.baseUrl + currentProtocol?.signed_file}
                        />
                    </div>
                </Modal>}


                <h5 className={s.actionsTitle}>Доступні дії:</h5>
                <ActionItem
                    complete={currentProtocol && currentProtocol.signature_is_cheked_by_organizer}
                    disabled={currentProtocol?currentProtocol.protocol_status !== 'AWAITING_ORGANIZER_SIGNATURE':true}
                    text="Перевірити підпис"
                    onClick={()=>{
                        setModalType('check_signature')
                    }}
                />
                {console.log(currentProtocol)}
                <ActionItem
                    complete={currentProtocol && currentProtocol.is_published}
                    disabled={
                        currentProtocol?
                        currentProtocol.protocol_status === 'AWAITING_ORGANIZER_SIGNATURE'?
                            !currentProtocol.signature_is_cheked_by_organizer:
                            currentProtocol.protocol_status === 'TOTALY_SIGNED'?
                                currentProtocol.is_published:
                                true:
                            true

                     }
                    // disabled={
                    //     currentProtocol &&
                    //     currentProtocol.protocol_status === 'AWAITING_ORGANIZER_SIGNATURE'?
                    //         currentProtocol.signature_is_cheked_by_organizer?false:true
                    //         :currentProtocol.protocol_status === 'TOTALY_SIGNED' && currentProtocol.is_published?true:false
                    //
                    // }
                    // disabled={!(
                    //     currentProtocol &&
                    //     currentProtocol.protocol_status === 'AWAITING_ORGANIZER_SIGNATURE' &&
                    //     currentProtocol.signature_is_cheked_by_organizer &&
                    //     !currentProtocol.is_published
                    // )}
                    text="Підписати і оприлюднити протокол"
                    onClick={()=>{
                        setModalType('sign_and_publish')
                    }}
                />
                <ActionItem
                    complete={false}
                    disabled={true}
                    text="Сформувати акт переможця про відмову від підписання протоколу"
                    onClick={()=>{
                        alert('test');
                    }}
                />
                <ActionItem
                    complete={false}
                    disabled={true}
                    text="Сформувати рішення організатора про відмову  від підписання протоколу"
                    onClick={()=>{
                        alert('test');
                    }}
                />
            </div>
        )
    }

    return(
            <div className={s.DocumentsComponent}>
                <AuctionDocuments/>
                <Actions/>
            </div>
    )
}