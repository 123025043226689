import React, {useEffect, useState} from 'react';
import s from './Applications.module.css';
import file from '../../assets/img/document-icon.png';
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import Loading from "../../components/Loading/Loading";
import ApplicationItem from "../../components/ApplicationItem/ApplicationItem";
import ErrorHandler from "../../utils/ErrorLoger";
import CabinetSideBar from "../../components/CabinetSideBar/CabinetSideBar";
function Applications() {
    const [lots, setLots] = useState([]);
    const [detailShown, setDetailShown] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const {request,loading} = useHttp();

    const Status = ({status}) => {
        switch (status) {
            case 'AWAITING_PAYMENT': return <td>Не оплачена</td>
            case 'AWAITING_APPROVAL': return <td>Не підтверджена</td>
            case 'AWAITING_SIGNATURE': return <td>Не підписана</td>
            case 'AWAITING_WAIT_CONSENT': return <td>Попередня згода на очікування</td>
            case 'AWAITING_SUBMISSION': return <td>Не подана</td>
            case 'ACCEPTED': return <td className={s.green}>Прийнята</td>
            case 'CANCELED': return <td className={s.red}>Скасована</td>
            case 'EDITED': return <td>Змінена</td>
            case 'REJECTED': return <td className={s.red}>Відхилена</td>
        }
    }

    const getData = async () => {
        let data;
        try{
            data = await request(config.baseUrl + `/api/v1/auctions/user-entrants/`)
            if(data.results) setLots(data.results);
        }catch (e) {
            ErrorHandler(e)
        }
    }


    useEffect(async ()=>{
        await getData();
    },[])

    return(
        <CabinetSideBar>
        {loading?<Loading/>:<>
        <div className={s.Applications}>
            <div className={s.content}>
                {detailShown?<ApplicationItem onUpdate={getData} onClose={()=>{setDetailShown(null)}} item={lots.filter(el=>el.auction.id===selectedId)[0]}/>:
                <table cellSpacing="0" cellPadding="0" border="0"
                       className={s.table}>
                    <tr>
                        <th>Дата і час</th>
                        <th>№ аук-ну</th>
                        <th>Кадастровий номер</th>
                        <th>Заява</th>
                        <th>Статус</th>
                        <th>Оплата</th>
                    </tr>
                    <tr className={s.tableGap}>
                        <td/><td/><td/><td/><td/><td/>
                    </tr>
                    {lots.map((item)=>{
                        return (
                            <>
                                <tr onClick={()=>{
                                    setDetailShown(true);
                                    setSelectedId(item.auction.id);
                                }} className={s.tableItem}>
                                    <td>{item.created_at}</td>
                                    <td>{item.auction.id}</td>
                                    <td>{item.auction.cadastral_number}</td>
                                    <td><a target="_blank" href={item.application_file?config.baseUrl + item.application_file.url:null}><img src={file}/></a></td>
                                    {/*<td>Не підписана</td>*/}
                                    <Status status={item.status}/>
                                    {item.payment.status?
                                        <td className={s.green}>Оплачено</td>:
                                        <td className={s.yellow}>Очікується</td>
                                    }

                                </tr>
                                <tr className={s.tableGap}>
                                    <td/><td/><td/><td/><td/><td/>
                                </tr>
                            </>
                        )
                    })}


                    {/*<tr className={s.tableItem}>*/}
                    {/*    <td>25.09.2021-14:30</td>*/}
                    {/*    <td>766</td>*/}
                    {/*    <td>Територія біля річки</td>*/}
                    {/*    <td><a><img src={file}/></a></td>*/}
                    {/*    <td className={s.green}>Підписана</td>*/}
                    {/*    <td className={s.green}>Оплачено</td>*/}
                    {/*</tr>*/}
                    {/*<tr className={s.tableGap}>*/}
                    {/*    <td/><td/><td/><td/><td/><td/>*/}
                    {/*</tr>*/}
                    {/*<tr className={s.tableItem}>*/}
                    {/*    <td>25.09.2021-14:30</td>*/}
                    {/*    <td>766</td>*/}
                    {/*    <td>Територія біля річки</td>*/}
                    {/*    <td><a><img src={file}/></a></td>*/}
                    {/*    <td>Не підписана</td>*/}
                    {/*    <td className={s.yellow}>Очікується</td>*/}
                    {/*</tr>*/}
                    {/*<tr className={s.tableGap}>*/}
                    {/*    <td/><td/><td/><td/><td/><td/>*/}
                    {/*</tr>*/}
                    {/*<tr className={s.tableItem}>*/}
                    {/*    <td>25.09.2021-14:30</td>*/}
                    {/*    <td>766</td>*/}
                    {/*    <td>Територія біля річки</td>*/}
                    {/*    <td><a><img src={file}/></a></td>*/}
                    {/*    <td className={s.green}>Підписана</td>*/}
                    {/*    <td className={s.green}>Оплачено</td>*/}
                    {/*</tr>*/}
                    {/*<tr className={s.tableGap}>*/}
                    {/*    <td/><td/><td/><td/><td/><td/>*/}
                    {/*</tr>*/}
                    {/*<tr className={s.tableItem}>*/}
                    {/*    <td>25.09.2021-14:30</td>*/}
                    {/*    <td>766</td>*/}
                    {/*    <td>Територія біля річки</td>*/}
                    {/*    <td><a><img src={file}/></a></td>*/}
                    {/*    <td>Не підписана</td>*/}
                    {/*    <td className={s.yellow}>Очікується</td>*/}
                    {/*</tr>*/}
                    {/*<tr className={s.tableGap}>*/}
                    {/*    <td/><td/><td/><td/><td/><td/>*/}
                    {/*</tr>*/}
                    {/*<tr className={s.tableItem}>*/}
                    {/*    <td>25.09.2021-14:30</td>*/}
                    {/*    <td>766</td>*/}
                    {/*    <td>Територія біля річки</td>*/}
                    {/*    <td><a><img src={file}/></a></td>*/}
                    {/*    <td className={s.green}>Підписана</td>*/}
                    {/*    <td className={s.green}>Оплачено</td>*/}
                    {/*</tr>*/}
                </table>}
                {lots.length<1?<p className={s.warning}>У вас поки немає заяв на участь</p>:null}
            </div>
        </div></>}
        </CabinetSideBar>
    )
}

export default Applications;