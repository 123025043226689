import {useState, useCallback, useEffect, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import jwt from 'jwt-decode';
import config from '../config/config.js'
import ErrorHandler from "../utils/ErrorLoger";
import {AuthContext} from "../context/AuthContext";
import {useHttp} from "./HttpHook";
export const useUserInfo = (scope) => {
    const [userInfo, setUserInfo] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [newNotifications, setNewNotifications] = useState([]);
    const [profileId, setProfileId] = useState(null)
    const {request} = useHttp();

    const getUserInfo = async () => {
        try{
            if(scope === 'main') {
                const data = await request(config.baseUrl + '/api/v1/user/info/', 'GET');
                if(data && data.phone_number) setUserInfo(data);
                const profId = data.individual_enterpreneur_profile_id ||
                    data.individual_profile_id ||
                    data.legal_entity_profile_id
                setProfileId(profId)
            }

        }catch(e){
            ErrorHandler(e)
        }

    }


    const getNotifications = async () => {
        try{
            if(scope === 'main') {
                const notifications = await request(config.baseUrl + '/api/v1/notifications/', 'GET');
                if(notifications && notifications.results && notifications.results.length>0) setNotifications(notifications.results);
            }
        }catch(e){
            ErrorHandler(e)
        }
    }

    const getNewNotifications = async () => {
        try{
            if(scope === 'main') {
                const notifications = await request(config.baseUrl + '/api/v1/notifications/?is_read=False', 'GET');
                if(notifications && notifications.results && notifications.results.length>0) setNewNotifications(notifications.results);
            }
        }catch(e){
            ErrorHandler(e)
        }
    }

    useEffect(()=>{
        getUserInfo();
    },[scope])

    return { getUserInfo, userInfo, getNotifications, notifications, getNewNotifications, newNotifications, profileId }
}