import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {Field, Form, Formik, useFormikContext} from "formik";
import {CategoryField, SelectField, TextField} from "../../FormikFields/FormikFields";
import s from "../../../pages/Home/Home.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import Slider from "../../../pages/Home/components/Slider";
import {default as backgroundImage} from "../../../assets/img/topSearchBackground2.jpg";
import {Link} from "react-router-dom";
import {AuctionFilterContext} from "../../../context/AuctionFilterContext";
import config from "../../../config/config";
import ErrorHandler from "../../../utils/ErrorLoger";
import {useHttp} from "../../../hooks/HttpHook";
import {InputItem} from "../../UI/UI";
//import st from './dropdown.css';

// const resetFiltersHandler = async () => {
//     setSelectFilter({});
//     setSelectValues({})
//     setFilterDate(null);
// }

function MainSearchFilter(){
    const {filter, setFilter} = useContext(AuctionFilterContext);
    const [filterTypes, setFilterTypes] = useState();
    const {request} = useHttp();

    const submitHandler = (values) => {
        console.log('submint', values)
        setFilter({...filter, ...values});
    }

    const ClearFiltersButton = () => {
        const {resetForm} = useFormikContext();
        const clearFiltersHandler = () => {
            setFilter({});
            resetForm();
        }

        return(
            <span onClick={clearFiltersHandler} className={s.mapFilter}>
                <p>Скинути фільтр</p>
            </span>
        )
    }


    const FilterTypes = () => {
        const {values, setFieldValue} = useFormikContext();
        const typesHandler = (e) => {
            const id = +e.target.getAttribute('name');
            if(id !== values.type_id) setFieldValue('type_id', id)
            else if(id === values.type_id) setFieldValue('type_id', undefined)
        }

        return (
            <div className={s.filterTypes}>
                {filterTypes?filterTypes.map((el, id)=>{
                    return <p key={id} onClick={typesHandler} className={values.type_id===el.id?s.filterTypeAcive:null} name={el.id}>{el.name}</p>
                }):null}
            </div>
        )
    }

    const CategoryFilter = () => {

        const styles = {
            "nested-select-dropdown":{
                width: "calc(100% - 130px)"
            }
        }


        return(
            <Field
                style={styles}
                dropdown
                className={s.categoryFilter}
                name="cav_ps_code"
                url="/api/v1/land-market/lands/purpose-categories/"
                nestingKeys={['first_nested_categories', 'second_nested_categories', 'purpose_categories']}
                placeholder="Класифікатор CAV-PS"
                codeLabel="cav_ps_code"
                nameLabel="name"
                selectLabel="cav_ps_code"
                component={CategoryField}
                onSelect={(item, form) => {
                    console.log(item)
                }}
            />
        )
    }

    const SearchFilter = () => {
        return (
            <div className={s.filterInput}>
                <i className="fas fa-search"/>
                <InputItem style={{height:'100%'}}>
                    <Field
                        component={TextField}
                        name="search"
                        placeholder="Кадастровий номер, № торгів"
                    />
                </InputItem>
            </div>
        )
    }

    const StatusFilter =() => {
        return(
            <div className={s.statusFilter}>
                <InputItem>
                    <Field
                        style={{backgroundColor:'white', borderRadius:"4px"}}
                        name="auction_status"
                        data={[
                            { name: "Подання пропозицій", id: 'PENDING' },
                            { name: "Аукціон", id: 'STARTED' },
                            { name: "Очікування підписання протоколу", id: 'AWAITING_PROTOCOL_SIGN' },
                            { name: "Очікування підписання договору", id: 'AWAITING_CONTRACT_SIGN' },
                            { name: "Очікування оплати", id: 'AWAITING_PAYMENT' },
                            { name: "Не відбулися", id: 'NOT_OCCURRED' },
                            { name: "Скасовано", id: 'CANCELED' },
                            { name: "Завершено", id: 'ENDED' },
                        ]}
                        placeholder="Статус торгів"
                        component={SelectField}
                        checkDefaultById
                    />
                </InputItem>

            </div>
        )
    }

    const AreaFilter = ({onChange}) => {
        const [areaShown, setAreaShown] = useState(false);
        const {values, setFieldValue} = useFormikContext();
        return (
            <div className={s.filterBox}>
                <div onClick={(e)=>{
                    setAreaShown(!areaShown)
                }} className={s.filterBoxContent}>
                    <p>Площа</p>
                    {areaShown?
                        <svg className={s.svg} fill="currentColor" viewBox="0 0 40 40"><path d="M31 26.4q0 .3-.2.5l-1.1 1.2q-.3.2-.6.2t-.5-.2l-8.7-8.8-8.8 8.8q-.2.2-.5.2t-.5-.2l-1.2-1.2q-.2-.2-.2-.5t.2-.5l10.4-10.4q.3-.2.6-.2t.5.2l10.4 10.4q.2.2.2.5z"></path></svg>:
                        <svg className={s.rotatedSvg+' '+s.svg} fill="currentColor" viewBox="0 0 40 40"><path d="M31 26.4q0 .3-.2.5l-1.1 1.2q-.3.2-.6.2t-.5-.2l-8.7-8.8-8.8 8.8q-.2.2-.5.2t-.5-.2l-1.2-1.2q-.2-.2-.2-.5t.2-.5l10.4-10.4q.3-.2.6-.2t.5.2l10.4 10.4q.2.2.2.5z"></path></svg>
                    }
                </div>

                {areaShown && <Slider
                    isVisable={areaShown}
                    onChange={(area)=>{
                        setFieldValue('area_min', area.min);
                        setFieldValue('area_max', area.max);
                        // onChange({
                        //     area_min: area.min,
                        //     area_max: area.max
                        // })
                    }}
                    onClose={()=>{
                        setAreaShown(false);
                    }}
                    minValue={0}
                    maxValue={1000}
                    defaultValue={{min:values.area_min || 0, max:values.area_max || 1000}}
                />}
            </div>
        )
    }


    useEffect(async ()=>{
        try{
            const types = await request(config.baseUrl + `/api/v1/land-market/lands/types/`,'GET');
            if(types) setFilterTypes(types);
        }catch(e){
            ErrorHandler(e)
        }
    },[])

    return(
        <div className={s.topSearchBar}>

            <img className={s.backgroundImage} src={backgroundImage}/>
            <div className={s.topFiltersBoxBackground}/>
            <div className={s.topFiltersBox}>
                <Formik initialValues={filter} enableReinitialize={true} onSubmit={submitHandler}>
                    {props =>
                        <Form>
                            <>
                                <FilterTypes/>
                                <CategoryFilter/>
                                {console.log(props.values)}
                                <div className={s.filterInputs}>
                                    <SearchFilter/>
                                    <StatusFilter/>
                                    <AreaFilter/>
                                    <button type="submit" className={s.filterSearchButton}>Знайти</button>
                                </div>
                                <div className={s.filerBottomBar}>
                                    <Link to="/map/" className={s.mapFilter}><i className="far fa-map"/><p>Знайти на
                                        карті</p></Link>
                                    <ClearFiltersButton/>
                                    <Link to="/filters/" className={[s.mapFilter, s.extendedSearch].join(' ')}>
                                        <p>Розширенний пошук</p></Link>
                                </div>
                            </>
                        </Form>
                    }
                </Formik>

            </div>
        </div>
    )
}














const MainFilters = ({onSelect}) => {
    const [filter, setFilter] = useState({
        area_min:0,
        area_max:1000,
        price_min:0,
        price_max:1000000000
    });




    // const PriceFilter = useCallback(() => {
    //     return (
    //         <div className={s.filterBox}>
    //             <div onClick={(e)=>{
    //                 setPriceShown(!priceShown)
    //             }} className={s.filterBoxContent}>
    //                 <p>Ціна</p>
    //                 {priceShown?
    //                     <i className="fas fa-chevron-up"/>:
    //                     <i className="fas fa-chevron-down"/>
    //                 }
    //             </div>
    //             {priceShown &&
    //                 <Slider
    //                     isVisable={priceShown}
    //                     onChange={(price)=>{
    //                         setFilter({
    //                             ...filter,
    //                             price_min: price.min,
    //                             price_max: price.max
    //                         })
    //                     }}
    //                     onClose={()=>{
    //                         setPriceShown(false);
    //                     }}
    //                     minValue={0}
    //                     maxValue={1000000000}
    //                     defaultValue={{min:filter.price_min, max:filter.price_max}}
    //                 />}
    //         </div>
    //     )
    // },[priceShown])
    console.log(filter)






    return(
        <div className={s.topSearchBar}>

            <img className={s.backgroundImage} src={backgroundImage}/>
            <div className={s.topFiltersBoxBackground}/>
            <div className={s.topFiltersBox}>
                <FilterTypes onChange={filtersChangeHandler}/>
                <FilterCategories onChange={filtersChangeHandler}/>
                <div className={s.filterInputs}>
                    <SearchFilter onChange={filtersChangeHandler}/>
                    {/*<PriceFilter/>*/}
                    <StatusFilter onChange={filtersChangeHandler}/>
                    <AreaFilter onChange={filtersChangeHandler}/>
                    <button
                        onClick={()=>{onSelect({...propsFilter, ...filter})}}
                        className={s.filterSearchButton}
                    >Знайти</button>
                </div>
                <div className={s.filerBottomBar}>
                    <Link to="/map/" className={s.mapFilter}><i className="far fa-map"/><p>Знайти на карті</p></Link>
                    <span onClick={()=>{
                        onSelect({});
                        setFilter({})
                    }} className={s.mapFilter}><p>Скинути фільтр</p></span>
                    <Link to="/filters/" className={[s.mapFilter, s.extendedSearch].join(' ')}><p>Розширенний пошук</p></Link>
                </div>
            </div>
        </div>
    )
}

export default MainSearchFilter;