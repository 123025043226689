import React from 'react';
import * as Yup from "yup";
import {Field} from "formik";
import {InputItemShort} from "../../../../components/UI/UI";
import {NumberMaskField, TextField} from "../../../../components/FormikFields/FormikFields";
import InputField from "../../../../components/FormikFields/InputField";

export default function EntrepreneurSecondStage(){

    return(
        <>
            <InputItemShort label="Код РНОКПП:">
                <InputField name="individual_enterpreneur_tax_number" maskLength={10} component={NumberMaskField}/>
            </InputItemShort>

            <InputItemShort label="Прізвище:">
                <InputField name="individual_enterpreneur_second_name" component={TextField}/>
            </InputItemShort>

            <InputItemShort label="Ім'я:">
                <InputField name="individual_enterpreneur_first_name" component={TextField}/>
            </InputItemShort>

            <InputItemShort label="По батькові:">
                <InputField name="individual_enterpreneur_last_name" component={TextField}/>
            </InputItemShort>
        </>
    )
}

export const entrepreneurSchema = Yup.object().shape({
    individual_enterpreneur_tax_number: Yup.string()
        .required('Поле обов\'язкове')
        .matches(/^[0-9]+$/, "Невірно введено")
        .min(10, 'Довжина повинна бути 10 цифр')
        .max(10, 'Довжина повинна бути 10 цифр'),
    individual_enterpreneur_second_name: Yup.string()
        .required('Поле обов\'язкове'),
    individual_enterpreneur_first_name: Yup.string()
        .required('Поле обов\'язкове'),
    individual_enterpreneur_last_name: Yup.string()
        .required('Поле обов\'язкове'),
    profile_type: Yup.string()
        .required('Поле обов\'язкове'),
});