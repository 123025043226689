import React, {useRef, useState} from "react";
import s from './Questions.module.css';
import {Field} from "formik";
import {useHttp} from "../../hooks/HttpHook";
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
export default function AddQuestion({onStageChange, requestUrl}){
    const maxLength = 10000;
    const [question, setQuestion] = useState('');
    const [theme, setTheme] = useState('');
    const questionRef = useRef();
    const {request, error} = useHttp();
    const textLengthHandler = (e) => {
        if(e.target.value.length <= maxLength) {
            setQuestion(e.target.value)
        }else {
            questionRef.current.value = question;
        }
    }

    const submitQuestion = () => {
        try{
            request(requestUrl,'POST',{
                topic: theme,
                message: question
            }).then((data)=>{
                if(data) {
                    onStageChange('success')
                }
            });
        }catch(e){
            ErrorHandler(e)
        }
    }

    return(
        <div className={s.AddQuestion}>
            <div className={s.inputItem}>
                <label className={s.questionTheme}><span>Тема запитання:</span><p onClick={()=>{onStageChange('list')}}><i className="fas fa-long-arrow-alt-left"/>Назад</p></label>
                <input onChange={(e)=>{setTheme(e.target.value)}} name="contact_person_name"/>
            </div>
            <div className={s.inputItem}>
                <label>Запитання:</label>
                <textarea ref={questionRef} onChange={textLengthHandler} name="contact_person_name"/>
            </div>
            <p className={s.messageLetters}>Залишилось: {maxLength-question.length} символів</p>
            {error && <p className={s.error}>{error}</p>}
            <button onClick={submitQuestion} className={s.questionButton}>Задати питання</button>
        </div>
    )
}