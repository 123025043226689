import React from "react";
import s from './Questions.module.css';
export default function QuestionsList({onStageChange, onCurrentChatChange, chatList}){

    const QuestionItem = ({item}) => {
        return <div className={s.questionItem}>
            <p className={s.questionDate}>23.08.2021</p>
            <div className={s.questionItemContent}>
                <p className={s.questionTitle}>Тема: <span>{item.topic}</span></p>
                <div className={s.questionItemRight}>
                    {/*<p className={s.counter}>2</p>*/}
                    <p onClick={()=>{onCurrentChatChange(item.chat_id);onStageChange("chat")}} className={s.questionLink}>Перейти до чату</p>
                </div>
            </div>
        </div>
    }

    return(
        <>
            <div className={s.lotQuestionsBox}>
                {chatList.map((el)=><QuestionItem item={el}/>)}
            </div>
            <button onClick={()=>{onStageChange('create')}} className={s.questionButton}>Задати питання</button>
        </>
    )
}