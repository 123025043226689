import React, {useState, useEffect, useRef, Fragment} from 'react';
import s from './Card.module.css';
import {Link} from 'react-router-dom';
import config from '../../config/config.js';
import timeIcon from '../../assets/img/combined-shape_4.png';
import aim from '../../assets/img/aim.png';
import locationIcon from '../../assets/img/location.png';
import {useHttp} from "../../hooks/HttpHook";
import ErrorHandler from "../../utils/ErrorLoger";
function Card(props){
    const [isSaved, setIsSaved] = useState(false);

    const {request} = useHttp();
    const cadLinkRef = useRef();
    const item = props.item.lot;
    const location = props.item.lot?.location;
    const linkUrl = `/lot/${props.item.number}/`;

    const localizeStatus = (status) => {
        switch (status) {
            case "PENDING": return 'Подання пропозицій'
            case "AWAITING_START": return 'Очікування початку'
            case "STARTED": return 'Аукціон'
            case "AWAITING_PROTOCOL_SIGN": return 'Очікування підписання протоколу'
            case "AWAITING_CONTRACT_SIGN": return 'Очікування підписання договору'
            case "AWAITING_PAYMENT": return 'Очікування оплати'
            case "ENDED": return 'Завершено'
            case "NOT_OCCURRED": return 'Не відбулись'
            default: return status
        }
    }

    const saveHandler = async (e) => {
        e.preventDefault();
        let data;
        try {
            data = await request(config.baseUrl + `/api/v1/auctions/${props.item.id}/add-to-user-saved/`,'POST');
            if(data.status == 'OK') setIsSaved(true);
        } catch (e) {
            ErrorHandler(e)
        }
    }

    const removeHandler = async (e) => {
        e.preventDefault();
        let data;
        try {
            data = await request(config.baseUrl + `/api/v1/auctions/${props.item.id}/remove-from-user-saved/`,'POST');
            if(data.status == 'OK') setIsSaved(false);
        } catch (e) {
            ErrorHandler(e)
        }
    }

    useEffect(()=>{
        if(item.is_saved) setIsSaved(true);
    },[item.is_saved])

    return(
        <Fragment>
            <a target="_blank" style={{display:'none'}} ref={cadLinkRef} href={'http://map.land.gov.ua/kadastrova-karta?cadnum='+item.cadastral_number}></a>
        <Link to={linkUrl} className={s.Card}>
            <img alt="img" className={s.mainImg} src={config.baseUrl + item.photo_url}/>
            <div className={s.cardContentWrapper}>
                <div className={s.cardContent}>
                    <div className={s.left}>
                        <h5>Кадастровий номер:</h5>
                        <p className={s.cadastNum}
                           onClick={(e)=>{
                               e.preventDefault();
                               cadLinkRef.current.click()
                           }}
                        >
                            {item.cadastral_number}
                            <i className="fas fa-external-link-alt"></i>
                        </p>

                        <p className={s.description}>{item.description}</p>


                        <div className={s.descContent}>
                            <div className={s.type}>
                                <img alt="img" className={s.aimIcon} src={aim}/>
                                <p>{item.purpose.description}</p>
                            </div>
                            <div className={s.location}>
                                <img alt="img" className={s.locationIcon} src={locationIcon}/>
                                <p>{`${location.settlement?.name}, ${location.region?.name}, ${location.district?.name} район`}</p>
                            </div>


                        </div>
                    </div>
                    <div className={s.right}>
                       <div className={s.startingPrice}>
                           <p>Стартова ціна:</p>
                           <h4>{props.item.starting_price} грн</h4>
                           <p>Площа:</p>
                           <h4>{item.area} га</h4>
                       </div>
                    </div>
                </div>
                <div className={s.bottomBar}>
                    <p>Статус:<br/> <b>{localizeStatus(props.item.status)}</b></p>
                    <div className={s.textWrapper}><p>Завершується:<br/> <b>{props.item.date_start}</b></p></div>
                    <p>Номер аукціону:<br/> <b>{props.item.number}</b></p>
                    {!props.saved?
                        isSaved?
                            <div onClick={removeHandler} className={s.saveBtn}>
                                <i className="fas fa-star"/>
                                <p>Збережено</p>
                            </div>:
                            <div onClick={saveHandler} className={s.saveBtn}>
                                <i className="far fa-star"/>
                                <p>Зберегти</p>
                            </div>:
                        null}
                </div>
            </div>
        </Link>
        </Fragment>
    )
}

export default Card;