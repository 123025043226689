import React from 'react';
import s from './Contacts.module.css';

function Contacts(){
    return(
        <div className={s.Contacts}>
            <div className={s.content}>
                <h1>Наші контакти</h1>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10357.424635450383!2d25.6289626!3d49.5344297!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x94ac5b138219a947!2sNRG!5e0!3m2!1suk!2sua!4v1662967549504!5m2!1suk!2sua"
                    width="600" height="450" style={{"border":0}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                <div className={s.contactsBlock}>
                    <div className={s.contactsColumn}>
                        <h4>Адреса:</h4>
                        <b>Україна, 47722,</b>
                        <b>Тернопільська область,</b>
                        <b>Тернопільський район,</b>
                        <p>с.Великі Гаї, вул.Об’їзна, 3А</p>
                    </div>
                    <div>
                        aaaa
                    </div>
                    <div>
                        aaaa
                    </div>
                    <div>
                        aaaa
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts