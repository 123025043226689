import React, {useState, Fragment, useEffect, useRef, useContext} from 'react';
import s from './AuctionRoom.module.css';
import checkmarkDisabled from '../../assets/img/checkmark-disabled.png';
import checkmarkGreen from '../../assets/img/checkmark-green.png';
import clock from '../../assets/img/clock.png';
import radioBlue from '../../assets/img/radio-blue.png';
import radioGrey from '../../assets/img/radio-grey.png';
import radioYellow from '../../assets/img/radio-yellow.png';
import radioPurple from '../../assets/img/radio-purple.png';
import Timer from '../../components/Timer/Timer';
import warningIcon from '../../assets/img/warning.png';
import RoundSpinner from '../../assets/img/round-spinner.svg';
import Modal from "../../components/Modal/Modal";
import {useParams} from "react-router-dom";
import useWebSocket from 'react-use-websocket';
import config from "../../config/config";
import jwt from "jwt-decode";
import {AuthContext} from "../../context/AuthContext";
import {useHttp} from "../../hooks/HttpHook";
import {useHistory} from 'react-router-dom';
import Countdown, {zeroPad} from "react-countdown";
import Loading from "../../components/Loading/Loading";
import {Redirect} from 'react-router-dom'
import ErrorHandler from "../../utils/ErrorLoger";
function AuctionRoom() {
    const [nextStageCountdownTime, setNextStageCountdownTime] = useState(Date.now());
    const [currentStageStartedAt, setCurrentStageStartedAt] = useState();
    const [joinedGroup, setJoinedGroup] = useState('SPECTATORS');
    const [currentStage, setCurrentStage] = useState('AWAITING_START');
    const [nextMemberWillBidAt, setNextMemberWillBidAt] = useState(Date.now());
    const [initialBids, setInitialBids] = useState({});
    const [membersOrder, setMembersOrder] = useState([]);
    const [betModalOpen, setBetModalOpen] = useState(false);
    const [currentMemberBid, setCurrentMemberBid] = useState();
    const [websocketUrl, setWebsocketUrl] = useState(null);
    const [memberBidWillStartAt, setMemberBidWillStartAt] = useState(Date.now());
    const [userMemberId, setUserMemberId] = useState(0);
    const [modalError, setModalError] = useState();
    const [beforeHighestBid, setBeforeHighestBid] = useState(0);
    const [highestBid, setHighestBid] = useState(0);
    const [registrationList, setRegistrationList] = useState([]);
    const [currentMemberBidStartedAt, setCurrentMemberBidStartedAt] = useState(0);
    const [preferenceModal, setPreferenceModal] = useState(0);
    const [currentMemberResolvePreferenceStartedAt, setCurrentMemberResolvePreferenceStartedAt] = useState(Date.now());
    const [nextMemberWillResolvePreferenceAt, setNextMemberWillResolvePreferenceAt] = useState(Date.now()+60000);

    const [timetable, setTimetable] = useState([]);
    const [roundBids, setRoundBids] = useState({
        'ROUND_1' : {},
        'ROUND_2' : {},
        'ROUND_3' : {},
    });
    const stagesOrder = {
        'AWAITING_START' : 0,
        'STARTED' : 1,
        'ROUND_1' : 2,
        'BREAK_1' : 3,
        'ROUND_2' : 4,
        'BREAK_2' : 5,
        'ROUND_3' : 6,
        'RESOLVE_PREFERENCES' : 7,
        'ENDED' : 8,
    }
    const {request, loading} = useHttp();
    const history = useHistory();
    const countdownRef = useRef();
    const modalCountdownRef = useRef();
    const myBetCountdownRef = useRef();
    const myBetEndCountdownRef = useRef();
    const preferenceCountdown = useRef();
    const betInput = useRef();
    const auth = useContext(AuthContext);
    const lotNumber = useParams().params;
    const websocket = useWebSocket(websocketUrl,{
        onClose:(obj)=>{
            //console.log('ONCLOSE EVENT',obj)
            writeLogsToLocalStorage('CLOSING WEBSOCKET');
            if(obj.reason === 'auction_ended') history.push(`/results/${lotNumber}/`);
            if(obj.reason === 'auction_not_registred') history.push(`/lot/${lotNumber}/`);
            if(obj.reason === 'invalid_access_code') history.push(`/lot/${lotNumber}/`);
        },
        onOpen:(obj)=>{
            //console.log('ONOPEN EVENT',obj)
            writeLogsToLocalStorage('CONNECTED TO WEBSOCKET');
        },
        onError:(obj)=>{
            writeLogsToLocalStorage('ERROR');
            //console.log('ONERROR EVENT',obj)
        },
        onMessage:(obj)=>{
            socketMessageHandler(JSON.parse(obj.data));
        },
        shouldReconnect: ()=> true,
        reconnectAttempts: 5,
        reconnectInterval: 1000,

    });

    function writeLogsToLocalStorage(message){
        let logs = {};
        if(localStorage.getItem('logs')) logs = JSON.parse(localStorage.getItem('logs'));
        const currentTime = new Date();
        const timestamp = currentTime.toJSON();
        logs[timestamp] = message;
        localStorage.setItem('logs', JSON.stringify(logs));
    }

    function maxBet() {
        let maxBet = 0;
        Object.keys(initialBids).map((key)=>{
            if(+initialBids[key].amount>maxBet) maxBet = +initialBids[key].amount
        })
        Object.keys(roundBids).map((key)=>{
            Object.keys(roundBids[key]).map((bet)=>{
                if(+roundBids[key][bet].amount>maxBet) maxBet = roundBids[key][bet].amount
            })
        })
        return maxBet;
    }

    function makeBet(){
        const amount = +betInput.current.value;
        writeLogsToLocalStorage("SEND MESSAGE:")
        writeLogsToLocalStorage({"bid_amount": `${amount}`})
        websocket.sendMessage(JSON.stringify({"bid_amount": `${amount}`}));
        setModalError(null);
    }

    const stageText = () =>{
        switch (currentStage) {
            case "AWAITING_START": return <span>До початку<br/>аукціону:</span>;
            case "STARTED": return <span>До початку<br/>раунду 1:</span>;
            case "ROUND_1": return <span>До закінчення<br/>раунду 1:</span>;
            case "BREAK_1": return <span>До початку<br/>раунду 2:</span>;
            case "ROUND_2": return <span>До закінчення<br/>раунду 2:</span>;
            case "BREAK_2": return <span>До початку<br/>раунду 3:</span>;
            case "ROUND_3": return <span>До закінчення<br/>раунду 3:</span>;
            case "RESOLVE_PREFERENCES": return <span>До закінчення вибору<br/>переважного права</span>;
        }
    }


    useEffect(()=>{
        function compare( a, b ) {
            if ( +a.time < +b.time ){
                return -1;
            }
            if ( +a.time > +b.time ){
                return 1;
            }
            return 0;
        }

        try{
            request(`${config.baseUrl}/api/v1/auctions/${lotNumber}/`,'GET').then((data)=>{
                if (data && data.is_active_member) setWebsocketUrl(`${config.baseWsUrl}/ws/auctions/${data.id}/join/?token=${auth.token}&access_code=${data.member_access_code}`)
                else setWebsocketUrl(`${config.baseWsUrl}/ws/auctions/${data.id}/join/`);
                if(data && data.user_member_id) {
                    setUserMemberId(data.user_member_id)
                    if (data && data.active_members) {
                        const list = [];
                        Object.keys(data.active_members).map((key)=>{
                            list.push({time: data.active_members[key].registration_time, user: key})
                        })
                        list.sort(compare);
                        setRegistrationList(list);
                    }
                }});

        }catch(e){
            ErrorHandler(e)
        }
    },[])

    useEffect(() => {
        return () => {
            websocket.getWebSocket()?.close();
            console.log('CLOSING WEBSOCKET',websocket.getWebSocket());
        }
    }, []);


    function socketMessageHandler(message){
        console.log('MESSAGE', message);
        writeLogsToLocalStorage(message);
        if("joined_group" in message) setJoinedGroup(message.joined_group);
        if("members_bid_order" in message) setMembersOrder(message.members_bid_order);
        if("timetable" in message) setTimetable(message.timetable);

        if("next_member_will_bid_at" in message) {
            setNextMemberWillBidAt(+message.next_member_will_bid_at*1000);
            if(joinedGroup!='SPECTATORS') myBetEndCountdownRef.current.getApi().start();
            modalCountdownRef.current?.getApi().start();
        }
        if("member_bid_will_start_at" in message) {
            setMemberBidWillStartAt(+message.member_bid_will_start_at*1000);
            myBetCountdownRef.current?.getApi().start();
        }
        if("current_member_bid" in message) {
            setCurrentMemberBid(message.current_member_bid);
            if(message.current_member_bid===userMemberId) setBetModalOpen(true);
        }
        if("next_stage_will_start_at" in message) {
            setCurrentStageStartedAt(+message.current_stage_started_at*1000);
            setNextStageCountdownTime(+message.next_stage_will_start_at*1000);
            countdownRef.current.getApi().start();
        }
        //======================================================================================================
        if("current_stage" in message) {
            if(message.current_stage=="ENDED") history.push(`/results/${lotNumber}/`);
            setCurrentStage(message.current_stage);
        }

        if("initial_bids" in message) setInitialBids(message.initial_bids);
        if("round1_bids" in message) {
            roundBids.ROUND_1 = {...message.round1_bids, ...roundBids.ROUND_1};
        }
        if("round2_bids" in message) {
            roundBids.ROUND_2 = {...message.round2_bids, ...roundBids.ROUND_2};
        }
        if("round3_bids" in message) {
            roundBids.ROUND_3 = {...message.round3_bids, ...roundBids.ROUND_3};
        }
        if("last_member_bid" in message) {
            if(message.last_member_bid) setTimeout(()=>{
                roundBids[message.current_stage][userMemberId] = message.last_member_bid;
                setBetModalOpen(false);
            },0);
        }

        if("status" in message){
            if(message.status === "success" && message.response === "bid_accepted") {
                let oldBids = {...roundBids};
                let currentRound = oldBids[currentStage]
                currentRound[userMemberId] = {amount:message.amount, time:message.time}
                oldBids[currentStage] = {...currentRound}

                setBetModalOpen(false);

            }
            if(message.status === "error" && message.error_code === "bid_too_small") {
                setModalError('Ставка нижча ніж попередня');
                betInput.current.value = '';
            }

            if(message.status === "error" && message.error_code === "min_bid_step_required") {
                setModalError('Необхідний мінімальний крок ставки');
                betInput.current.value = '';
            }
        }
        if("current_member_resolve_preference" in message){
            //setCurrentMemberResolvePreference(message.current_member_resolve_preference);
            console.log('myID',userMemberId);
            console.log('socketId', message.current_member_resolve_preference)
            if(userMemberId == message.current_member_resolve_preference) setPreferenceModal(1);
            else setPreferenceModal(0);
        }

        if("highest_bid_amount" in message){
            setHighestBid(message.highest_bid_amount);
        }

        if("before_highest_bid_amount" in message){
            setBeforeHighestBid(message.before_highest_bid_amount);
        }

        if("current_member_bid_started_at" in message){
            setCurrentMemberBidStartedAt(message.current_member_bid_started_at);
        }

        if("next_member_will_resolve_preference_at" in message){
            setCurrentMemberResolvePreferenceStartedAt(+message.current_member_resolve_preference_started_at*1000);
            setNextMemberWillResolvePreferenceAt(+message.next_member_will_resolve_preference_at*1000);
            preferenceCountdown.current.getApi().start()
        }

        //next_member_will_resolve_preference_at
        // before_highest_bid_amount: "5235325.00"
        // current_member_resolve_preference: 57
        // current_member_resolve_preference_started_at: 1632119910
        // highest_bid_amount: "99999999.00"
        // next_member_will_resolve_preference_at: 1632120
    }


    function findIndex(memId) {
        return registrationList.map((el, id)=>{
            if(+el.user == memId) return id+1;
        })
    }

    const BetIcon = (props) => {
        let status;

        if(roundBids[props.round][props.id]?.amount) status = 'completed';
        else if(currentMemberBid == props.id && currentStage==props.round) status = 'active';
        else status = 'waiting';

        switch (status) {
            case 'active':
                return <Timer
                    hideCountdown={true}
                    text={''}
                    timeDelta={0}
                    timerEnd={nextMemberWillBidAt}
                    timerStart={+currentMemberBidStartedAt*1000}/>
            case 'waiting':
                return <img src={clock}/>
            case 'completed':
                return <img src={checkmarkGreen}/>

            default:
                return <img src={checkmarkDisabled}/>
        }
    }

    const RoundIcon = (props) => {

        if(stagesOrder[props.stage]>stagesOrder[currentStage]){
            return <img src={clock}/>
        }
        if(stagesOrder[props.stage]===stagesOrder[currentStage]){
            return <img className={s.roundSpinner} src={RoundSpinner}/>
        }
        if(stagesOrder[props.stage]<stagesOrder[currentStage]){
            return <img src={checkmarkGreen}/>
        }

        return <img src={clock}/>
    }

    return(
        <div className={s.Lot}>
            {/*{currentStage=='ENDED'?<Redirect to={`/results/${lotId}/`}/>:null}*/}
            {loading?<Loading/>:null}
            {joinedGroup!=='MEMBERS'?<div className={s.spectatorBox}>
                <img src={warningIcon}/>
                <p>Ви спостерігач і не можете робити ставки</p>
            </div>:null}
            <div className={s.left}>

                <h2 style={{position:'relative'}}><div className={s.leftBorder}></div>Хід аукціону</h2>
                <div className={s.auctionStage}>
                    <RoundIcon stage={'AWAITING_START'}/>
                    <p>Початок аукціону</p>
                    <p className={s.auctionStageTime}>{timetable['STARTED']?timetable['STARTED'].started_at + ' - ' + timetable['STARTED'].ended_at:null}</p>
                </div>
                <div className={s.auctionStage}>
                    <RoundIcon stage={'ROUND_1'}/>
                    <p>Раунд 1</p>
                    <p className={s.auctionStageTime}>{timetable['ROUND_1']?timetable['ROUND_1'].started_at + ' - ' + timetable['ROUND_1'].ended_at:null}</p>
                </div>
                <div className={s.auctionStage}>
                    <RoundIcon stage={'ROUND_2'}/>
                    <p>Раунд 2</p>
                    <p className={s.auctionStageTime}>{timetable['ROUND_2']?timetable['ROUND_2'].started_at + ' - ' + timetable['ROUND_2'].ended_at:null}</p>
                </div>
                <div className={s.auctionStage}>
                    <RoundIcon stage={'ROUND_3'}/>
                    <p>Раунд 3</p>
                    <p className={s.auctionStageTime}>{timetable['ROUND_3']?timetable['ROUND_3'].started_at + ' - ' + timetable['ROUND_3'].ended_at:null}</p>
                </div>
                <div className={s.auctionStage}>
                    <RoundIcon stage={'RESOLVE_PREFERENCES'}/>
                    <p>Оголошення результатів</p>
                    <p className={s.auctionStageTime}>{timetable['ENDED']?timetable['ENDED'].started_at:null}</p>
                </div>
            </div>
            <div className={s.middle}>
                <h2 className={s.middleTitle}>Початкові заявки</h2>
                <div className={s.bets}>
                    {Object.keys(initialBids).map((memberId, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betCheckmark}>
                                <img src={checkmarkDisabled}/>
                            </div>
                            <div className={s.betOwner}>
                                <p>{memberId==userMemberId?<p className={s.yourBet}>Учасник {findIndex(memberId)}<b> (Ви)</b></p>:<p>Учасник {findIndex(memberId)}</p>}</p>
                            </div>
                            <div className={s.betTime}>
                                <p>{initialBids[memberId]?initialBids[memberId].time:null}</p>
                            </div>
                            <div className={s.betAmount}>
                                <p>{initialBids[memberId]?initialBids[memberId].amount+' грн':null}</p>
                            </div>
                        </div>
                    })}
                </div>

                <h2 className={s.middleTitle}>Раунд 1</h2>
                <div className={s.bets}>
                    {membersOrder['ROUND_1'] && membersOrder['ROUND_1'].map((memberId, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betCheckmark}>
                                <BetIcon id={memberId} round='ROUND_1' />
                            </div>
                            <div className={s.betOwner}>
                                <p>{memberId==userMemberId?<p className={s.yourBet}>Учасник {findIndex(memberId)}<b> (Ви)</b></p>:<p>Учасник {findIndex(memberId)}</p>}</p>
                            </div>
                            <div className={s.betTime}>
                                <p>{roundBids['ROUND_1'][memberId]?roundBids['ROUND_1'][memberId].time:null}</p>
                            </div>
                            <div className={s.betAmount}>
                                <p>{roundBids['ROUND_1'][memberId]?roundBids['ROUND_1'][memberId].amount+' грн':null}</p>
                            </div>
                        </div>
                    })}
                </div>



                <h2 className={s.middleTitle}>Раунд 2</h2>
                <div className={s.bets}>
                    {membersOrder['ROUND_2'] && membersOrder['ROUND_2'].map((memberId, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betCheckmark}>
                                <BetIcon id={memberId} round='ROUND_2' />
                            </div>
                            <div className={s.betOwner}>
                                <p>{memberId==userMemberId?<p className={s.yourBet}>Учасник {findIndex(memberId)}<b> (Ви)</b></p>:<p>Учасник {findIndex(memberId)}</p>}</p>
                            </div>
                            <div className={s.betTime}>
                                <p>{roundBids['ROUND_2'][memberId]?roundBids['ROUND_2'][memberId].time:null}</p>
                            </div>
                            <div className={s.betAmount}>
                                <p>{roundBids['ROUND_2'][memberId]?roundBids['ROUND_2'][memberId].amount+' грн':null}</p>
                            </div>
                        </div>
                    })}
                </div>

                <h2 className={s.middleTitle}>Раунд 3</h2>
                <div className={s.bets}>
                    {membersOrder['ROUND_3'] && membersOrder['ROUND_3'].map((memberId, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betCheckmark}>
                                <BetIcon id={memberId} round='ROUND_3' />
                            </div>
                            <div className={s.betOwner}>
                                <p>{memberId==userMemberId?<p className={s.yourBet}>Учасник {findIndex(memberId)}<b> (Ви)</b></p>:<p>Учасник {findIndex(memberId)}</p>}</p>
                            </div>
                            <div className={s.betTime}>
                                <p>{roundBids['ROUND_3'][memberId]?roundBids['ROUND_3'][memberId].time:null}</p>
                            </div>
                            <div className={s.betAmount}>
                                <p>{roundBids['ROUND_3'][memberId]?roundBids['ROUND_3'][memberId].amount+' грн':null}</p>
                            </div>
                        </div>
                    })}
                </div>

            </div>
            <div className={s.right}>
                <h2>Час та ставки</h2>
                <div className={s.timer}>
                    <Timer reverse={true} reference={countdownRef} timeDelta={0} text={stageText()} timerStart={currentStageStartedAt} timerEnd={nextStageCountdownTime}/>
                </div>
                {maxBet()?<div className={s.highestBet}>
                    <p>Найвища ставка:</p>
                    <h3>{maxBet()} грн</h3>
                </div>:null}
                {joinedGroup!=='SPECTATORS'?<Fragment>
                    <h2>Статус</h2>
                    <div className={s.statusItem}>
                        <img src={userMemberId!==currentMemberBid || (currentStage == 'BREAK_1'|| currentStage == 'BREAK_2' || currentStage == 'BREAK_3') && currentStage !== 'RESOLVE_PREFERENCES'?radioYellow:radioGrey}/>
                        <p>В очікуванні</p>
                    </div>
                    <div style={userMemberId!==currentMemberBid && (currentStage == 'ROUND_1'|| currentStage == 'ROUND_2' || currentStage == 'ROUND_3') && !roundBids[currentStage][userMemberId]?{display:"block"}:{display:"none"}} className={s.highestBet}>
                        <p>До вашого кроку залишилось:</p>
                        <h3><Countdown ref={myBetCountdownRef} daysInHours={true} date={memberBidWillStartAt}/></h3>
                    </div>
                    <div className={s.statusItem}>
                        <img src={userMemberId===currentMemberBid && !(currentStage == 'BREAK_1'|| currentStage == 'BREAK_2' || currentStage == 'BREAK_3')?radioBlue:radioGrey}/>
                        <p>Зараз ваш хід <span style={userMemberId===currentMemberBid && !(currentStage == 'BREAK_1'|| currentStage == 'BREAK_2' || currentStage == 'BREAK_3')?{display:"block"}:{display:"none"}} ><Countdown ref={myBetEndCountdownRef} daysInHours={true} date={nextMemberWillBidAt}/></span></p>
                    </div>
                    {userMemberId===currentMemberBid && !(currentStage == 'BREAK_1'|| currentStage == 'BREAK_2' || currentStage == 'BREAK_3')?
                        <div className={s.betAccepted}>
                            {(currentStage == 'ROUND_1'|| currentStage == 'ROUND_2' || currentStage == 'ROUND_3') && roundBids[currentStage][userMemberId]?<div className={s.betAcceptedContent}>
                                <h5>Ваша ставка прийнята</h5>
                                <p>Дочекайтесь своєї черги для наступного раунду.</p>
                            </div>:null}
                            <button onClick={()=>{setBetModalOpen(true)}} className={s.changeBetButton}>{(currentStage == 'ROUND_1'|| currentStage == 'ROUND_2' || currentStage == 'ROUND_3') && roundBids[currentStage][userMemberId]?'Змінити ставку':'Зробити ставку'}</button>
                        </div>:null}
                    <div className={s.statusItem}>
                        {currentStage=='RESOLVE_PREFERENCES'?<img src={radioPurple}/>:<img src={radioGrey}/>}
                        <p>Очікуйте, йдуть торги для суб'єктів переважного права</p>
                    </div>
                </Fragment>:null}
            </div>
            <Modal isVisible={betModalOpen} onClose={()=>{setBetModalOpen(false)}}>
                <div className={s.betModal}>
                    <h1 className={s.betModalTitle}>Зробіть ставку</h1>
                    <p>Найвища ставка: {maxBet()} грн.</p>
                    <div className={s.betModalTimer}>
                        <Timer reverse={true} reference={modalCountdownRef} timeDelta={0} text={'Ваш хід:'} onComplete={()=>{
                            setBetModalOpen(false);
                            setModalError(null);
                        }} timerStart={+currentMemberBidStartedAt*1000} timerEnd={nextMemberWillBidAt}/>
                    </div>
                    {modalError?<p className={s.modalError}>{modalError}</p>:null}
                    <label>Ваша ставка (грн.)</label>
                    <input
                        onChange={(e)=>{e.target.value = e.target.value.replace(/[^0-9.]/g, '')}}
                        ref={betInput}/>
                    <div className={s.betModalButtons}>
                        <button onClick={()=>{setBetModalOpen(false)}} className={s.betModalRejectBut}>Відхилити</button>
                        <button onClick={()=>{makeBet()}} className={s.betModalBetBut}>Зробити ставку</button>
                    </div>
                </div>
            </Modal>
            <Modal isVisible={preferenceModal} onClose={()=>{}}>
                <div className={s.preferenceModal}>
                    <div className={s.preferenceTimer}>
                        <Timer reverse={true} reference={preferenceCountdown} timeDelta={0} text={''} onComplete={()=>{
                            setPreferenceModal(0);
                        }} timerStart={currentMemberResolvePreferenceStartedAt} timerEnd={nextMemberWillResolvePreferenceAt}/>
                    </div>
                    <h2>Чи погоджуєтесь Ви купити цей лот за:</h2>
                    <h1>{preferenceModal==1?highestBid:beforeHighestBid} грн</h1>
                    <div className={s.betModalButtons}>
                        <button onClick={()=>{
                            if(preferenceModal===1) {
                                websocket.sendMessage(JSON.stringify({"highest_bid_accepted": false}));
                                writeLogsToLocalStorage("SEND MESSAGE:")
                                writeLogsToLocalStorage({"highest_bid_accepted": false})
                                setPreferenceModal(2);
                            } else if(preferenceModal===2) {
                                websocket.sendMessage(JSON.stringify({"before_highest_bid_accepted": false}));
                                writeLogsToLocalStorage("SEND MESSAGE:")
                                writeLogsToLocalStorage({"before_highest_bid_accepted": false})
                                setPreferenceModal(null);
                            }
                        }} className={s.betModalRejectBut}>Відмовляюсь</button>

                        <button onClick={()=>{
                            if(preferenceModal===1) {
                                websocket.sendMessage(JSON.stringify({"highest_bid_accepted": true}));
                                writeLogsToLocalStorage("SEND MESSAGE:")
                                writeLogsToLocalStorage({"highest_bid_accepted": true})
                                setPreferenceModal(2);
                            } else if(preferenceModal===2) {
                                websocket.sendMessage(JSON.stringify({"before_highest_bid_accepted": true}));
                                writeLogsToLocalStorage("SEND MESSAGE:")
                                writeLogsToLocalStorage({"before_highest_bid_accepted": true})
                                setPreferenceModal(null);
                            }
                        }} className={s.betModalBetBut}>Погоджуюсь</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AuctionRoom;