import React, {useEffect, useState} from "react";
import s from "./Support.module.css";
import {useHttp} from "../../hooks/HttpHook";
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
import QuestionsList from "../../components/Questions/List";
import AddQuestion from "../../components/Questions/AddQuestion";
import Chat from "../../components/Questions/Chat";
import CabinetSideBar from "../../components/CabinetSideBar/CabinetSideBar";

export default function Support() {
    const [currentStage, setCurrentStage] = useState('list');
    const [chatList, setChatList] = useState([]);
    const [currentChatId, setCurrentChatId] = useState(null);
    const {request} = useHttp();
    function getData(){
        try{
            request(`${config.baseUrl}/api/v1/chat/user-chat`,'GET').then((data)=>{
                if(data) {
                    setChatList(data);
                }
            });
        }catch(e){
            ErrorHandler(e)
        }
    }

    const CurrentStage = () => {
        switch (currentStage) {
            case "list": return <QuestionsList
                onStageChange={setCurrentStage}

                onCurrentChatChange={setCurrentChatId}
                chatList={chatList}
            />
            case "create": return <AddQuestion
                onStageChange={setCurrentStage}
                requestUrl={`${config.baseUrl}/api/v1/chat/user-chat`}
            />
            case "chat": return <Chat
                onStageChange={setCurrentStage}
                chatId={currentChatId}
                lotId={lotId}
            />
            case "success": return <div>
                Питання успішно задано<br/>
                <button style={{width:'150px',marginTop:"20px"}} className={s.blueButton} onClick={()=>{getData();setCurrentStage("list")}}>ок</button>
            </div>

            default: return <div></div>
        }
    }

    useEffect(()=>{
        getData();
    },[])

    return(
        <CabinetSideBar>
            <div className={s.Support}>
                <div className={s.content}>
                    <h2 className={s.title}>Запитання по аукціону</h2>
                    <CurrentStage/>
                </div>
            </div>
        </CabinetSideBar>
    )
}