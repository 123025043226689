import React, {Suspense, lazy} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
// const Home = lazy(() => import('./pages/Home/Home'));
// const Lot = lazy(() => import('./pages/Lot/NewLot'));
// const Profile = lazy(() => import('./pages/Profile/Profile'));
// const Register = lazy(() => import('./pages/Register/RegisterRedesign'));
// const Confirm = lazy(() => import('./pages/Confirm/Confirm'));
// const Map = lazy(() => import('./pages/Map/Map'));
// const Login = lazy(() => import('./pages/Login/Login'));
// const Documents = lazy(() => import('./pages/Documents/Documents'));
// const Notifications = lazy(() => import('./pages/Notifications/Notifications'));
// const MyLots = lazy(() => import('./pages/MyLots/MyLots'));
// const EditLot = lazy(() => import('./pages/EditLot/EditLotRedesign'));
// const Invoices = lazy(() => import('./pages/Invoices/Invoices'));
// const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));

import Protocols from "./pages/Protocols/Protocols";
import AuctionRoom from "./pages/AuctionRoom/AuctionRoom";
import AuctionResults from "./pages/AuctionResults/AuctionResults";
import CabinetSideBar from "./components/CabinetSideBar/CabinetSideBar";
import Applications from "./pages/Applications/Applications";
import EditForm from "./pages/EditForm/EditForm";
import AddLotRedesign from "./pages/AddLot/NewAddLot";
import Contracts from "./pages/Contracts/Contracts";
import OrganizerLot from "./pages/OrganizerLot/OrganizerLot";
import Support from "./pages/Support/Support";

import EditLot from "./pages/EditLot/EditLotRedesign";
import Invoices from "./pages/Invoices/Invoices";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Home from './pages/Home/Home';
import Lot from './pages/Lot/NewLot';
import Profile from './pages/Profile/Profile';
import Register from './pages/Register/RegisterRedesign';
import Login from './pages/Login/Login';
import Restore from './pages/Restore/Restore';
import Confirm from './pages/Confirm/Confirm';
import Map from "./pages/Map/Map";
import Documents from "./pages/Documents/Documents";
import Notifications from "./pages/Notifications/Notifications";
import MyLots from "./pages/MyLots/MyLots";
import News from "./pages/News/News";
import Questions from "./pages/Questions/Questions";
import Instructions from "./pages/Instructions/Instructions";
import Tarifs from "./pages/Tarifs/Tarifs";
import Contacts from "./pages/Contacts/Contacts";

export const useRoutes = (scope, renderHeader) =>{


    const DefaultRedirectRoute = () => {
        const redirectPath = {
            complete_registration:"/register/",
            confirm_phone_number:"/confirm/",
            sms_reset_password:"/reset/",
            verify_sms_code:"/confirm/",
            main:"/auction/"
        }
        return <Redirect to={redirectPath[scope] || '/auction/'}/>
    }

    const ProtectedRoute = ({path, permissionScopes, children}) => {
        if(permissionScopes.includes(scope)){
            return(
                <Route path={path}>
                    {children}
                </Route>
            )
        } else {
          return   <DefaultRedirectRoute/>
        }
    }

    if(scope)
    return (
        <Switch>
            <Route exact path="/auction/">
                <Home/>
            </Route>
            <Route exact path="/register/">
                <Register renderHeader={renderHeader}/>
            </Route>
            <Route exact path="/login/">
                <Login renderHeader={renderHeader}/>
            </Route>
            <Route exact path="/restore/">
                <Restore/>
            </Route>
            <Route exact path="/lot/:number/">
                <Lot/>
            </Route>
            <Route exact path="/room/:params/:access_code/">
                <AuctionRoom/>
            </Route>
            <Route exact path="/room/:params/">
                <AuctionRoom/>
            </Route>
            <Route exact path="/results/:params/">
                <AuctionResults/>
            </Route>
            <Route exact path="/saved/">
                <Home/>
            </Route>
            <Route exact path="/filters/">
                <Home/>
            </Route>
            <Route exact path="/map/">
                <Map/>
            </Route>
            <Route exact path="/news/">
                <News/>
            </Route>
            <Route exact path="/questions/">
                <Questions/>
            </Route>
            <Route exact path="/tarifs/">
                <Tarifs/>
            </Route>
            <Route exact path="/contacts/">
                <Contacts/>
            </Route>
            <Route exact path="/instructions/">
                <Instructions/>
            </Route>
            <ProtectedRoute path="/profile/" permissionScopes={["main"]}>
                <Profile/>
            </ProtectedRoute>
            <ProtectedRoute path="/form/edit/:params/" permissionScopes={["main"]}>
                <EditForm/>
            </ProtectedRoute>
            <ProtectedRoute path="/create/lot/" permissionScopes={["main"]}>
                <AddLotRedesign/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/documents/" permissionScopes={["main"]}>
                <Documents/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/applications/" permissionScopes={["main"]}>
                <Applications/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/invoices/" permissionScopes={["main"]}>
                <Invoices/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/protocols/" permissionScopes={["main"]}>
                <Protocols/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/contracts/" permissionScopes={["main"]}>
                <Contracts/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/notifications/" permissionScopes={["main"]}>
                <Notifications renderHeader={renderHeader}/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/mylots/saved/" permissionScopes={["main"]}>
                <MyLots/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/mylots/auction/" permissionScopes={["main"]}>
                <MyLots/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/mylots/auction/:number/" permissionScopes={["main"]}>
                <OrganizerLot/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/support/" permissionScopes={["main"]}>
                <Support/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/edit/:number/" permissionScopes={["main"]}>
                <EditLot/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/reset/" permissionScopes={["sms_reset_password"]}>
                <ResetPassword/>
            </ProtectedRoute>
            <ProtectedRoute path="/confirm/" permissionScopes={["verify_sms_code","sms_reset_password","confirm_phone_number"]}>
                <Confirm/>
            </ProtectedRoute>
            <DefaultRedirectRoute/>
        </Switch>
    )


}
