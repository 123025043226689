import {createContext} from 'react';

function noop() {}

export const UserInfoContext = createContext({
    userInfo: {},
    notifications: {},
    newNotifications: {},
    profileId: null,
    getUserInfo: noop,
    getNotifications: noop,
    getNewNotifications: noop,
});
