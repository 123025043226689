import React, {Fragment, useEffect, useRef, useState} from 'react';
import Countdown from 'react-countdown';
import s from "../Lot/Lot.module.css";
import {InputItem, ItemRow} from "../../components/UI/UI";
import FileSign from "../../components/FileSign/FileSignCopy";
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import LegalCabinet from "../Cabinet/LegalCabinet";
import Cabinet from "../Cabinet/Cabinet";
import IndividualCabinet from "../Cabinet/IndividualCabinet";
import CabinetSideBar from "../../components/CabinetSideBar/CabinetSideBar";
import EnterpreneurCabinet from "../Cabinet/EnterpreneurCabinet";
function Profile(){
    const [userInfo, setUserInfo] = useState({});
    const {request} = useHttp();
    useEffect(async ()=>{
        const info = await request(config.baseUrl + `/api/v1/user/info/`,'GET');
        setUserInfo(info)
    },[])
    return(
        <CabinetSideBar>
            {userInfo?.legal_entity_profile_id && <LegalCabinet/>}
            {userInfo?.individual_profile_id && <IndividualCabinet/>}
            {userInfo?.individual_enterpreneur_profile_id && <EnterpreneurCabinet/>}
        </CabinetSideBar>
    )
}

export default Profile;























// import React, {useEffect, useRef, useState} from 'react';
// import Countdown from 'react-countdown';
// function Profile(){
//     const [isAddTime, setIsAddTime] = useState(false);
//     const addCounterRef = useRef();
//     const [counterDate, setCounterDate] = useState(Date.now()+10000);
//     const [counterAddTime, setCounterAddTime] = useState(Date.now()+15000);
//     return(
//         <div>
//             {isAddTime?<Countdown
//                     ref={addCounterRef}
//                     onComplete={()=>{alert('Вилетів')}}
//                     date={counterAddTime}/>:
//                 <Countdown
//                     onComplete={()=>{
//                         setIsAddTime(true);
//                         setTimeout(()=>{
//                             addCounterRef.current.getApi().start()
//                         },0)}
//                     }
//                     date={counterDate}/>}
//
//             <button onClick={()=>{
//                 setCounterDate(Date.now()+10000);
//                 setCounterAddTime(Date.now()+15000);
//                 setIsAddTime(false)
//             }}>Clear timers</button>
//         </div>
//     )
// }
//
// export default Profile;