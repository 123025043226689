module.exports = {
    PAYMENT_PURPOSES:{
        MEMBER:[
            { name: "Для повернення гарантійного внеску", id: "GUARANTEE_FEE_REFUND" },
            { name: "Для повернення реєстраційного внеску", id: "REGISTRATION_FEE_REFUND" },
        ],
        ORGANIZER:[
            { name: "Для зарахування оплати за лот", id: "LOT_PAYMENT" },
            { name: "Для зарахування орендної плати від фізичних осіб", id: "RENT_PAYMENT_FOR_INDIVIDUALS" },
            { name: "Для зарахування орендної плати від юридичних осіб", id: "RENT_PAYMENT_FOR_LEGAL_ENTITIES" },
            { name: "Для зарахування витрат на підготовку лота", id: "LOT_PREPARATION_EXPENSES_PAYMENT" },
        ],
        ALL:[
            { name: "Для зарахування всіх видів платежів", id: "ALL_PAYMENTS" },
        ]
    },

    CURRENCY:[
        { name: "UAH", id: "UAH" }
    ],

    DOCUMENT_TYPES:[
        { name: "паспорт громадянина України", id: "PASSPORT" },
        { name: "паспорт громадянина України у формі ID картки", id: "PASSPORT_AS_CARD" },
        { name: "посвідка на тимчасове проживання", id: "TEMPORARY_RESIDENCE_PERMIT" },
        { name: "посвідка на постійне проживання", id: "PERMANENT_RESIDENCE_PERMIT" },
        { name: "інший документ", id: "ANOTHER_DOCUMENT" },
    ],
    CITIZENSHIP:[
        { name: "Україна", id: 'Ukraine' },
    ]
}