import React, {useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import Loading from "../../Loading/Loading";
import s from "../Participation.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import InputMask from "react-input-mask";
import {InputItem, InputItemShort, ItemRow} from "../../UI/UI";
import {Field, useFormikContext} from "formik";
import {CheckboxField, SelectField, TextField} from "../../FormikFields/FormikFields";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";


export default function Location() {
    const {values} = useFormikContext();
    const {nextStage} = useContext(ParticipationFormContext)
    return(
        <>
        <fieldset disabled={true}>
            <InputItemShort label="Країна">
                <Field
                    name="country"
                    url="/api/v1/location/countries/"
                    component={SelectField}
                    customId="code"
                    disabled={true}
                    checkDefaultById
                />
            </InputItemShort>

            <InputItemShort label="Поштовий індекс">
                <Field
                    name="postal_code"
                    component={TextField}
                />
            </InputItemShort>

            <ItemRow>
                <InputItem label="Область">
                    <Field
                        name="region"
                        url="/api/v1/location/regions/"
                        component={SelectField}
                        disabled={true}
                        checkDefaultById
                    />
                </InputItem>

                <InputItem label="Район">
                    <Field
                        name="district"
                        url="/api/v1/location/districts/"
                        component={SelectField}
                        disabled={true}
                        checkDefaultById
                    />
                </InputItem>
            </ItemRow>

            <InputItemShort label="Населений пункт">
                <Field
                    name="settlement"
                    url="/api/v1/location/settlements/"
                    component={SelectField}
                    disabled={true}
                    checkDefaultById
                />
            </InputItemShort>

            <InputItemShort label="Вулиця">
                <Field
                    name="street"
                    component={TextField}
                />
            </InputItemShort>

            <ItemRow>
                <InputItem label="Номер будинку">
                    <Field
                        name="building_number"
                        component={TextField}
                    />
                </InputItem>

                <InputItem label="Корпус">
                    <Field
                        name="building_block_number"
                        component={TextField}
                    />
                </InputItem>
            </ItemRow>

            <InputItem label="Номер квартири/офісу">
                <Field
                    name="apartment_or_office_number"
                    component={TextField}
                />
            </InputItem>

            <InputItem label="Email">
                <Field
                    name="email"
                    component={TextField}
                />
            </InputItem>

            <InputItem label="Номер телефону">
                <Field
                    name="phone_number"
                    component={TextField}
                />
            </InputItem>

            <Field name="legal_entity_address_and_postal_address_is_different" component={CheckboxField}>
                Юридична адреса та адреса для листування юридичної особи відрізняються
            </Field>

            {values.legal_entity_address_and_postal_address_is_different &&
                <>
                    <InputItemShort label="Країна">
                        <Field
                            name="postal_address_country"
                            url="/api/v1/location/countries/"
                            component={SelectField}
                            customId="code"
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItemShort>

                    <InputItemShort label="Поштовий індекс">
                        <Field
                            name="postal_address_postal_code"
                            component={TextField}
                        />
                    </InputItemShort>

                    <ItemRow>
                        <InputItem label="Область">
                            <Field
                                name="postal_address_region"
                                url="/api/v1/location/regions/"
                                component={SelectField}
                                disabled={true}
                                checkDefaultById
                            />
                        </InputItem>
                        
                        <InputItem label="Район">
                            <Field
                                name="postal_address_district"
                                url="/api/v1/location/districts/"
                                component={SelectField}
                                disabled={true}
                                checkDefaultById
                            />
                        </InputItem>
                    </ItemRow>
                    

                    <InputItemShort label="Населений пункт">
                        <Field
                            name="postal_address_settlement"
                            url="/api/v1/location/settlements/"
                            component={SelectField}
                            disabled={true}
                            checkDefaultById
                        />
                    </InputItemShort>

                    <InputItemShort label="Вулиця">
                        <Field
                            name="postal_address_street"
                            component={TextField}
                        />
                    </InputItemShort>

                    <ItemRow>
                        <InputItem label="Номер будинку">
                            <Field
                                name="postal_address_building_number"
                                component={TextField}
                            />
                        </InputItem>

                        <InputItem label="Корпус">
                            <Field
                                name="postal_address_building_block_number"
                                component={TextField}
                            />
                        </InputItem>
                    </ItemRow>

                    <InputItem label="Номер квартири/офісу">
                        <Field
                            name="postal_address_apartment_or_office_number"
                            component={TextField}
                        />
                    </InputItem>
                </>
            }

        </fieldset>
            <button
                className={s.nextStageButton}
                onClick={nextStage}
            >Далі</button>
        </>
    )
}