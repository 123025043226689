import React, {useEffect, useState} from 'react';
import s from './Questions.module.css';
import {Title} from "../../components/UI/UI";
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
import {useHttp} from "../../hooks/HttpHook";
import Loading from "../../components/Loading/Loading";
function Questions(){
    const [data, setData] = useState(null);
    const {request} = useHttp()
    const Block = ({children, collapsedComponent}) => {
        const [isCollapsed, setCollapsed] = useState(true)
        return(
            <div className={s.block}>
                <p type="button" className={s.collapseButton} onClick={setCollapsed.bind(this, !isCollapsed)}>
                    {isCollapsed?'+':'-'}
                </p>
                {
                    isCollapsed?
                        collapsedComponent:
                        children
                }
            </div>
        )
    }

    async function getData(){
        try{
            let response = await request(config.baseUrl + `/api/v1/information/question-answers/`,'GET');
            setData(response)
        }catch(e){
            ErrorHandler(e)
        }
    }

    useEffect(()=>{
        getData()
    },[])

    return(
        <div className={s.Questions}>
            {!data ?
                <Loading/>:
                <div className={s.content}>
                    <h1 className={s.title}>Запитання і відповіді</h1>
                    {data.map((el)=>
                        <Block collapsedComponent={
                            <>
                                <Title style={{marginBottom:0, paddingRight:'50px'}}>{el.topic}</Title>
                            </>
                        }>
                            <Title style={{paddingRight:'50px'}}>{el.topic}</Title>
                            <p>{el.answer_text}</p>
                        </Block>
                    )}
                </div>
            }
        </div>
    )
}

export default Questions;