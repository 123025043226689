import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react';
import s from './FileSign.module.css';
import fileImg from '../../assets/img/document-icon.png';
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import loading from '../../assets/img/loading.svg';
import ErrorHandler from "../../utils/ErrorLoger";
import {PlusButton} from "../UI/UI";
import {AuthContext} from "../../context/AuthContext";

//TODO Finish new FileSign component
function FileSign({
                      disabled,
                      multiple = false,
                      documentType,
                      onSelect,
                      defaultDocument,
                      style,
                      className,
                      title,
                      description,
                      url,
                      uploadMethod = 'base64'
                  }) {

    const inputRef = useRef();
    const auth = useContext(AuthContext)
    const [documents, setDocuments] = useState([]);
    const {request} = useHttp();
    function getBase64(file) {
        const reader = new FileReader()
        const fileExtension = file.name.split('.').pop();
        const fileName = file.name.split('.')[0];
        return new Promise(resolve => {
            reader.onload = ev => {
                resolve({
                    "file_extension": fileExtension,
                    "encoded_file": ev.target.result,
                    "filename": fileName,
                    "document_type": documentType
                })
            }
            reader.readAsDataURL(file)
        })
    }

    // const deleteHandler = async () => {
    //     try{
    //         let data = await request(config.baseUrl + `/api/v1/user/documents/${fileId}/delete/`, 'DELETE');
    //         if(data.status == 'OK') {
    //             setDocument(null);
    //             setFileId(null);
    //             props.onSelect(null);
    //         }
    //     }catch (e) {
    //         ErrorHandler(e)
    //     }
    // }

    const deleteFile = async (document) => {
        if(uploadMethod === 'base64') {
            try{
                request(config.baseUrl + `/api/v1/user/documents/${document.id}/delete/`, 'DELETE')
                    .then((response)=>{
                        if(response.status == 'OK') {
                            setDocuments(documents.filter((el)=>el.id !== document.id))
                        }
                    })
            }catch (e) {
                ErrorHandler(e)
            }
        }
        if(uploadMethod === 'formData') {
            setDocuments(documents.filter((el)=>el.id !== document.id))
        }
    }


    const uploadFile = (file) => {
        if(uploadMethod === 'base64') return getBase64(file)
                .then((convertedFile)=>{
                    return new Promise((res, rej)=>{
                        try{
                            request(config.baseUrl + '/api/v1/user/documents/upload/','POST', convertedFile)
                                .then((data)=>res({data, convertedFile}))
                        }catch (e) {
                            ErrorHandler(e)
                            rej(e)
                        }
                    })
                })
                .then((response)=>{
                    if(response.data.file_id) {
                        //setFileIds([...fileIds, response.data.file_id]);
                        return {...response.convertedFile, id: response.data.file_id}
                    }
                }).catch((e)=>console.log(e))
        else if(uploadMethod === 'formData') {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${auth.token}`);

            const formdata = new FormData();
            formdata.append("file", file, );

            const requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            return fetch(config.baseUrl + url, requestOptions)

        }
    }

    const documentsHandler = async (fileList) => {

        const newDocuments = []
        for(let i = 0; i<fileList.length; i++){

            newDocuments.push({
                id:null,
                filename:fileList[i].name,
                loading:true
            })
        }
        setDocuments([...documents, ...newDocuments]);

        const promises = [];
        const updatedDocuments = [...newDocuments];

        for(let i = 0; i<fileList.length; i++){
            promises.push(uploadFile(fileList[i]).then(res=>res.json()))

        }

        Promise.all(promises).then(list=>{
            list.forEach((file, index)=>{
                updatedDocuments[index].id = file.id;
                updatedDocuments[index].loading = false;
            })

            setDocuments(updatedDocuments)

            if(multiple) onSelect(updatedDocuments.map(el=>el.id), updatedDocuments);
            else onSelect(updatedDocuments[0].id, updatedDocuments);

        })



        inputRef.current.value = '';
        // if(fileList.length){
        //     setLoading(true);
        //     const documentObj = await getBase64(fileList[0]);
        //     setDocument(documentObj);
        //     let data;
        //     try{
        //         data = await request(config.baseUrl + '/api/v1/user/documents/upload/','POST', documentObj);
        //
        //         if(data.file_id) {
        //             setFileId(data.file_id);
        //             onSelect(data.file_id);
        //         }
        //     }catch(e){
        //         ErrorHandler(e)
        //     }
        //     setLoading(false);
        // }
    }

    const getFileUrlFromId =(id) => {
        if(uploadMethod === 'formData'){
            try{

                request(`${config.baseUrl}${url}${id}/`, 'GET')
                    .then((response)=>{
                        if(response.file)
                        window.open(config.baseUrl + response.file, '_blank').focus();
                    })
            }catch (e) {
                ErrorHandler(e)
            }
        }
        return ""
    }


    function UploadedFile({document}){

        return (
            <div className={s.uploadedFile}>
                <div className={s.uploadedFileTitle}>
                    <img src={document.loading?loading:fileImg}/>
                    <p onClick={()=>getFileUrlFromId(document.id)} style={{wordBreak:'break-all'}}>{document?(document.file_extension?document.filename+'.'+document.file_extension:document.filename):null}</p>
                </div>
                <i className="fas fa-times" onClick={deleteFile.bind(this, document)}/>
            </div>
        )
    }

    useEffect(()=>{
        if(defaultDocument) {
            setDocuments([defaultDocument]);
           // setFileId(defaultDocument.id)
        }
    },[defaultDocument])

    return(
        <>
            <div style={style} className={disabled?[className,s.inputDisabled].join(' '):className}>
                {documents.map((file, index)=><UploadedFile key={index} document={file}/>)}
                <input
                    ref={inputRef}
                    style={{display:'none'}}
                    onChange={
                        (e) => documentsHandler(e.target.files)
                    }
                    type="file"
                    accept=".jpg, .jpeg, .pdf"
                    multiple={multiple}
                />
                <PlusButton
                    onClick={()=>inputRef.current.click()}
                    color='green'
                >
                    {title}
                </PlusButton>
                <p className={s.fileUploadDescription}>{description}</p>
            </div>

        </>

    )
}

/*
USAGE

        <FileSign
            documentType='TAX_NUMBER'
            onSelect={(id)=>{console.log(id)}}
            className={s.fileSign}
            description="РНОКПП- Реєстраційний номер облікової картки платника податків. Максимальний розмір файлу 50 Мб"
            title="Завантажити РНОКПП"
        />

        <FileSign
            multiple={true}
            onSelect={(id)=>{console.log(id)}}
            className={s.fileSign}
            description="РНОКПП- Реєстраційний номер облікової картки платника податків. Максимальний розмір файлу 50 Мб"
            title="Завантажити РНОКПП"
        />


 */

export default FileSign;