import config from "../../../config/config";
import React, {Fragment, useContext, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import Loading from "../../Loading/Loading";
import s from "../Participation.module.css";
import CustomSelect from "../../CustomSelect/CustomSelect";
import FileSign from "../../FileSign/FileSign";
import {Field, useFormikContext} from "formik";
import {
    CheckboxField,
    DateField,
    NumberMaskField,
    NumericField,
    SelectField,
    TextAreaField,
    TextField
} from "../../FormikFields/FormikFields";
import {InputItem, InputItemShort, ItemRow, OutlinedButton} from "../../UI/UI";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";
import CurrencyInput from "react-currency-masked-input";
import InputMask from "react-input-mask";
import SignFileModal from "../../SignFileModal/SignFileModal";
import FileSignMultiple from "../../FileSignMultiple/FileSignMultiple";
import InputField from "../../FormikFields/InputField";

export default function FinalInfo(){
    const [preferenceSignStatus, setPreferenceSignStatus] = useState('unsigned');
    const [additionalSignStatus, setAdditionalSignStatus] = useState('unsigned');
    const {nextStage} = useContext(ParticipationFormContext)
    const {values, setFieldValue, errors} = useFormikContext();
    return(
        <>

            <InputItemShort label="Розмір закритої цінової пропозиції">
                <InputField name={"initial_bid_amount"} component={NumericField}/>
            </InputItemShort>

            <InputItemShort label="Номер банківського рахунку - IBAN">
                <InputField name={"refund_iban"} mask="UA999999999999999999999999999" component={NumberMaskField}/>
            </InputItemShort>

            <InputItemShort label="Призначення рахунка">
                <InputField name={"refund_iban_purpose"} component={TextField}/>
            </InputItemShort>

            <InputItemShort label="Суб'єкт переважного права">
                <InputField
                    name={"has_preference"}
                    component={SelectField}
                    code="id"
                    data={[
                        {name:'Так', id:true},
                        {name:'Ні', id:false}
                    ]}
                />
            </InputItemShort>
            {values.has_preference &&
                    <div className={s.checkboxRow}>
                        <div className={s.checkboxItem}>
                            <input
                                onChange={()=>{
                                    setFieldValue("preferences", [{type:"SPECIAL_USE_PREFERENCE"}])
                                }}
                                name={"preferences_type"}
                                type={"radio"}
                            />
                            <p>Спецдозвіл на видобування корисних копалин<br/> загальнодержавного значення (1 черга)</p>
                        </div>
                        <div className={s.checkboxItem}>
                            <input
                                onChange={()=>{
                                    setFieldValue("preferences", [{type:"RENTER_PREFERENCE"}])
                                }}
                                name={"preferences_type"}
                                type={"radio"}
                            />
                            <p>Договір оренди земельної ділянки (2 черга)</p>
                        </div>
                    </div>
            }

            {values.preferences.length>0 &&
                <>
                    <InputItemShort label="Номер документа">
                        <Field name={"preferences[0].document_number"} component={NumericField}/>
                    </InputItemShort>

                    <InputItemShort label="Дата документа">
                        <Field name={"preferences[0].document_date"} component={DateField}/>
                    </InputItemShort>

                    <InputItemShort label="Термін дії до">
                        <Field name={"preferences[0].document_expire_at"} component={DateField}/>
                    </InputItemShort>

                    <SignFileModal
                        isVisible={preferenceSignStatus==='signing'}
                        onSign={(fileId)=>{
                            setPreferenceSignStatus('signed');
                            setFieldValue('preferences', [{...values.preferences[0], document:fileId}])
                        }}
                        onClose={setPreferenceSignStatus.bind(this, 'unsigned')}
                        transparentBackground={false}
                        documentType={"PREFERENCE_DOCUMENT"}
                    />
                    <InputItemShort label={"Документ, що посвідчує наявність переважного права."}>
                        <OutlinedButton
                            onClick={setPreferenceSignStatus.bind(this, 'signing')}
                            disabled={preferenceSignStatus==='signed'}
                            color="#057E36"
                        >
                            {preferenceSignStatus==='signed'?'Підписано':'Обрати і підписати'}
                        </OutlinedButton>
                    </InputItemShort>

                </>
            }

            <SignFileModal
                isVisible={additionalSignStatus==='signing'}
                onSign={(fileId)=>{
                    setAdditionalSignStatus('signed');
                    setFieldValue('additional_documents', [fileId])
                }}
                onClose={setAdditionalSignStatus.bind(this, 'unsigned')}
                transparentBackground={false}
                documentType={"ADDITIONAL_DOCUMENT"}
            />
            <InputItemShort label={"Додаткові документи (за необхідності)"}>
                <OutlinedButton
                    onClick={setAdditionalSignStatus.bind(this, 'signing')}
                    disabled={additionalSignStatus==='signed'}
                    color="#057E36"
                >
                    {preferenceSignStatus==='signed'?'Підписано':'Обрати додаткові документи'}
                </OutlinedButton>
            </InputItemShort>

            <InputField name={"personal_data_process_consent"} component={CheckboxField}>
                Надаю згоду на обробку моїх персональних даних відповідно до Закону України «Про захист персональних даних»
            </InputField>
            <button
                disabled={values.has_preference?!(preferenceSignStatus==='signed'):false}
                className={s.nextStageButton}
                type="submit"
            >Далі</button>
        </>
    )
}