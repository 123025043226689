import React, {useState, useEffect, useMemo, Fragment, useContext} from 'react';
import s from './Auction.module.css';
import dateFormat from 'dateformat';
import InputMask from 'react-input-mask';
import Card from '../../components/Card/Card'
import DatePicker, {setDefaultLocale} from 'react-datepicker';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import OutsideClickHandler from 'react-outside-click-handler';
import config from '../../config/config.js';
import {useHttp} from '../../hooks/HttpHook';
import "react-datepicker/dist/react-datepicker.css";
import InputRange from "react-input-range";
import './datepicker.css';
import uk from 'date-fns/locale/uk';
import MyLotsCard from "../../components/MyLotsCard/MyLotsCard";
import Loading from "../../components/Loading/Loading";
import CabinetCard from "../../components/CabinetCard/CabinetCard";
import LotItem from "../../components/LotItem/LotItem";
import SearchFilter from "../../components/Filters/SearchFilter/SearchFilter";
import DateFilter from "../../components/Filters/DateFilter/DateFilter";
import {AuthContext} from "../../context/AuthContext";
import SignComponent from "../../components/SignComponent/SignComponent";
import {AuctionFilterContext} from "../../context/AuctionFilterContext";

function Auction(props){
    const [lots, setLots] = useState([]);
    const [filterDate, setFilterDate] = useState(null);
    const [selectFilter, setSelectFilter] = useState({});
    const [selectValues, setSelectValues] = useState({});
    const [settlementShown, setSettlementShown] = useState(false);
    const {request, loading} = useHttp();
    const auth = useContext(AuthContext);
    const {filter} = useContext(AuctionFilterContext);

    async function getAllLots(){
        const params = new URLSearchParams(filter);
        const data = await request(config.baseUrl + `/api/v1/auctions/?${params}`);
        if(data) setLots(data.results);
    }

    //
    // const PurposeFilter = () => {
    //     return (
    //         <div className={s.filterItem}>
    //             <label>Тип/Призначення</label>
    //             <CustomSelect
    //                 onSelect={(value)=>{
    //                     setSelectFilter({...selectFilter, purpose_id: value[0].id})
    //                     setSelectValues({...selectValues, purpose: value})
    //                 }}
    //                 url="/api/v1/land-market/lands/purposes/"
    //                 defaultValue={
    //                     selectValues.purpose?selectValues.purpose:[]
    //                 }
    //             />
    //
    //         </div>
    //     )
    // }
    //
    // const LocationFilter = () => {
    //     //TODO show location in main input
    //     return(
    //         <div onClick={()=>{setSettlementShown(true)}} className={s.filterItem}>
    //             <label>Населенний пункт</label>
    //             <div  className={s.filterBox + ' ' + s.settlement}>
    //                 {settlementShown?<OutsideClickHandler onOutsideClick={()=>{setSettlementShown(false)}}><div className={s.filterDropdown}>
    //                     <div className={s.infoBox}>
    //                         <label>Область</label>
    //                         <CustomSelect
    //                             onSelect={(value)=>{
    //                                 setSelectFilter({...selectFilter, region_id: value[0].id})
    //                                 setSelectValues({...selectValues, region: value})
    //                             }}
    //                             url="/api/v1/location/regions/"
    //                             defaultValue={
    //                                 selectValues.region?selectValues.region:[]
    //                             }/>
    //
    //
    //
    //                     </div>
    //                     <div className={s.infoBox}>
    //                         <label>Район</label>
    //                         <CustomSelect
    //                             onSelect={(value)=>{
    //                                 setSelectFilter({...selectFilter, district_id: value[0].id})
    //                                 setSelectValues({...selectValues, district: value})
    //                             }}
    //                             url="/api/v1/location/districts/"
    //                             defaultValue={
    //                                 selectValues.district?selectValues.district:[]
    //                             }/>
    //                     </div>
    //                     <div className={s.infoBox}>
    //                         <label>Населений пункт</label>
    //                         <CustomSelect
    //                             onSelect={(value)=>{
    //                                 setSelectFilter({...selectFilter, settlement_id: value[0].id})
    //                                 setSelectValues({...selectValues, settlement: value})
    //                             }}
    //                             url="/api/v1/location/settlements/"
    //                             defaultValue={
    //                                 selectValues.settlement?selectValues.settlement:[]
    //                             }/>
    //
    //                     </div>
    //                     <div className={s.infoBox}>
    //                         <label>Вулиця</label>
    //                         <CustomSelect
    //                             onSelect={(value)=>{
    //                                 setSelectFilter({...selectFilter, street_id: value[0].id})
    //                                 setSelectValues({...selectValues, street: value})
    //                             }}
    //                             url="/api/v1/location/streets/"
    //                             defaultValue={
    //                                 selectValues.street?selectValues.street:[]
    //                             }/>
    //
    //                     </div>
    //                 </div></OutsideClickHandler>:null}
    //             </div>
    //         </div>
    //     )
    // }
    //
    // const MemoizedLocation = useMemo(()=> LocationFilter,[settlementShown, selectFilter])
    // const MemoizedPurpose = useMemo(()=> PurposeFilter,[selectFilter])


    // useEffect(()=>{
    //     if(props.myLots) setLots(props.data)
    //     else getAllLots()
    // },[selectFilter]);

    useEffect(()=>{
        // console.log('filter',props.filter)
        // if(props.filter && props.filter != JSON.stringify(selectFilter)){
        //     setSelectFilter(JSON.parse(props.filter));
        // }
        getAllLots();
    },[filter])

//TODO connect filters
    return(
        <Fragment>
            {loading?<Loading/>:null}
        <div className={s.Auction}>
                {/*<div className={s.filter}>*/}
                {/*    <div className={s.filterBottom}>*/}
                {/*        <SearchFilter onChange={()=>{}}/>*/}
                {/*        <DateFilter/>*/}
                {/*        <MemoizedPurpose/>*/}
                {/*        <MemoizedLocation/>*/}
                {/*        <button onClick={resetFiltersHandler} className={s.resetBtn}>Скинути фільтри</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={s.content}>*/}
                {/*    {!props.myLots && lots && lots.length>0?lots.map((lot, i)=>{*/}
                {/*       return <Card type='auction' item={lot} key={i}/>*/}
                {/*    }):props.myLots && lots && lots.length>0?lots.map((lot, i)=>{*/}
                {/*        return <Card  item={lot} key={i}/>*/}
                {/*    }):<h3 className={s.notFound}>Аукціонів не знайдено</h3>}*/}

                {/*</div>*/}



                <div className={s.content}>
                    {props.myLots?
                        lots.map((el, i)=><CabinetCard key={i}/>):
                        lots.map((el, i)=><Card key={i} item={el}/>)
                    }
                </div>
        </div>
        </Fragment>
    )
}

export default Auction;