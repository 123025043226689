import React, {useCallback, useContext, useEffect, useState} from 'react';
import s from './NewLot.module.css';
import {ListItem, List, Link, Grid, Item, Box} from "@mui/material";
import image from '../../assets/img/topSearchBackground.jpg';
import config from "../../config/config";
import {useParams, useHistory} from 'react-router-dom';
import {useHttp} from "../../hooks/HttpHook";
import LotInfo from "./components/LotInfo";
import Menu from "./components/Menu";
import LotDetails from "./components/LotDetails";
import Participation from "../../components/Participation/NewParticipation";
import ErrorHandler from "../../utils/ErrorLoger";
import LotOrganizer from "./components/LotOrganizer";
import LotBank from "./components/LotBank";
import LotAuctionDocuments from "./components/LotAuctionDocuments";
import Loading from "../../components/Loading/Loading";
import LotDocuments from "./components/LotDocuments";
import LotPayment from "./components/LotPayment";
import {AuthContext} from "../../context/AuthContext";
import Countdown from "react-countdown";
import LotQuestions from "./components/LotQuestions";

function NewLot() {
    const [auctionInfo, setAuctionInfo] = useState({});
    const [singleAuctionInfo, setSingleAuctionInfo] = useState({});
    const [currentImage, setCurrentImage] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [isParticipation, setIsParticipation] = useState(false);
    const lotNumber = useParams().number;
    const history = useHistory();
    const auth = useContext(AuthContext);
    const {request, loading} = useHttp();

    const saveHandler = async (e) => {
        e.preventDefault();
        let data;
        try {
            data = await request(config.baseUrl + `/api/v1/auctions/${auctionInfo.id}/add-to-user-saved/`,'POST');
            if(data.status === 'OK') setIsSaved(true);
        } catch (e) {
            ErrorHandler(e)
        }
    }

    const removeHandler = async (e) => {
        e.preventDefault();
        let data;
        try {
            data = await request(config.baseUrl + `/api/v1/auctions/${auctionInfo.id}/remove-from-user-saved/`,'POST');
            if(data.status === 'OK') setIsSaved(false);
        } catch (e) {
            ErrorHandler(e)
        }
    }

    function getData(){
        try{
            request(`${config.baseUrl}/api/v1/auctions/${lotNumber}/`,'GET').then((data)=>{
                if(data && data.lot) {
                    setAuctionInfo(data);
                    setCurrentImage(config.baseUrl + data.lot.photos[0].url);
                    setIsSaved(data.lot.is_saved);
                }
            });
        }catch(e){
            ErrorHandler(e)
        }
        try{
            request(`${config.baseUrl}/api/v1/auctions/organizer/auction/${lotNumber}/single/`,'GET').then((data)=>{
                if(data) {
                    setSingleAuctionInfo(data);
                }
            });
        }catch(e){
            ErrorHandler(e)
        }
    }

    const OtherPhotos = useCallback(() => {
        return (
            <div className={s.otherPhotos}>
                {auctionInfo.lot && auctionInfo.lot.photos.map((item, i)=>
                    <img
                        key={i}
                        src={config.baseUrl + item.url}
                        onClick={()=>setCurrentImage(config.baseUrl + item.url)}
                    />
                )}
            </div>
        )
    },[auctionInfo])

    const SubscribeButton = () => {
        return (
            <div>
                {auth.scope==='main'&&
                    <div>
                        {isSaved?
                            <p onClick={removeHandler}>Не стежити</p>:
                            <p onClick={saveHandler}>Стежити</p>
                        }
                    </div>
                }
            </div>
        )
    }
    // if(+Date.now() > (auctionInfo.entrant_period_start_at_timestamp*1000) && +Date.now() < (auctionInfo.entrant_period_end_at_timestamp*1000)){
    //     if(!auctionInfo.is_entrant) return <button onClick={()=>{setIsParticipation(true)}} className={s.blueButton}>Вступити в аукціон</button>
    // }
    // if(+Date.now() > (auctionInfo.date_start_timestamp*1000))
    //     return <button onClick={()=>{history.push(`/room/${auctionInfo.number}/`)}} className={s.blueButton}>Приєднатись до аукціону</button>
    // else return <div></div>

    const AuctionButton = ({status}) => {
        const isEntrantPeriod = +Date.now() > (auctionInfo.entrant_period_start_at_timestamp*1000) && +Date.now() < (auctionInfo.entrant_period_end_at_timestamp*1000);
        const isAwaitingStartPeriod = auctionInfo.auction_status === 'AWAITING_START';
        const isStartedPeriod = auctionInfo.auction_status === 'STARTED';
        const isAuctionEndedPeriod = (
            auctionInfo.auction_status === 'AWAITING_PROTOCOL_SIGN' ||
            auctionInfo.auction_status === 'AWAITING_CONTRACT_SIGN' ||
            auctionInfo.auction_status === 'AWAITING_PAYMENT' ||
            auctionInfo.auction_status === 'ENDED'
        )
        if(auth.scope==='main'){
            if(isEntrantPeriod){
                if(auctionInfo.is_entrant){
                    return <p>Ви вже учасник</p>
                }else {
                    return <button onClick={()=>{setIsParticipation(true)}} className={s.blueButton}>Вступити в аукціон</button>
                }
            }else if(isAwaitingStartPeriod){
                return <div>
                    {auctionInfo.date_start_timestamp &&
                        <div className={s.priceBoxButtons}>
                            <p>Початок за:</p>
                            <h3><Countdown daysInHours={true} date={+auctionInfo.date_start_timestamp*1000}/></h3>
                        </div>
                    }
                </div>
            }else if(isStartedPeriod){
                return <button onClick={()=>{history.push(`/room/${auctionInfo.number}/`)}} className={s.blueButton}>Приєднатись до аукціону</button>
            }else if(isAuctionEndedPeriod){
                return <button onClick={()=>{history.push(`/results/${auctionInfo.number}/`)}} className={s.blueButton}>Переглянути результати</button>
            }else return <div/>
        }
        else return <div/>
    }


    useEffect(()=>{
        getData();
    },[])

    return(
        <div className={s.NewLot}>
            {Object.keys(auctionInfo).length === 0?<Loading/>:
                <>
                    {isParticipation?
                        <Participation
                            lotNumber={auctionInfo.number}
                            lotId={auctionInfo.id}
                            lot={auctionInfo.lot}
                            startingPrice={auctionInfo.starting_price}
                        />:
                        <div className={s.wrapper}>
                            <Menu/>
                            <div className={s.content}>
                                <div id="info" className={s.mainInfo}>
                                    <div className={s.imagesBox}>
                                        <img src={currentImage}/>
                                        <OtherPhotos/>
                                    </div>
                                    <div className={s.titleBox}>
                                        <h3>{auctionInfo.lot && auctionInfo.lot.theme}</h3>
                                        <AuctionButton/>
                                        <SubscribeButton/>
                                    </div>
                                    <LotDetails  auctionInfo={auctionInfo} lotNumber={lotNumber}/>
                                </div>
                                <LotInfo id="description" auctionInfo={auctionInfo}/>
                                <LotDocuments id="documents" auctionInfo={auctionInfo}/>
                                <LotOrganizer id="organizer" auctionInfo={auctionInfo}/>
                                <LotBank id="bank" auctionInfo={auctionInfo}/>
                                {auth.scope==='main'&& <LotQuestions id="questions" lotId={auctionInfo.id}/>}
                                <LotAuctionDocuments id="auction_documents" auctionInfo={auctionInfo}/>
                                <LotPayment id="payment" paymentStatus={singleAuctionInfo.payment_status} auctionInfo={auctionInfo}/>
                            </div>
                        </div>}
                </>
            }
        </div>
    )
}

export default NewLot;