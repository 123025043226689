import React, {useCallback, useContext, useState} from "react";
import s from './ExtendedFilters.module.css';
import {Button, CheckboxItem, InputItem, ItemRow, Line, Title} from "../UI/UI";
import {Field, Form, Formik, useFormikContext} from "formik";
import {CategoryField, CheckboxField, DateField, NumericField, SelectField} from "../FormikFields/FormikFields";
import {AuctionFilterContext} from "../../context/AuctionFilterContext";
import {useHistory} from "react-router-dom";


export default function ExtendedFilters() {
    const [purposes, setPurposes] = useState([])
    const history = useHistory();
    const {filter, setFilter} = useContext(AuctionFilterContext);
    const submitHandler = (values) => {
        setFilter({...filter, ...values});
        history.push('/auction/')
    }

    const ClearFiltersButton = () => {
        const {resetForm} = useFormikContext();
        const clearFiltersHandler = () => {
            setFilter({});
            resetForm();
            history.push('/auction/')
        }

        return(
            <span onClick={clearFiltersHandler} className={s.resetFilter}>
                <p>Скинути фільтр</p>
            </span>
        )
    }

    return(
        <div className={s.ExtendedFilters}>
            <div className={s.content}>
                <Formik initialValues={filter} onSubmit={submitHandler}>
                    {props=>
                    <Form>
                        <Title>Розширений пошук</Title>
                        <InputItem label="Пошук за ключовим словом:">
                            <Field name="common" placeholder="Введіть назву об’єкта, населений пункт, опис тощо"/>
                        </InputItem>
                        {console.log(props.values)}
                        <ItemRow>
                            <InputItem label="Тип процедури:">
                                <Field
                                    name="procedure_type_id"
                                    url="/api/v1/land-market/lands/procedure-types/"
                                    placeholder="Оберіть тип процедури"
                                    component={SelectField}
                                    checkDefaultById
                                />
                            </InputItem>
                            <InputItem label="Тип оголошення:">
                                <input disabled/>
                            </InputItem>
                        </ItemRow>

                        <Line/>

                        <Field name="has_preference_subjects" component={CheckboxField}>
                            Продаж землі з переважним правом
                        </Field>

                        <Line/>

                        <ItemRow>
                            <InputItem label="Статус торгів:">
                                <Field
                                    name="auction_status"
                                    data={[
                                        { name: "Подання пропозицій", id: 'PENDING' },
                                        { name: "Аукціон", id: 'STARTED' },
                                        { name: "Очікування підписання протоколу", id: 'AWAITING_PROTOCOL_SIGN' },
                                        { name: "Очікування підписання договору", id: 'AWAITING_CONTRACT_SIGN' },
                                        { name: "Очікування оплати", id: 'AWAITING_PAYMENT' },
                                        { name: "Не відбулися", id: 'NOT_OCCURRED' },
                                        { name: "Скасовано", id: 'CANCELED' },
                                        { name: "Завершено", id: 'ENDED' },
                                    ]}
                                    placeholder="Оберіть статус торгів"
                                    component={SelectField}
                                    checkDefaultById
                                />
                            </InputItem>
                            <InputItem label="Вид торгів:">
                                <Field
                                    name="auction_type"
                                    data={[
                                        { name: "Первинні", id: 'PRIMARY' },
                                        { name: "Вторинні", id: 'SECONDARY' },
                                    ]}
                                    placeholder="Оберіть тип процедури"
                                    component={SelectField}
                                    checkDefaultById
                                />
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                                <InputItem label="Категорія земель за класифікатором:">
                                    <Field
                                        name="cav_ps_code"
                                        nestingKeys={['first_nested_categories', 'second_nested_categories', 'purpose_categories']}
                                        url="/api/v1/land-market/lands/purpose-categories/"
                                        placeholder="Класифікатор CAV-PS"
                                        codeLabel="cav_ps_code"
                                        nameLabel="name"
                                        selectLabel="cav_ps_code"
                                        component={CategoryField}
                                        onSelect={(item, form)=>{
                                           // console.log(item)
                                            props.setFieldValue('purpose_id', undefined, false)
                                            if(item.land_purposes){
                                                setPurposes(item.land_purposes)
                                            }else {
                                                setPurposes([])
                                            }
                                        }}
                                        checkDefaultById
                                    />
                                </InputItem>

                            <InputItem label="Форма власності:">
                                <Field
                                    name="ownership_type"
                                    data={[
                                        { name: "Комунальна", id: 'COMMUNAL' },
                                        { name: "Державна", id: 'STATE' },
                                        { name: "Приватна", id: 'PRIVATE' },
                                    ]}
                                    placeholder="Оберіть форму власності"
                                    component={SelectField}
                                    checkDefaultById
                                />
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <InputItem label="Цільове призначення земельної ділянки:">
                                <Field
                                    disabled={purposes.length<=0}
                                    name="purpose_id"
                                    nestingKeys={['first_nested_categories', 'second_nested_categories', 'purpose_categories']}
                                    placeholder="Оберіть цільове призначення"
                                    component={CategoryField}
                                    codeLabel="code"
                                    nameLabel="description"
                                    selectLabel="id"
                                    data={purposes}
                                    checkDefaultById
                                />
                            </InputItem>
                            <InputItem label="Вид земельних угідь:">
                                <Field
                                    nestingKeys={['land_type_classifier_types']}
                                    name="land_type_classifier_id"
                                    url="/api/v1/land-market/lands/land-type-classifiers/"
                                    placeholder="Оберіть вид земельних угідь"
                                    codeLabel="code"
                                    nameLabel="name"
                                    selectLabel="id"
                                    component={CategoryField}
                                    checkDefaultById
                                />
                            </InputItem>
                        </ItemRow>

                        <Line/>

                        <ItemRow>
                            <InputItem label="Місце розташування земельної ділянки:">
                                <Field
                                    name="region_id"
                                    url="/api/v1/location/regions/"
                                    placeholder="Оберіть місце розташування"
                                    component={SelectField}
                                    checkDefaultById
                                />
                            </InputItem>
                            <InputItem label="Площа земельної ділянки (га)">
                                <ItemRow>
                                    <InputItem>
                                        <Field component={NumericField} placeholder="Від" name="area_min"/>
                                    </InputItem>
                                    <InputItem>
                                        <Field component={NumericField} placeholder="До" name="area_max"/>
                                    </InputItem>
                                </ItemRow>
                            </InputItem>
                        </ItemRow>



                        <ItemRow>
                            <InputItem label="Дата публікації оголошення:">
                                <ItemRow>
                                    <InputItem>
                                        <Field component={DateField} placeholder="Початок" name="published_at__gte"/>
                                    </InputItem>
                                    <InputItem>
                                        <Field component={DateField} placeholder="Завершення" name="published_at__lte"/>
                                    </InputItem>
                                </ItemRow>
                            </InputItem>
                            <InputItem label="Дата початку проведення аукціону:">
                                <ItemRow>
                                    <InputItem>
                                        <Field component={DateField} placeholder="Початок" name="start_date__gte"/>
                                    </InputItem>
                                    <InputItem>
                                        <Field component={DateField} placeholder="Завершення" name="start_date__lte"/>
                                    </InputItem>
                                </ItemRow>
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <InputItem label="Стартова ціна лота, грн:">
                                <ItemRow>
                                    <InputItem>
                                        <Field component={NumericField} placeholder="Від" name="starting_price_gte"/>
                                    </InputItem>
                                    <InputItem>
                                        <Field component={NumericField} placeholder="До" name="starting_price_lte"/>
                                    </InputItem>
                                </ItemRow>
                            </InputItem>
                            <InputItem label="Дата завершення аукціону:">
                                <ItemRow>
                                    <InputItem>
                                        <Field component={DateField} placeholder="Початок" name="end_date__gte"/>
                                    </InputItem>
                                    <InputItem>
                                        <Field component={DateField} placeholder="Завершення" name="end_date__lte"/>
                                    </InputItem>
                                </ItemRow>
                            </InputItem>
                        </ItemRow>

                        <ItemRow>
                            <InputItem label="Кількість лотів:">
                                <Field
                                    name="lots_count"
                                    data={[
                                        { name: "1", id: '1' },
                                        { name: "2", id: '2' },
                                        { name: "3", id: '3' },
                                        { name: "4", id: '4' },
                                        { name: "5", id: '5' },
                                        { name: "6", id: '6' },
                                        { name: "7", id: '7' },
                                        { name: "8", id: '8' },
                                        { name: "9", id: '9' },
                                        { name: "10", id: '10' },
                                    ]}
                                    placeholder="Оберіть кількість лотів"
                                    component={SelectField}
                                    checkDefaultById
                                />
                            </InputItem>
                        </ItemRow>

                        <Line/>

                        <InputItem label="Організатор торгів:">
                            <Field name="auction_organizer" placeholder="Введіть ЄДРПОУ або РНКПОП або назву"/>
                        </InputItem>


                        <ItemRow>
                            <InputItem label="Критерій сортування:">
                                <Field
                                    name="order_by"
                                    data={[
                                        { name: "Від нових до старих", id: 'new_first' },
                                        { name: "Від старих до нових", id: 'old_first' },
                                        { name: "Від дешевих до дорогих", id: 'low_price_first' },
                                        { name: "Від дорогих до дешевих", id: 'high_price_first' },
                                    ]}
                                    placeholder="Від нових до старих"
                                    component={SelectField}
                                    checkDefaultById
                                />
                            </InputItem>
                        </ItemRow>

                        <Line/>

                        <Field name="" component={CheckboxField}>
                            Шукати також в архіві
                        </Field>

                        <Field name="" component={CheckboxField}>
                            Сповіщати про нові торги за заданими пошуковими параметрами
                        </Field>

                        <Line/>
                        <ItemRow>
                            <Button type="submit" style={{width:'50%', marginBottom:'40px'}} color="#057E36">
                                Пошук
                            </Button>
                            <ClearFiltersButton/>
                        </ItemRow>

                    </Form>}
                </Formik>
            </div>

        </div>
    )
}