import React, {useContext, useState} from "react";
import s from "../Participation.module.css";
import {Field, useFormikContext} from "formik";
import {TextAreaField, TextField} from "../../FormikFields/FormikFields";
import {InputItem, InputItemShort, ItemRow, OutlinedButton} from "../../UI/UI";
import {ParticipationFormContext} from "../../../context/ParticipationFormContext";
import SignFileModal from "../../SignFileModal/SignFileModal";

export default function ChiefInfo(){
    const {values, setFieldValue} = useFormikContext();
    const [signStatus, setSignStatus] = useState('unsigned')
    const {nextStage} = useContext(ParticipationFormContext)

    return(
        <>
            <SignFileModal
                isVisible={signStatus==='signing'}
                onSign={(fileId)=>{
                    setSignStatus('signed');
                    setFieldValue('legal_entity_documents', [fileId])
                }}
                onClose={setSignStatus.bind(this, 'unsigned')}
                defaultFileUrl={values.legal_entity_document.file}
                transparentBackground={false}
                documentType={"LEGAL_ENTITY_DOCUMENT"}
            />
            <fieldset disabled={true}>
                <InputItemShort label={"Прізвище керівника"}>
                    <Field name={"legal_entity_chief_last_name"} component={TextField}/>
                </InputItemShort>
                <InputItemShort label={"Ім'я керівника"}>
                    <Field name={"legal_entity_chief_first_name"} component={TextField}/>
                </InputItemShort>
                <InputItemShort label={"По батькові керівника"}>
                    <Field name={"legal_entity_chief_second_name"} component={TextField}/>
                </InputItemShort>
                <InputItemShort label={"Назва документу, на підставі якого діє керівник"}>
                    <Field name={"legal_entity_chief_document_name"} component={TextAreaField}/>
                </InputItemShort>
                <ItemRow>
                    <InputItem label={"Дата документа"}>
                        <Field name={"legal_entity_chief_document_date"} component={TextField}/>
                    </InputItem>
                    <InputItem label={"Номер документа"}>
                        <Field name={"legal_entity_chief_document_number"} component={TextField}/>
                    </InputItem>
                </ItemRow>
                {console.log(values)}
                <InputItemShort label={"Документ юридичної особи"}>
                    <OutlinedButton
                        onClick={setSignStatus.bind(this, 'signing')}
                        disabled={signStatus==='signed'}
                        color="#057E36"
                    >
                        {signStatus==='signed'?'Підписано':'Підписати'}
                    </OutlinedButton>
                </InputItemShort>

            </fieldset>
            <button
                disabled={!(signStatus==='signed')}
                className={s.nextStageButton}
                onClick={nextStage}
            >Далі</button>
        </>
    )
}