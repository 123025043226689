import React from 'react';
import s from './News.module.css';
import {Title} from "../../components/UI/UI";
import img from '../../assets/img/topSearchBackground2.jpg'
function News(){
    return(
        <div className={s.News}>
            <div className={s.content}>
                <h1>Новини</h1>
                <div className={s.newsContainer}>
                    <div className={s.newsItem}>
                        <img src={img}/>
                        <p className={s.date}>10 червня 2022</p>
                        <h5>Шановні Користувачі та Відвідувачі електронного майданчика «RYNOK-ZEMLI»!</h5>
                        <p className={s.description}>Повідомляємо, що в період з 18.00 год 01.08.2022 по 09.00 год. 02.08.2022 робота ...</p>
                    </div>
                    <div className={s.newsItem}>
                        <img src={img}/>
                        <p className={s.date}>10 червня 2022</p>
                        <h5>Шановні Користувачі та Відвідувачі електронного майданчика «RYNOK-ZEMLI»!</h5>
                        <p className={s.description}>Повідомляємо, що в період з 18.00 год 01.08.2022 по 09.00 год. 02.08.2022 робота ...</p>
                    </div>
                    <div className={s.newsItem}>
                        <img src={img}/>
                        <p className={s.date}>10 червня 2022</p>
                        <h5>Шановні Користувачі та Відвідувачі електронного майданчика «RYNOK-ZEMLI»!</h5>
                        <p className={s.description}>Повідомляємо, що в період з 18.00 год 01.08.2022 по 09.00 год. 02.08.2022 робота ...</p>
                    </div>
                    <div className={s.newsItem}>
                        <img src={img}/>
                        <p className={s.date}>10 червня 2022</p>
                        <h5>Шановні Користувачі та Відвідувачі електронного майданчика «RYNOK-ZEMLI»!</h5>
                        <p className={s.description}>Повідомляємо, що в період з 18.00 год 01.08.2022 по 09.00 год. 02.08.2022 робота ...</p>
                    </div>
                    <div className={s.newsItem}>
                        <img src={img}/>
                        <p className={s.date}>10 червня 2022</p>
                        <h5>Шановні Користувачі та Відвідувачі електронного майданчика «RYNOK-ZEMLI»!</h5>
                        <p className={s.description}>Повідомляємо, що в період з 18.00 год 01.08.2022 по 09.00 год. 02.08.2022 робота ...</p>
                    </div>
                    <div className={s.newsItem}>
                        <img src={img}/>
                        <p className={s.date}>10 червня 2022</p>
                        <h5>Шановні Користувачі та Відвідувачі електронного майданчика «RYNOK-ZEMLI»!</h5>
                        <p className={s.description}>Повідомляємо, що в період з 18.00 год 01.08.2022 по 09.00 год. 02.08.2022 робота ...</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News;