import s from "../NewLot.module.css";
import React, {useEffect, useState} from "react";
import {useHttp} from "../../../hooks/HttpHook";
import config from "../../../config/config";

export default function LotOrganizer({auctionInfo, id}) {

    return (
        <div id={id} className={s.lotOrganizer}>
            <h1 className={s.title}>Організатор торгів</h1>
            <div className={s.lotDetails}>
                <div className={s.gridItem}>
                    <p className={s.label}>Прізвище:</p>
                    <h5 className={s.value}>{auctionInfo.user && auctionInfo.user.username}</h5>
                </div>
                <div className={s.gridItem}>
                    <p className={s.label}>Ім'я:</p>
                    <h5 className={s.value}>{auctionInfo.user && auctionInfo.user.second_name}</h5>
                </div>
                <div className={s.gridItem}>
                    <p className={s.label}>По батькові:</p>
                    <h5 className={s.value}>{auctionInfo.user && auctionInfo.user.third_name}</h5>
                </div>
            </div>

            <div className={s.textItem}>
                <p className={s.label}>Організаційно правова форма:</p>
                <h5 className={s.value}>--</h5>
            </div>
            <div className={s.lotDetails}>
                <div className={s.gridItem}>
                    <p className={s.label}>Email:</p>
                    <h5 className={s.value}>--</h5>
                </div>
                <div className={s.gridItem}>
                    <p className={s.label}>Номер телефону:</p>
                    <h5 className={s.value}>{auctionInfo.user && auctionInfo.user.phone_number}</h5>
                </div>
            </div>
            <div className={s.line}/>
            <h5 className={s.smallTitle}>Місцезнаходження</h5>
            <div className={s.textItemRow}>
                <div className={s.textItem}>
                    <p className={s.label}>Область:</p>
                    <h5 className={s.value}>--</h5>
                </div>
                <div className={s.textItem}>
                    <p className={s.label}>Район:</p>
                    <h5 className={s.value}>--</h5>
                </div>
            </div>
            <div className={s.textItemRow}>
                <div className={s.textItem}>
                    <p className={s.label}>Населений пункт:</p>
                    <h5 className={s.value}>--</h5>
                </div>
                {auctionInfo.lot && auctionInfo.lot.location.street &&
                <div className={s.textItem}>
                    <p className={s.label}>Вулиця:</p>
                    <h5 className={s.value}>--</h5>
                </div>}
            </div>
        </div>
    )
}