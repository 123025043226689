import React from 'react';
import { connect, getIn } from 'formik';
import {Error} from "../UI/UI";

const ErrorMessage = props => {
    console.log(props)
    const error = getIn(props.formik.errors, props.name);
    return error ? <Error>{error}</Error> : null;
};

export default connect(ErrorMessage);