import React, {useState, Fragment, useEffect, useRef, useContext} from 'react';
import s from './AuctionResults.module.css';
import checkmarkDisabled from '../../assets/img/checkmark-disabled.png';
import checkmarkGreen from '../../assets/img/checkmark-green.png';
import clock from '../../assets/img/clock.png';
import radioBlue from '../../assets/img/radio-blue.png';
import radioGrey from '../../assets/img/radio-grey.png';
import radioYellow from '../../assets/img/radio-yellow.png';
import Timer from '../../components/Timer/Timer';
import warningIcon from '../../assets/img/warning.png';
import {IMaskInput} from 'react-imask';
import Modal from "../../components/Modal/Modal";
import {useParams} from "react-router-dom";
import useWebSocket from 'react-use-websocket';
import config from "../../config/config";
import jwt from "jwt-decode";
import {AuthContext} from "../../context/AuthContext";
import {useHttp} from "../../hooks/HttpHook";
import {useHistory} from 'react-router-dom';
import Countdown from "react-countdown";
import Loading from "../../components/Loading/Loading";
import ErrorHandler from "../../utils/ErrorLoger";
function AuctionResults() {
    const [results, setResults] = useState({});
    const [roundBids, setRoundBids] = useState({});
    const [lotInfo, setLotInfo] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [timetable, setTimetable] = useState({});
    const {request} = useHttp();
    const lotNumber = useParams().params;
    const history = useHistory();
    let timeoutCounter=0;
    const inputReference = useRef();

    async function getAuctionResults(id){
        timeoutCounter++;
        try{
            let data = await request(`${config.baseUrl}/api/v1/auctions/${id}/result/`,'GET');
            if(data.members) {
                setRoundBids({'ROUND_1':data.round1_bids, 'ROUND_2':data.round2_bids, 'ROUND_3':data.round3_bids,})
                setLoading(false);
                setResults(data);
                setTimetable(data.timetable);
            }
            else setTimeout(getData,3000);
        }catch(e){
            ErrorHandler(e)
            if(timeoutCounter<15) setTimeout(getAuctionResults,3000);
            else history.push(`/lot/${lotNumber}`);
        }
    }

    async function getLotInfo(){
        try{
            request(`${config.baseUrl}/api/v1/auctions/${lotNumber}/`,'GET').then((data)=>{
                if(data) {
                    setLotInfo(data);
                    getAuctionResults(data.id);
                }
            });
        }catch(e){
            ErrorHandler(e)
        }
    }

    useEffect(()=>{
        getLotInfo();
    },[])


    return(
        <div className={s.Lot}>
            <div className={s.left}>
                {!isLoading &&
                    <>
                        <h2 style={{position:'relative'}}><div className={s.leftBorder}></div>Хід аукціону</h2>
                        <div className={s.auctionStage}>
                            <img src={checkmarkGreen}/>
                            <p>Початок аукціону</p>
                            <p className={s.auctionStageTime}>{timetable['STARTED']?.started_at.split(' ')[1] + ' - ' + timetable['STARTED']?.ended_at.split(' ')[1]}</p>
                        </div>
                        <div className={s.auctionStage}>
                            <img src={checkmarkGreen}/>
                            <p>Раунд 1</p>
                            <p className={s.auctionStageTime}>{timetable['ROUND_1']?.started_at.split(' ')[1] + ' - ' + timetable['ROUND_1']?.ended_at.split(' ')[1]}</p>
                        </div>
                        <div className={s.auctionStage}>
                            <img src={checkmarkGreen}/>
                            <p>Раунд 2</p>
                            <p className={s.auctionStageTime}>{timetable['ROUND_2']?.started_at.split(' ')[1] + ' - ' + timetable['ROUND_2']?.ended_at.split(' ')[1]}</p>
                        </div>
                        <div className={s.auctionStage}>
                            <img src={checkmarkGreen}/>
                            <p>Раунд 3</p>
                            <p className={s.auctionStageTime}>{timetable['ROUND_3']?.started_at.split(' ')[1] + ' - ' + timetable['ROUND_3']?.ended_at.split(' ')[1]}</p>
                        </div>
                        <div className={s.auctionStage}>
                            <img src={checkmarkGreen}/>
                            <p>Оголошення результатів</p>
                            <p className={s.auctionStageTime}>{timetable['ENDED']?.started_at.split(' ')[1]}</p>
                        </div>
                    </>}
            </div>
            <div className={s.middle}>
                <h2 style={{color:'green'}} className={s.middleTitle}>Оголошення результаів</h2>
                <div className={s.bets}>
                    {results && results.leaderboard?results.leaderboard.map((el, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betItemTop}>
                                <div className={s.betOwner}>
                                    <p>{results.members[el.member_id].user.username}</p>
                                </div>
                                <div className={id==0?s.betAmountWinner:s.betAmount}>
                                    <p>{el.amount} грн</p>
                                </div>
                                {results.members_with_preferences.includes(el.member_id) && <p className={s.subjectTag}>Суб'єкт переважного права</p>}
                            </div>
                            <div className={s.betTime}>
                                <p>#20804875</p>
                                <div className={s.circle}></div>
                                <p>{el.time}</p>
                            </div>
                        </div>
                    }):null}
                </div>


                <div className={s.middleSubtitle}>
                    <h2>Хроніка аукціону</h2>
                </div>

                <h2 className={s.middleTitle}>Початкові заявки</h2>
                <div className={s.bets}>
                    {results && results.members_bid_order?results.members_bid_order.map((memberId, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betItemTop}>
                                <div className={s.betOwner}>
                                    <p>{results.members[memberId].user.username}</p>
                                </div>
                                <div className={s.betAmount}>
                                    <p>{roundBids['ROUND_1'][memberId]?roundBids['ROUND_1'][memberId].amount+' грн':null}</p>
                                </div>
                            </div>
                            <div className={s.betTime}>
                                <p>#20804875</p>
                            </div>
                        </div>
                    }):null}
                </div>

                <h2 className={s.middleTitle}>Раунд 1</h2>
                <div className={s.bets}>
                    {results && results.members_bid_order?results.members_bid_order.map((memberId, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betItemTop}>
                                <div className={s.betOwner}>
                                    <p>{results.members[memberId].user.username}</p>
                                </div>
                                <div className={s.betAmount}>
                                    <p>{roundBids['ROUND_1'][memberId]?roundBids['ROUND_1'][memberId].amount+' грн':null}</p>
                                </div>
                            </div>
                            <div className={s.betTime}>
                                <p>#20804875</p>
                                <div className={s.circle}></div>
                                <p>{roundBids['ROUND_1'][memberId]?roundBids['ROUND_1'][memberId].time:null}</p>
                            </div>
                        </div>
                    }):null}
                </div>



                <h2 className={s.middleTitle}>Раунд 2</h2>
                <div className={s.bets}>
                    {results && results.members_bid_order?results.members_bid_order.map((memberId, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betItemTop}>
                                <div className={s.betOwner}>
                                    <p>{results.members[memberId].user.username}</p>
                                </div>
                                <div className={s.betAmount}>
                                    <p>{roundBids['ROUND_2'][memberId]?roundBids['ROUND_2'][memberId].amount+' грн':null}</p>
                                </div>
                            </div>
                            <div className={s.betTime}>
                                <p>#20804875</p>
                                <div className={s.circle}></div>
                                <p>{roundBids['ROUND_2'][memberId]?roundBids['ROUND_2'][memberId].time:null}</p>
                            </div>
                        </div>
                    }):null}
                </div>

                <h2 className={s.middleTitle}>Раунд 3</h2>
                <div className={s.bets}>
                    {results && results.members_bid_order?results.members_bid_order.map((memberId, id)=>{
                        return <div key={id} className={s.betItem}>
                            <div className={s.betItemTop}>
                                <div className={s.betOwner}>
                                    <p>{results.members[memberId].user.username}</p>
                                </div>
                                <div className={s.betAmount}>
                                    <p>{roundBids['ROUND_3'][memberId]?roundBids['ROUND_3'][memberId].amount+' грн':null}</p>
                                </div>
                            </div>
                            <div className={s.betTime}>
                                <p>#20804875</p>
                                <div className={s.circle}></div>
                                <p>{roundBids['ROUND_3'][memberId]?roundBids['ROUND_3'][memberId].time:null}</p>
                            </div>
                        </div>
                    }):null}
                </div>
            </div>
            <div className={s.right}>
                {isLoading?
                    <Loading/>:
                    <>
                        <h2>Деталі аукціону</h2>
                        <p>Дата проведення:</p>
                        <h5>{lotInfo.date_start}</h5>
                        <p>Лот:</p>
                        <a className={s.cadastNum}
                           target="_blank"
                           href={lotInfo && lotInfo.land?'http://map.land.gov.ua/kadastrova-karta?cadnum='+lotInfo.land.cadastral_number:''}>
                            {lotInfo && lotInfo.land?lotInfo.land.cadastral_number:null}
                            <i className="fas fa-external-link-alt"></i>
                        </a>
                        <p>Кількість учасників:</p>
                        <h5>{results && results.members?Object.keys(results.members).length:null}</h5>
                        <p>Початкова ціна:</p>
                        <h5>{lotInfo.starting_price}</h5>
                    </>}
            </div>
        </div>
    )
}

export default AuctionResults;