import React from 'react';
import SignComponent from "../SignComponent/SignComponent";
import config from "../../config/config";
import ErrorHandler from "../../utils/ErrorLoger";
import {useHttp} from "../../hooks/HttpHook";

export default function SignFileModal({documentType, onSign, ...props}){
    const {request} = useHttp();
    function getBase64(signedFile, sourceFile) {
        const reader = new FileReader()
        const fileParts = sourceFile.name.split('.');
        const fileExtension = fileParts.pop().toLowerCase();

        return new Promise(resolve => {
            reader.onload = ev => {
                resolve({
                    "file_extension": fileExtension,
                    "encoded_file": ev.target.result,
                    "filename": fileParts.join(''),
                    "document_type": documentType
                })
            }
            reader.readAsDataURL(signedFile)
        })
    }

    async function signLot(result) {
        try{
            let data = await request(config.baseUrl + `/api/v1/user/documents/upload/`,'POST', await getBase64(result.signedFile, result.sourceFile));
            if(data && data.file_id){
                onSign(data.file_id);
            }
        }catch (e){
            ErrorHandler(e);
        }
    }

    return(
        <SignComponent
            {...props}
            onSign={signLot}
        />
    )
}