import React, {useState, useCallback, Fragment, useMemo, useEffect, createContext} from "react";
import s from './Participation.module.css';
import checkmarkGreen from '../../assets/img/checkmark-green.svg';
import CustomSelect from "../CustomSelect/CustomSelect";
import FileSign from "../FileSign/FileSign";
import FileSignMultiple from "../FileSignMultiple/FileSignMultiple";
import {useHistory} from 'react-router-dom'
import config from "../../config/config";
import {useHttp} from "../../hooks/HttpHook";
import Loading from "../Loading/Loading";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import ErrorHandler from "../../utils/ErrorLoger";
import CurrencyInput from "react-currency-masked-input";
import {Field, Form, Formik, useFormikContext} from "formik";
import ShortInfo from './legalEntity/ShortInfo'
import {TextField} from "../FormikFields/FormikFields";
import Location from "./legalEntity/Location";
import BeneficialOwners from "./legalEntity/BeneficialOwners";
import {ParticipationFormContext} from "../../context/ParticipationFormContext";
import ChiefInfo from "./legalEntity/ChiefInfo";
import ChiefDocuments from "./legalEntity/ChiefDocuments";
import AuthorizedPerson from "./legalEntity/AuthorizedPerson";
import FinalInfo from "./legalEntity/FinalInfo";
import IndividualInfo from "./individual/IndividualInfo";
import IndividualLocation from "./individual/IndividualLocation";
import IndividualDocuments from "./individual/IndividualDocuments";

function Participation(props) {
    const [userType, setUserType] = useState();
    const [currentStage, setCurrentStage] = useState(1);
    const [mainForm, setMainForm] = useState({});
    const history = useHistory();
    const {request, errors} = useHttp( {useFormikLocalization:true});

    const userTypes = [
        {name:"Фізична особа", id:'INDIVIDUAL'},
        {name:"Фізична особа-підприємець", id:'INDIVIDUAL_ENTREPRENEUR' },
        {name:"Юридична особа", id:'LEGAL_ENTITY'},
    ]

    async function getUserType(){
        try{
            const info = await request(config.baseUrl + `/api/v1/user/info/`,'GET');
            const profId =
                info.individual_enterpreneur_profile_id ||
                info.individual_profile_id ||
                info.legal_entity_profile_id
            setUserType(info.profile_type);

            const profile = await request(config.baseUrl + `/api/v1/user/${profId}/user-profile/`,'GET');
            initializeForm(profile, info.profile_type)
        }catch(e){
            ErrorHandler(e)
        }
    }



    const ErrorsHandler = ({errors}) => {
        const {setErrors} = useFormikContext();
        useEffect(()=>{
            if(errors) setErrors(errors);
        },[errors])
        return null;
    }

    const initializeForm = (form, userType) => {

        const changeObj = (obj, word) => {
            const resultObj = {};
            Object.keys(obj).forEach((key)=>{
                let parts = key.split('_')
                if(parts[0] === word) parts.shift()
                resultObj[parts.join('_')] = obj[key]
            })
            return resultObj
        }

        switch(userType){
            case 'INDIVIDUAL':{
                const changedObj = changeObj(form, "individual");
                setMainForm({
                    legal_form: 'INDIVIDUAL',
                    preferences: [],
                    ...changedObj,
                    ...changeObj(changeObj(changedObj.postal_address, "postal"),"address"),
                    is_citizen:true,
                    region:form.individual_postal_address.postal_address_region_id,
                    settlement:form.individual_postal_address.postal_address_settlement_id,
                    district:form.individual_postal_address.postal_address_district_id,
                });
                break;
            }
            case 'INDIVIDUAL_ENTREPRENEUR':{
                const changedObj = changeObj(changeObj(form, "individual"),"enterpreneur");
                setMainForm({
                    legal_form: 'INDIVIDUAL_ENTREPRENEUR',
                    preferences: [],
                    ...changedObj,
                    ...changeObj(changeObj(changedObj.postal_address, "postal"),"address"),
                    is_citizen:true,
                    region:form.individual_postal_address.postal_address_region_id,
                    settlement:form.individual_postal_address.postal_address_settlement_id,
                    district:form.individual_postal_address.postal_address_district_id,
                });
                break;
            }
            case 'LEGAL_ENTITY':{
                setMainForm({
                    legal_form: 'LEGAL_ENTITY',
                    preferences: [],
                    ...form,

                    legal_entity_name:form.legal_entity_name,
                    legal_entity_tax_number:'34634634',
                    legal_entity_chief_tax_number:'34634634',


                    ...form.legal_entity_address,
                    region:form.legal_entity_address.region_id,
                    settlement:form.legal_entity_address.settlement_id,
                    district:form.legal_entity_address.district_id,
                    ...form.legal_entity_postal_address,
                    postal_address_region:form.legal_entity_address.region_id,
                    postal_address_settlement:form.legal_entity_address.settlement_id,
                    postal_address_district:form.legal_entity_address.district_id,
                    legal_entity_authorized_person_apartment_or_office_number:form.legal_entity_authorized_person_address?.apartment_or_office_number,
                    legal_entity_authorized_person_building_number:form.legal_entity_authorized_person_address?.building_number,
                    legal_entity_authorized_person_building_block_number:form.legal_entity_authorized_person_address?.building_block_number,
                    legal_entity_authorized_person_country:form.legal_entity_authorized_person_address?.country,
                    legal_entity_authorized_person_district:form.legal_entity_authorized_person_address?.district_id,
                    legal_entity_authorized_person_postal_code:form.legal_entity_authorized_person_address?.postal_code,
                    legal_entity_authorized_person_region:form.legal_entity_authorized_person_address?.region_id,
                    legal_entity_authorized_person_settlement:form.legal_entity_authorized_person_address?.settlement_id,
                    legal_entity_authorized_person_street:form.legal_entity_authorized_person_address?.street,

                    legal_entity_authorized_person_authorization_document_issuer:form.legal_entity_authorized_person_authorization_document_issued_by,
                    legal_entity_authorized_person_authorization_document_issue_date:form.legal_entity_authorized_person_authorization_document_issue_dt,

                });
                break;
            }
        }
    }


    async function formSubmitHandler(values){
        let data = await request(config.baseUrl + `/api/v1/auctions/${props.lotNumber}/participate/`, 'POST', values);
        if(data && data.status === "OK"){
            setCurrentStage(currentStage+1)
        }
    }


    useEffect(()=>{
        getUserType();
    },[])

    function formChangeHandler(e, state, setState) {
        setState({...state, [e.target.name]: e.target.value});
    }

    async function validateForm(request, form, onSuccess) {
        let data = await request(config.baseUrl + `/api/v1/auctions/${props.lotId}/participate/validation/`, 'POST', {...form, legal_form:userType});
        if(data && data.status==='OK') onSuccess();
    }




    function ThirdStage() {
        const[thirdStageForm, setThirdStageForm] = useState({
            identity_document_type:'',
            identity_document_serial_number:'',
            identity_document_number:'',
            identity_document_issue_date:null,
            identity_document: null,
            email:'',
            phone_number:'',
        });

        const numberChangeHandler = (e) =>{
            const number = e.target.value.replace(/[^0-9]/g,'');
            setThirdStageForm({...thirdStageForm, 'phone_number': '+' + number});
        }

        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{
            validateForm(request, thirdStageForm, ()=>{
                setMainForm( Object.assign({}, mainForm, thirdStageForm))
                setCurrentStage(4);
            })
        }
        return(
            <div>
                {loading?<Loading/>:null}
                <div className={s.inputItem}>
                    <label>Документ що посвідчує особу*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setThirdStageForm({...thirdStageForm,identity_document_type:id})
                    }} data={[
                        {name:'паспорт громадянина України (ГУ)', id:'PASSPORT'},
                        {name:'паспорт ГУ у формі картки', id:'PASSPORT_AS_CARD'},
                        {name:'посвідка на тимчасове проживання', id:'TEMPORARY_RESIDENCE_PERMIT'},
                        {name:'посвідка на постійне проживання', id:'PERMANENT_RESIDENCE_PERMIT'},
                        {name:'інший документ', id:'ANOTHER_DOCUMENT'},
                    ]}/>
                </div>
                <div className={s.itemRow}>
                    <div className={s.inputItem}>
                        <label>Серія</label>
                        <input onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="identity_document_serial_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Номер</label>
                        <input onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="identity_document_number"/>
                    </div>
                    <div className={s.inputItem}>
                        <label>Дата видачі</label>
                        {/*<DatePicker*/}
                        {/*    name="identity_document_issue_date"*/}
                        {/*    dateFormat="yyyy-MM-dd"*/}
                        {/*    onChange={(date)=>{console.log(date)}}*/}
                        {/*    locale="ua-Uk"*/}
                        {/*/>*/}
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={thirdStageForm.identity_document_issue_date_timestamp}
                            onChange={(date)=>{
                                const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                setThirdStageForm({
                                    ...thirdStageForm,
                                    identity_document_issue_date_timestamp: +date,
                                    identity_document_issue_date: stringDate
                                })
                            }}
                            locale="ua-Uk"
                        />
                        {/*<input placeholder="2020-02-08" onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="identity_document_issue_date"/>*/}
                    </div>
                </div>
                <FileSign
                    documentType='IDENTITY_DOCUMENT'
                    onSelect={(id)=>{setThirdStageForm({...thirdStageForm,  identity_document: id})}}
                    className={s.fileSign}
                    description="Документ, що посвідчує особу. Максимальний розмір файлу 50 Мб"
                    title="Завантажити документ"
                />
                <div className={s.line +' '+s.gap}/>
                <div className={s.inputItem}>
                    <label>Email*</label>
                    <input onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="email"/>
                </div>
                <div className={s.inputItem}>
                    <label>Номер телефону*</label>
                    <InputMask onChange={numberChangeHandler} mask="+38 (099) 999-99-99" name="phone_number"/>
                    {/*<input onChange={(e)=>{formChangeHandler(e, thirdStageForm, setThirdStageForm)}} name="phone_number"/>*/}
                </div>
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button style={{marginTop:'150px'}} onClick={submitHandler} className={s.nextStageButton}>Далі</button>
            </div>
        )
    }

    function FourthStage(){
        const [specialUsePreference, setSpecialUsePreference] = useState(null);
        const [renterPreference, setRenterPreference] = useState(null);
        const [additionalDocuments, setAdditionalDocuments] = useState([]);
        const [fourthStageForm, setFourthStageForm] = useState({
            initial_bid_amount:'',
            refund_iban:'',
            has_preference:false,
            personal_data_process_consent:'',
        });
        const {requestErrors, request, loading} = useHttp();
        const submitHandler = () =>{

            let preferences = [];
            if(specialUsePreference) preferences.push(specialUsePreference)
            if(renterPreference) preferences.push(renterPreference)
            let validateDate = fourthStageForm.has_preference?
                {...fourthStageForm, preferences}:
                {...fourthStageForm}
            if(additionalDocuments.length>0 && fourthStageForm.has_preference) validateDate ={...validateDate, additional_documents:additionalDocuments}
            validateForm(request, validateDate, async ()=>{
                setMainForm( Object.assign({}, mainForm, validateDate))
                let data;
                try{
                    data = await request(config.baseUrl + `/api/v1/auctions/${props.lotNumber}/participate/`,'POST', {...mainForm, ...validateDate, legal_form:userType});
                    if(data.status === 'OK') setCurrentStage(0);
                }catch(e){
                    ErrorHandler(e)
                }
            })
        }

        return(
            <div>
                {loading?<Loading/>:null}
                <h4>Розмір мінімальної ставки: {props.startingPrice} грн</h4>
                <div className={s.inputItem}>
                    <label>Розмір закритої цінової пропозиції*</label>
                    <CurrencyInput decimalsLimit={2} name="initial_bid_amount" onChange={(e)=>{formChangeHandler(e, fourthStageForm, setFourthStageForm)}} separator="."/>
                </div>
                <div className={s.inputItem}>
                    <label>Номер банківського рахунку - IBAN*</label>
                    {/*<input onChange={(e)=>{formChangeHandler(e, fourthStageForm, setFourthStageForm)}} name="refund_iban"/>*/}
                    <InputMask onChange={(e)=>{formChangeHandler(e, fourthStageForm, setFourthStageForm)}} mask="UA999999999999999999999999999" name="refund_iban"/>
                </div>
                <div className={s.inputItem}>
                    <label>Суб'єкт переважного права*</label>
                    <CustomSelect onSelect={(value,id)=>{
                        setFourthStageForm({...fourthStageForm,has_preference:JSON.parse(id)});
                    }} data={[{name:'Так', id:'true'},{name:'Ні', id:'false'}]}/>
                </div>
                {fourthStageForm.has_preference?<>
                    <div className={s.checkboxRow}>
                        <div className={s.checkboxItem}>
                            <input name="preference_type" onChange={(e)=>{
                                if(e.target.checked){
                                    setRenterPreference(null)
                                    setSpecialUsePreference({
                                        type:'SPECIAL_USE_PREFERENCE',
                                        document_number:'',
                                        document_date:'',
                                        document_expire_at:'',
                                        document:null,
                                    })
                                }else{
                                    setSpecialUsePreference(null)
                                }


                            }} type='radio'/>
                            <p>Спецдозвіл на видобування корисних копалин<br/> загальнодержавного значення (1 черга)</p>
                        </div>
                        <div className={s.checkboxItem}>
                            <input name="preference_type" onChange={(e)=>{
                                if(e.target.checked){
                                    setSpecialUsePreference(null)
                                    setRenterPreference({
                                        type:'RENTER_PREFERENCE',
                                        document_number:'',
                                        document_date:'',
                                        document_expire_at:'',
                                        document:null,
                                    })
                                }else{
                                    setRenterPreference(null)
                                }

                            }} type='radio'/>
                            <p>Договір оренди земельної ділянки (2 черга)</p>
                        </div>
                    </div>
                    {specialUsePreference?<>
                        <h4 style={{'marginTop':'20px'}}>Спецдозвіл на видобування корисних копалин загальнодержавного значення (1 черга)</h4>
                        <div className={s.inputItem}>
                            <label>Номер документа*</label>
                            <input onChange={(e)=>{formChangeHandler(e, specialUsePreference, setSpecialUsePreference)}} name="document_number"/>
                        </div>
                        <div className={s.inputItem}>
                            <label>Дата документа*</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={specialUsePreference.document_date_timestamp}
                                onChange={(date)=>{

                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setSpecialUsePreference({
                                        ...specialUsePreference,
                                        document_date_timestamp: +date,
                                        document_date: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                            {/*<input onChange={(e)=>{formChangeHandler(e, specialUsePreference, setSpecialUsePreference)}} name="document_date"/>*/}
                        </div>
                        <div className={s.inputItem}>
                            <label>Термін дії до*</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={specialUsePreference.document_expire_at_timestamp}
                                onChange={(date)=>{

                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setSpecialUsePreference({
                                        ...specialUsePreference,
                                        document_expire_at_timestamp: +date,
                                        document_expire_at: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                        </div>

                        <div className={s.itemRow + ' ' + s.fileUploadRow}>
                            <FileSign
                                documentType='PREFERENCE_DOCUMENT'
                                onSelect={(id)=>{setSpecialUsePreference({...specialUsePreference,  document: id})}}
                                className={s.fileSign}
                                description="Документ, що посвідчує наявність переважного права. Максимальний розмір файлу 50 Мб"
                                title="Завантажити документ"
                            />
                        </div>
                    </>:null}

                    {renterPreference?<>
                        <h4 style={{'marginTop':'20px'}}>Договір оренди земельної ділянки (2 черга)</h4>
                        <div className={s.inputItem}>
                            <label>Номер документа*</label>
                            <input onChange={(e)=>{formChangeHandler(e, renterPreference, setRenterPreference)}} name="document_number"/>
                        </div>
                        <div className={s.inputItem}>
                            <label>Дата документа*</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={renterPreference.document_date_timestamp}
                                onChange={(date)=>{

                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setRenterPreference({
                                        ...renterPreference,
                                        document_date_timestamp: +date,
                                        document_date: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                            {/*<input onChange={(e)=>{formChangeHandler(e, renterPreference, setRenterPreference)}} name="document_date"/>*/}
                        </div>
                        <div className={s.inputItem}>
                            <label>Термін дії до*</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={renterPreference.document_expire_at_timestamp}
                                onChange={(date)=>{

                                    const stringDate = date.getUTCFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
                                    setRenterPreference({
                                        ...renterPreference,
                                        document_expire_at_timestamp: +date,
                                        document_expire_at: stringDate
                                    })
                                }}
                                locale="ua-Uk"
                            />
                        </div>

                        <div className={s.itemRow + ' ' + s.fileUploadRow}>
                            <FileSign
                                documentType='PREFERENCE_DOCUMENT'
                                onSelect={(id)=>{setRenterPreference({...renterPreference,  document: id})}}
                                className={s.fileSign}
                                description="Документ, що посвідчує наявність переважного права. Максимальний розмір файлу 50 Мб"
                                title="Завантажити документ"
                            />
                        </div>

                    </>:null}
                    {specialUsePreference || renterPreference?

                        <FileSignMultiple
                            documentType='ADDITIONAL_DOCUMENT'
                            onSelect={(id)=>{setFourthStageForm({...fourthStageForm,  additional_documents: id})}}
                            className={s.fileSign}
                            description="Додаткові документи (за необхідності). Максимальний розмір файлу 50 Мб "
                            title="Завантажити документ"
                        />:null}
                </>:<div className={s.line +' '+s.gap}/>}
                <div style={{padding:'0px', marginTop:'50px'}} className={s.checkboxItem}>
                    <input name="personal_data_process_consent" onChange={(e)=>{
                        e.target.checked?
                            setFourthStageForm({...fourthStageForm,personal_data_process_consent:true}):
                            setFourthStageForm({...fourthStageForm,personal_data_process_consent:false})
                    }} type='checkbox'/>
                    <p>Надаю згоду на обробку моїх персональних даних відповідно до Закону України «Про захист персональних даних»</p>
                </div>
                <div style={{padding:'0px', marginTop:'20px'}} className={s.checkboxItem}>
                    <input type='checkbox'/>
                    <p>Зберегти особисту інформацію для наступних аукціонів</p>
                </div>
                {requestErrors?<p className={s.error}>{requestErrors[0]}</p>:null}
                <button onClick={submitHandler} className={s.finishButton}>Вступити у аукціон</button>
            </div>
        )
    }




    function FinishStage() {
        const [invoices, setInvoices] = useState([])
        useEffect(()=>{
            invoiceHandler();
        },[])
        const {requestErrors, request, loading} = useHttp();
        const invoiceHandler = async () => {
            try{
                let data = await request(config.baseUrl + `/api/v1/auctions/${props.lotId}/invoices/`);
                if(data.length>0) setInvoices(data);
            }catch (e) {
                console.log(e);
            }
        }

        return(
            <div className={s.Finish}>
                {loading?<Loading/>:null}
                <div className={s.finishContent}>
                    <h2>Дякуємо за вступ у торги</h2>
                    <p className={s.grey}>Ваші дані знаходяться в процесі перевірки. Наступним кроком є  оплата<br/> реєстраційного і гарантійного внесків </p>
                    <a target="_blank" href = {invoices.length>0?config.baseUrl + invoices[0].url: null} onClick={invoiceHandler} className={s.invoiceButton}>Рахунок для оплати реєстраційного внеску</a>
                    <a target="_blank" href = {invoices.length>0?config.baseUrl + invoices[1].url: null} className={s.invoiceButton}>Рахунок для оплати гарантійного внеску</a>
                    <button onClick={()=>{history.push('/mylots/auction/')}} style={{width:'60%'}} className={s.finishButton}>Перейти до особистого кабінету</button>
                </div>
            </div>
        )
    }
console.log(mainForm)
    const Stage = ({stageNumber}) => {
        //if(currentStage==1) return <FirstStage/>
        switch (userType) {
            case 'INDIVIDUAL':
            case 'INDIVIDUAL_ENTREPRENEUR': {
                switch (+stageNumber) {
                    case 1: return <IndividualInfo entityName={userType==="INDIVIDUAL"?'Фізична особа':'Фізична особа-підприємець'}/>
                    case 2: return <IndividualLocation/>
                    case 3: return <IndividualDocuments/>
                    case 4: return <FinalInfo/>
                    case 5: return <FinishStage/>
                    default: return null;
                }
            }
            case 'LEGAL_ENTITY': {
                switch (+stageNumber) {
                    case 1: return <ShortInfo/>
                    case 2: return <Location/>
                    case 3: return <BeneficialOwners/>
                    case 4: return <ChiefInfo/>
                    case 5: return <ChiefDocuments/>
                    case 6: return <AuthorizedPerson/>
                    case 7: return <FinalInfo/>
                    case 8: return <FinishStage/>
                    default: return null;
                }
            }
            default : return null
        }

    }

    const StagesProgress = ()=>{
        const stagesNumbers = [];
        const maxStages = userType==='LEGAL_ENTITY'?7:userType==='INDIVIDUAL_ENTREPRENEUR' || userType==='INDIVIDUAL'?4:0;
        if(!userType) return <div style={{height:'109px'}}/>
        for(let i=0; i<maxStages; i++){

            if(i+1<currentStage){
                stagesNumbers.push(<img alt={"progress img"} key={i} className={s.completedStage} src={checkmarkGreen}/>)
                if(i!==maxStages-1)stagesNumbers.push(<div key={i+35} className={s.completedLine}/>)
            }else if(i+1===currentStage){
                stagesNumbers.push(<p key={i} className={s.activeStage}>{i+1}</p>)
                if(i!==maxStages-1)stagesNumbers.push(<div key={i+35} className={s.line}/>)
            }else {
                stagesNumbers.push(<p key={i}>{i+1}</p>)
                if(i!==maxStages-1)stagesNumbers.push(<div key={i+35} className={s.line}/>)
            }


        }

        return(
            <div className={s.stages}>
                {stagesNumbers}
            </div>
        )
    }


    return(
        <div className={s.Participation}>
            <h1>Вступ у аукціон</h1>

            <div className={s.content}>
                <h5 className={s.contentTitle}>Шановний користувачу!</h5>
                <p className={s.contentSubtitle}>Для участі в торгах Вам необхідно перевірити та доповнити інформацією поля форми, що наведена нижче, а також сплатити реєстраційний і гарантійний внески</p>
                <StagesProgress/>
                {Object.keys(mainForm).length !== 0?
                    <>
                        <Formik onSubmit={formSubmitHandler} initialValues={mainForm}>
                            <Form>
                                <ParticipationFormContext.Provider value={{nextStage:setCurrentStage.bind(this,currentStage+1)}}>
                                    <Stage stageNumber={currentStage}/>
                                    <ErrorsHandler errors={errors}/>
                                </ParticipationFormContext.Provider>
                            </Form>
                        </Formik>
                    </> :
                    <Loading/>
                }
            </div>
        </div>
    )
}

export default Participation;